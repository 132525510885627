<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-3 col-md-3 col-lg-3">
              <div class="user-block blue-block p-3">
                <div class="new-users total-users">
                  <p class="user-month">Total Companies</p>
                  <div v-if="TotalCompaniesLoader" class="loading-class">
                    <Loading />
                  </div>
                  <p v-if="!TotalCompaniesLoader" class="arrow-text">
                    <img src="assets/images/dashboard/logo-icon.svg" />
                    {{ TotalCompanies }}
                  </p>
                  <p class="per-text" v-if="!TotalCompaniesLoader">
                    {{ TotalNewCompaniesThisWeek.total }} New Companies this
                    week
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img
                    src="assets/images/dashboard/bar-chart.svg"
                    width="100"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
              <div class="user-block green-block p-3">
                <div class="new-users">
                  <p class="user-month">NEW COMPANIES THIS MONTH</p>
                  <div v-if="NewCompaniesThisMonthLoader" class="loading-class">
                    <Loading />
                  </div>
                  <p class="arrow-text" v-if="!NewCompaniesThisMonthLoader">
                    <img src="assets/images/icons/arrow_upward.svg" />
                    {{ TotalNewCompaniesThisMonth.total }}
                  </p>
                  <p class="per-text" v-if="!NewCompaniesThisMonthLoader">
                    {{ TotalNewCompaniesThisMonth.percentage }} Up in the last
                    30 days
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart.svg" />
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
              <div class="user-block orange-block p-3">
                <div class="new-users">
                  <p class="user-month">COMPANIES WITH CONTRACTS EXPIRING</p>
                  <div
                    v-if="CompaniesContractExpiringLoader"
                    class="loading-class"
                  >
                    <Loading />
                  </div>
                  <p class="arrow-text" v-if="!CompaniesContractExpiringLoader">
                    {{ CompaniesContractExpiring }}
                  </p>
                  <p class="per-text" v-if="!CompaniesContractExpiringLoader">
                    {{ ContractAreDueToRenewThisMonth }} Contracts are due to
                    renew this month
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart1.svg" />
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
              <div class="user-block purple-block p-3">
                <div class="new-users">
                  <p class="user-month">AVERAGE USERS PER COMPANY</p>
                  <div v-if="AverageUserPerCompanyLoader" class="loading-class">
                    <Loading />
                  </div>
                  <p class="arrow-text" v-if="!AverageUserPerCompanyLoader">
                    <img src="assets/images/icons/user-clock.svg" />
                    {{ AverageUserPerCompany.total }}
                  </p>
                  <p class="per-text" v-if="!AverageUserPerCompanyLoader">
                    {{ AverageUserPerCompany.percentage }} Up this month
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart.svg" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-box">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-6 p-4">
                    <h4>Pocket Box Plus Companies</h4>
                    <p>Real time crypto currancy information and rating data</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 px-4 mt-3 mb-3">
                    <div class="table-responsive table-color">
                      <table
                        class="table"
                        width="100%"
                        border="0"
                        id="companies_list"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Business Type</th>
                            <!-- <th>Town/ City</th> -->
                            <th>Vehicles</th>
                            <th>Users</th>
                            <th>Package</th>
                            <th>Start Date</th>
                            <th class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="showLoader">
                          <tr>
                            <td colspan="8">
                              <Loading />
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr
                            v-for="(Company, index) in Companies"
                            :key="index"
                          >
                            <td>{{ Company.name }}</td>
                            <td>{{ Company.business_type }}</td>
                            <!-- <td>{{ Company.town }}</td> -->
                            <td>{{ Company.total_vehicles }}</td>
                            <td>{{ Company.total_users }}</td>
                            <td>{{ Company.package_name }}</td>
                            <td>{{ Company.service_start_date | date }}</td>
                            <td class="text-center">
                              <router-link
                                :to="{
                                  name: 'CompanyUpdate',
                                  params: { CompanyId: Company.id },
                                }"
                                class="btn btn-primary btn-xs"
                                title="Edit Record"
                              >
                                Edit
                              </router-link>
                              <router-link
                                :to="{
                                  name: 'CompanyUsers',
                                  params: { CompanyId: Company.id },
                                }"
                                class="btn btn-primary btn-xs"
                                title="Company Users"
                                style="margin-left: 10px"
                              >
                                Plus Users
                              </router-link>
                              <router-link
                                :to="{
                                  name: 'CompanyFleetUsers',
                                  params: { CompanyId: Company.id },
                                }"
                                class="btn btn-primary btn-xs"
                                title="Fleet Users"
                                style="margin-left: 10px"
                              >
                                Fleet Users
                              </router-link>
                              <button
                                type="button"
                                class="btn btn-primary btn-xs"
                                @click="setQRCode(Company.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#qrcodeModal"
                                title="View QR Code"
                                style="margin-left: 10px"
                              >
                                QR Code
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-box">
                <div class="row p-3">
                  <div class="col">
                    <h4>New Companies By Month</h4>
                    <p>New Companies Added To Pocket Box By Month</p>
                  </div>
                  <div class="col text-end blurDiv"></div>
                </div>
                <div v-if="CompaniesByMonthLoader" class="loading-class">
                  <Loading />
                </div>
                <canvas
                  ref="chart_CompaniesByMonth"
                  style="width: 100%; height: 400px"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <companyQRCode
      :QRCodeEndpoint="QRCodeEndpoint"
      :PageTitle="PageTitle"
      :key="ComponentKey"
      ref="companyQRCode"
    ></companyQRCode>
  </div>
</template>
<script>
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "jquery/dist/jquery.min.js";
  import $ from "jquery";

  import companyQRCode from "@/views/Company/QRCode";
  import Nav from "@/components/Navigation";
  import Axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Chart from "chart.js";

  export default {
    components: {
      Nav,
      Loading,
      companyQRCode,
    },

    data() {
      return {
        Companies: {},
        showLoader: true,
        QRCodeEndpoint: "",
        PageTitle: "Company QR Code",
        ComponentKey: 0,
        TotalCompanies: 0,
        TotalNewCompaniesThisWeek: [],
        TotalNewCompaniesThisMonth: [],
        CompaniesContractExpiring: 0,
        ContractAreDueToRenewThisMonth: 0,
        AverageUserPerCompany: [],
        CompaniesByMonth: [],
        CompaniesByMonthData: {
          Labels: [],
          Dataset: [],
        },
        TotalCompaniesLoader: true,
        NewCompaniesThisMonthLoader: true,
        CompaniesContractExpiringLoader: true,
        AverageUserPerCompanyLoader: true,
        CompaniesByMonthLoader: true,
      };
    },
    mounted() {
      document.title = "PocketBox Admin - Companies";
      // data fragment
      var actions = [
        "TotalCompanies",
        "NewCompaniesThisMonth",
        "CompaniesContractExpiring",
        "AverageUserPerCompany",
        "CompaniesList",
        "NewCompaniesByMonth",
      ];
      var setTime = 100;
      actions.forEach((action) => {
        setTimeout(() => {
          this.GetCompanyFragment(action);
        }, setTime);
        setTime = parseInt(setTime) + 500;
      });
    },
    filters: {
      date: function (data) {
        if (data != null && data != "") {
          var reqdString = "";
          for (let i = 0; i < 10; i++) {
            reqdString += data[i];
          }
          var arr = reqdString.split("-");
          reqdString = arr[2] + "-" + arr[1] + "-" + arr[0];
          return reqdString;
        }
      },
    },
    methods: {
      GetCompanyFragment(type) {
        Axios.post(Endpoints.Company.CompanyFragment, {
          token: localStorage.getItem("token"),
          data: type,
        }).then((RequestResponse) => {
          if (RequestResponse.data.success) {
            if (type == "TotalCompanies") {
              this.TotalCompanies = RequestResponse.data.data.TotalCompanies;
              this.TotalNewCompaniesThisWeek =
                RequestResponse.data.data.NewCompaniesThisWeek;
              this.TotalCompaniesLoader = false;
            } else if (type == "NewCompaniesThisMonth") {
              this.TotalNewCompaniesThisMonth =
                RequestResponse.data.data.NewCompaniesThisMonth;
              this.NewCompaniesThisMonthLoader = false;
            } else if (type == "CompaniesContractExpiring") {
              this.CompaniesContractExpiring =
                RequestResponse.data.data.CompaniesContractExpiring;
              this.CompaniesContractExpiringLoader = false;
            } else if (type == "AverageUserPerCompany") {
              this.AverageUserPerCompany =
                RequestResponse.data.data.AverageUserPerCompany;
              this.AverageUserPerCompanyLoader = false;
            } else if (type == "CompaniesList") {
              this.Companies = RequestResponse.data.data.records;
              this.showLoader = false;
              setTimeout(() => {
                $("#companies_list").DataTable({
                  aaSorting: [],
                  language: {
                    search: "",
                    searchPlaceholder: "Search Companies...",
                    paginate: {
                      next: "&#8594;", // or '→'
                      previous: "&#8592;", // or '←'
                    },
                  },
                  oLanguage: {
                    sLengthMenu: "Show _MENU_",
                  },
                  columnDefs: [
                    {
                      targets: [7],
                      orderable: false,
                    },
                  ],
                });
              }, 1000);
            } else if (type == "NewCompaniesByMonth") {
              this.CompaniesByMonth =
                RequestResponse.data.data.NewCompaniesByMonth;
              for (let Index in RequestResponse.data.data.NewCompaniesByMonth) {
                let Item = RequestResponse.data.data.NewCompaniesByMonth[Index];
                this.CompaniesByMonthData.Labels.push(Index);
                this.CompaniesByMonthData.Dataset.push(Item);
              }
              this.CompaniesByMonthLoader = false;
              this.DrawCharts();
            }
          }
        });
      },
      DrawCharts() {
        if (document.body.contains(this.$refs.chart_CompaniesByMonth)) {
          var chart_CompaniesByMonth2D =
              this.$refs.chart_CompaniesByMonth.getContext("2d"),
            gradient = chart_CompaniesByMonth2D.createLinearGradient(
              0,
              0,
              0,
              450
            );
          gradient.addColorStop(0, "rgba( 44, 121, 249 , 0.5)");
          gradient.addColorStop(0.5, "rgba( 44, 121, 249 , 0.25)");
          gradient.addColorStop(1, "rgba( 44, 121, 249 , 0)");
          let chart_CompaniesByMonth = new Chart(chart_CompaniesByMonth2D, {
            type: "line",
            data: {
              labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              datasets: [
                {
                  label: "Total Users",
                  backgroundColor: gradient,
                  pointBackgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "#347ef9",
                  pointRadius: 3,
                  pointHoverRadius: 4,
                  data: this.CompaniesByMonth,
                },
              ],
            },
            options: {
              responsive: false,
              maintainAspectRatio: false,
              layout: {
                padding: 20,
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    position: "right",
                    ticks: {
                      display: true,
                      beginAtZero: true,
                      min: 0,
                    },

                    distribution: "linear",
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                  },
                ],
              },
              tooltips: {
                backgroundColor: "#3cb054",
                borderColor: "#3cb054",
                borderWidth: 1,
                borderRadius: 10,
                titleFontColor: "white",
                titleFontStyle: "bold",
                displayColors: false,
                bodyFontColor: "white",
                yAlign: "bottom",
              },
            },
          });
        }
      },
      setQRCode(CompanyId) {
        let EncodedLink = encodeURIComponent(
          `http://www.pocketbox.co.uk?company=${CompanyId}`
        );
        var ContentString = btoa(
          `https://pocketbox.page.link/?link=${EncodedLink}&apn=com.pocketbox.app&ibi=com.pocketbox.app&isi=1556393851&ofl=http://www.pocketbox.co.uk`
        );
        this.QRCodeEndpoint =
          Endpoints.QR.Generate + "?content=" + ContentString;
      },
      refreshList() {
        this.GetCompanyList(1, 1000);
      },
    },
  };
</script>
<style>
  .card-title p {
    font-size: 14px;
    color: #6d6d6d;
    margin-bottom: 0;
  }
  .dataTables_filter input {
    margin-bottom: 10px;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 3px 10px !important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current,
  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
  .paginate_button:hover,
  .paginate_button:focus {
    border: 1px solid #dadada !important;
    color: #fff !important;
  }
  h2.page-title {
    padding: 15px 0;
  }
</style>
