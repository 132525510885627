<template>
  <div class="col-sm-6 col-md-6 col-lg-6" ref="VehicleByType">
    <div class="table-box table-full-height">
      <div class="p-3">
        <div class="col">
          <h4>Vehicle By Type</h4>
          <p>Pocket Box Vehicle Data By Type.</p>
        </div>
      </div>
      <div class="three-block-gen-bre p-4 bar-pd">
        <div class="row">
          <div v-if="VehicleByTypeLoader" class="loading-class">
            <Loading />
          </div>
          <div
            :class="[
              'col typeBoxes',
              VehicleByType.type == 'Car'
                ? 'bg-blue-col'
                : VehicleByType.type == 'Motorcycle'
                ? 'bg-orange-col'
                : VehicleByType.type == 'LGV'
                ? 'bg-yellow-col'
                : VehicleByType.type == 'HGV'
                ? 'bg-voilet-col'
                : VehicleByType.type == 'LCV'
                ? 'bg-info-col'
                : VehicleByType.type.trim() == 'Bicycle'
                ? 'bg-bicycle-col'
                : VehicleByType.type.trim() == 'HCV'
                ? 'bg-hcv-col'
                : VehicleByType.type.trim() == 'Private'
                ? 'bg-private-col'
                : VehicleByType.type.trim() == 'Other'
                ? 'bg-other-col'
                : VehicleByType.type.trim() == 'PSV'
                ? 'bg-psv-col'
                : VehicleByType.type.trim() == 'Saloon'
                ? 'bg-saloon-col'
                : VehicleByType.type.trim() == 'Tricycle'
                ? 'bg-tricycle-col'
                : 'bg-no-image-col',
            ]"
            v-for="VehicleByType in VehicleByTypes"
            :key="VehicleByType.index"
          >
            <img
              :src="
                'assets/images/icons/' +
                (VehicleByType.type == 'Car'
                  ? 'car.svg'
                  : VehicleByType.type == 'Motorcycle'
                  ? 'bike.svg'
                  : VehicleByType.type == 'LGV'
                  ? 'lgv.svg'
                  : VehicleByType.type == 'HGV'
                  ? 'hgv.svg'
                  : VehicleByType.type == 'LCV'
                  ? 'lcv.svg'
                  : VehicleByType.type.trim() == 'Bicycle'
                  ? 'bicycle.svg'
                  : VehicleByType.type.trim() == 'HCV'
                  ? 'hcv.svg'
                  : VehicleByType.type.trim() == 'Private'
                  ? 'private.svg'
                  : VehicleByType.type.trim() == 'Other'
                  ? 'other.svg'
                  : VehicleByType.type.trim() == 'PSV'
                  ? 'psv.svg'
                  : VehicleByType.type.trim() == 'Saloon'
                  ? 'saloon.svg'
                  : VehicleByType.type.trim() == 'Tricycle'
                  ? 'tricycle.svg'
                  : 'no-image.svg')
              "
            />
            <p class="mt-4">{{ VehicleByType.type }}</p>
            <p>
              <b>{{ VehicleByType.total_percent }}</b>
            </p>
            <p class="mark-num">{{ VehicleByType.total }}</p>
          </div>
        </div>
      </div>
      <div class="chart-container-gender p-2" style="height: 30vh; width: 100%">
        <canvas ref="chart_VehicleByType" style="height: 450px"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Chart from "chart.js";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        VehicleByTypes: [],
        VehicleByTypesGraph: [],
        VehicleByTypeLoader: false,
      };
    },
    mounted() {
      this.VehicleByTypeLoader = true;
      axios
        .post(Endpoints.Data.VehiclesFragment, {
          token: localStorage.getItem("token"),
          data: "VehicleByType",
        })
        .then((RequestResponse) => {
          if (RequestResponse.data.success) {
            this.VehicleByTypes = RequestResponse.data.data.VehicleByType;
            this.VehicleByTypeLoader = false;
            this.VehicleByTypes.forEach((VehicleByType) => {
              let color =
                VehicleByType.type.trim() == "Car"
                  ? "#4c8bfd"
                  : VehicleByType.type.trim() == "Motorcycle"
                  ? "#F37137"
                  : VehicleByType.type.trim() == "HGV"
                  ? "#F49D37"
                  : VehicleByType.type.trim() == "LGV"
                  ? "#6c68ff"
                  : VehicleByType.type.trim() == "LCV"
                  ? "#17a2b8"
                  : VehicleByType.type.trim() == "Bicycle"
                  ? "#00a55d"
                  : VehicleByType.type.trim() == "HCV"
                  ? "#bf070e"
                  : VehicleByType.type.trim() == "Private"
                  ? "#e5bd2e"
                  : VehicleByType.type.trim() == "Other"
                  ? "#a8049b"
                  : VehicleByType.type.trim() == "PSV"
                  ? "#f3789d"
                  : VehicleByType.type.trim() == "Saloon"
                  ? "#4e373e"
                  : VehicleByType.type.trim() == "Tricycle"
                  ? "#04a575"
                  : "#e00404";
              this.VehicleByTypesGraph.push({
                data: [VehicleByType.total],
                backgroundColor: [color],
                borderWidth: 1,
                barThickness: 40,
                maxBarThickness: 40,
              });
            });
            this.$nextTick(() => {
              this.DrawVehicleByTypeCharts();
            });
          }
        });
    },
    methods: {
      DrawVehicleByTypeCharts() {
        // Vehicles by month
        if (document.body.contains(this.$refs.chart_VehicleByType)) {
          var stackedBar = new Chart(
            this.$refs.chart_VehicleByType.getContext("2d"),
            {
              type: "horizontalBar",
              data: {
                datasets: this.VehicleByTypesGraph,
              },
              options: {
                responsive: false,
                legend: {
                  display: false,
                },
                tooltips: {
                  enabled: false,
                },
                scales: {
                  xAxes: [
                    {
                      stacked: true,
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        display: false, //this will remove only the label
                      },
                    },
                  ],
                  yAxes: [
                    {
                      stacked: true,
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        display: false, //this will remove only the label
                      },
                    },
                  ],
                },
              },
            }
          );
        }
      },
    },
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
</style>
