<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper permissions-page">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title mt-2 mb-4">Edit Fleet Account Role</h1>
              <div class="panel-box">
                <div class="row">
                  <div class="form-step-col p-5">
                    <form ref="EditRoleForm" @submit.prevent="EditAccountRole">
                      <div class="row">
                        <!-- Company Name -->
                        <div class="col-sm-12 col-lg-12">
                          <div class="mb-3">
                            <label class="form-label">
                              Title<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Title"
                              v-model="EditRole.title"
                              :class="{
                                'is-invalid':
                                  submitted && $v.EditRole.title.$error,
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <label class="col-sm-2 role-name">&nbsp;</label>
                        <div class="col-sm-10">
                          <div class="form-check-solid">
                            <table>
                              <tbody>
                                <template>
                                  <tr>
                                    <td class="p-l-0">
                                      <div
                                        class="checkbox checkbox-primary select-all-box"
                                      >
                                        <input
                                          id="checkbox-primary-1"
                                          type="checkbox"
                                          @click="selectAllRoles()"
                                        /><label for="checkbox-primary-1 "
                                          >Select All</label
                                        >
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form theme-form roles"
                        v-if="Modules.length > 0"
                      >
                        <div
                          class="row"
                          v-for="(Module, ModuleIndex) in Modules"
                          :key="ModuleIndex"
                        >
                          <template v-if="Module != 'Hierarchy System'">
                            <label class="col-sm-2 role-name">{{
                              Module
                            }}</label>
                            <div class="col-sm-10">
                              <div class="form-check-solid">
                                <table>
                                  <tbody>
                                    <template>
                                      <tr>
                                        <td
                                          v-for="(
                                            Action, ActionIndex
                                          ) in EditRole.permissions[Module]"
                                          :key="ActionIndex"
                                          :class="
                                            ActionIndex == 'enable'
                                              ? 'main-active-box'
                                              : 'child-actions'
                                          "
                                        >
                                          <input
                                            class="check_now_enable"
                                            type="checkbox"
                                            value="1"
                                            :checked="selectAllRole"
                                            v-model="
                                              EditRole.permissions[Module][
                                                ActionIndex
                                              ]
                                            "
                                          /><label>{{
                                            ActionIndex.replaceAll("_", " ")
                                          }}</label>
                                        </td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="text-right">
                        <router-link
                          :to="{ name: 'FleetAccountRoles' }"
                          class="btn btn-default btn-xs"
                        >
                          &larr; Back</router-link
                        >
                        <button
                          class="btn btn-primary"
                          v-on:click="EditAccountRole()"
                          :disabled="Saving"
                        >
                          Save
                        </button>
                        <Loading v-if="Saving" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  const isAfterDate = (value, vm) => {
    return new Date(value).getTime() > new Date(vm.start_date).getTime();
  };
  export default {
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        Saving: false,
        submitted: false,
        Modules: [],
        EditRole: {
          title: "",
          permissions: {},
        },
        Permissions: ["enable"],
        selectAllRole: false,
        currentModules: [],
      };
    },
    validations: {
      EditRole: {
        title: { required },
      },
    },
    mounted() {
      this.RoleId = this.$route.params.RoleId;
      this.GetModules();
    },
    methods: {
      GetModules() {
        var obj = { token: localStorage.getItem("token") };
        Axios.post(Endpoints.Modules.List, obj).then((Response) => {
          if (Response.data.success) {
            this.currentModules = Response.data.records;
            Response.data.records.forEach((element) => {
              this.EditRole.permissions[element] = {};
              this.Permissions.forEach((Permission) => {
                this.EditRole.permissions[element][Permission] = false;
              });
            });
            setTimeout(() => {
              this.Modules = Response.data.records;
              this.getRole();
            }, 400);
          }
        });
      },
      getRole() {
        this.Saving = true;
        Axios.post(Endpoints.AccountRole.Get, {
          token: localStorage.getItem("token"),
          id: this.RoleId,
        }).then((Response) => {
          if (typeof Response.data.record != "undefined") {
            var getRoleData = Response.data.record;
            this.EditRole.id = getRoleData.id;
            this.EditRole.title = getRoleData.title;

            getRoleData.permissions = JSON.parse(getRoleData.permissions);

            this.currentModules.forEach((element) => {
              if (typeof getRoleData.permissions[element] != "undefined") {
                this.EditRole.permissions[element] =
                  getRoleData.permissions[element];
              }
            });
          }
          this.Saving = false;
        });
      },
      EditAccountRole() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.AccountRole.Update, {
          token: localStorage.getItem("token"),
          ...this.EditRole,
        }).then((Response) => {
          if (Response.data.success) {
            this.Saving = false;
            Swal.fire("Role Updated Successfully", "", "success").then(() => {
              this.$router.push("/fleet-account-roles");
              this.$emit("recordUpdated");
            });
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      selectAllRoles() {
        if (this.selectAllRole == true) {
          this.selectAllRole = false;
        } else {
          this.selectAllRole = true;
        }
        this.Modules.forEach((element) => {
          if (typeof this.EditRole.permissions[element] != "undefined") {
            Object.keys(this.EditRole.permissions[element]).forEach(
              (Permission) => {
                this.EditRole.permissions[element][Permission] =
                  this.selectAllRole;
              }
            );
          }
        });
      },
    },
  };
</script>
<style scoped>
  .permissions-page .row-new {
    display: inline-block;
    width: 350px;
    margin-left: 50px;
  }
  .permissions-page .row-new .btn-primary {
    margin-left: 10px;
    padding: 8px 15px;
    width: 161px;
  }
  .permissions-page .add-vehicle-heading {
    vertical-align: top;
    margin-top: 15px;
  }
  .permissions-page .headerCustom h5 span {
    display: inline-block;
    vertical-align: super;
  }
  .permissions-page .vehicle-details-heading {
    vertical-align: top;
    margin-top: 13px;
  }
  .permissions-page .roles .main-active-box {
    padding: 10px;
    background-color: #8f4f88 !important;
    color: #fff !important;
    text-transform: capitalize;
    width: 100px;
  }
  .permissions-page .roles .main-active-box label {
    padding-left: 10px;
    vertical-align: top;
    font-size: 13px;
    color: #fff;
  }
  .permissions-page .roles tr {
    border-bottom: 1px solid #fff;
  }
  .permissions-page .roles tr label {
    padding-left: 10px;
    vertical-align: top;
    font-size: 13px !important;
  }
  .permissions-page .roles tr td {
    padding: 10px 20px;
  }
  .permissions-page .roles tr input {
    vertical-align: middle;
  }
  .permissions-page .roles .role-name {
    vertical-align: middle;
    line-height: normal;
    padding-top: 15px;
    font-size: 13px;
  }
  .permissions-page .roles .child-actions {
    background-color: #e3e6eb;
    text-transform: capitalize;
  }
  .select-all-box label {
    margin-left: 10px;
    font-size: 13px;
  }
</style>
