<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-box">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-3 p-4">
                    <h4>Pocket Box Staff Leaderboard</h4>
                  </div>
                  <div class="col-sm-4 col-md-4 col-lg-2 p-4">
                    <div class="companies-list">
                      <select
                        v-model="company_id"
                        class="form-select"
                        @change="UpdateCompany($event)"
                      >
                        <option
                          v-for="Company in Companies"
                          v-bind:key="Company.id"
                          v-bind:value="Company.id"
                        >
                          {{ Company.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 px-4 mt-3 mb-3">
                    <div class="table-responsive table-color">
                      <table
                        class="table"
                        width="100%"
                        border="0"
                        id="company_users_list"
                      >
                        <thead>
                          <tr>
                            <th>Profile Image</th>
                            <th>Name</th>
                            <th>Email Address</th>
                            <th>Total Users</th>
                          </tr>
                        </thead>
                        <tbody v-if="showLoader">
                          <tr>
                            <td colspan="5">
                              <Loading />
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr v-for="(User, index) in Users" :key="index">
                            <td>
                              <div
                                class="profile-image"
                                v-if="!User.company_logo"
                              >
                                <img
                                  :src="
                                    GetProfileImage(User.id, User.profile_image)
                                  "
                                  class="listingIMG"
                                  :title="User.forename"
                                  :alt="User.forename"
                                />
                              </div>
                              <div
                                class="profile-image"
                                v-if="User.company_logo"
                              >
                                <img
                                  ref="CompanyLogoPreview"
                                  :src="
                                    GetCompanyLogo(User.id, User.company_logo)
                                  "
                                  class="listingIMG"
                                  id="companyLogo"
                                  title="Logo"
                                  alt="Logo"
                                  @error="
                                    $event.target.src = GetCompanyLogo(
                                      User.id,
                                      User.profile_image
                                    )
                                  "
                                />
                              </div>
                            </td>
                            <td>{{ User.forename }} {{ User.surname }}</td>
                            <td>{{ User.email }}</td>
                            <td>{{ User.TotalDownloads }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "jquery/dist/jquery.min.js";
  import $ from "jquery";

  import Nav from "@/components/Navigation";
  import Axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  export default {
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        showLoader: false,
        Users: {},
        Companies: false,
        company_id: 0,
        CompanyLogo: "",
        PageTitle: "Staff Leader Board",
      };
    },
    mounted() {
      document.title = "PocketBox Admin - Staff Leaderboard";
      this.GetCompanyList();
    },
    methods: {
      GetCompanyList() {
        Axios.post(Endpoints.Company.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Companies = Response.data.records;
          this.company_id = Response.data.records[0]["id"];
          this.GetCompanyUsersList(this.company_id);
        });
      },
      GetCompanyUsersList(CompanyId) {
        this.showLoader = true;
        Axios.post(Endpoints.Company.LeaderBoard.List, {
          token: localStorage.getItem("token"),
          company_id: CompanyId,
        }).then((Response) => {
          if (Response.data.success) {
            this.Users = Response.data.data;
            this.showLoader = false;
            setTimeout(() => {
              $("#company_users_list").DataTable({
                aaSorting: [],
                bDestroy: true,
                language: {
                  search: "",
                  searchPlaceholder: "Search...",
                  paginate: {
                    next: "&#8594;", // or '→'
                    previous: "&#8592;", // or '←'
                  },
                },
                oLanguage: {
                  sLengthMenu: "Show _MENU_",
                },
                columnDefs: [
                  {
                    targets: [3],
                    orderable: false,
                  },
                ],
              });
            }, 1000);
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      UpdateCompany(event) {
        $("#company_users_list").DataTable().clear().destroy();
        this.Users = false;
        this.company_id = event.target.value;
        this.GetCompanyUsersList(this.company_id);
      },
      GetProfileImage(Id, ProfileImage) {
        if (ProfileImage == "" || ProfileImage == 1 || ProfileImage == 0) {
          return "/assets/images/avatar.jpg";
        } else {
          return (
            Endpoints.Helper.MarketplaceImage +
            Id +
            "/" +
            localStorage.getItem("token") +
            "/user-profile-image"
          );
        }
      },
      GetCompanyLogo(CompanyID, ProfileImage) {
        if (ProfileImage == "" || ProfileImage == 1 || ProfileImage == 0) {
          return "/assets/images/avatar.jpg";
        } else {
          Axios.post(Endpoints.Helper.File.Get, {
            token: localStorage.getItem("token"),
            file: ProfileImage,
            company_id: CompanyID,
          }).then((FileGetResponse) => {
            var FileData = FileGetResponse.data;
            if (FileData.success === true) {
              $("#companyLogo").attr("src", FileData.file);
            }
          });
        }
      },
    },
  };
</script>
<style>
  .card-title p {
    font-size: 14px;
    color: #6d6d6d;
    margin-bottom: 0;
  }
  .dataTables_filter input {
    margin-bottom: 10px;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 3px 10px !important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current,
  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
  .paginate_button:hover,
  .paginate_button:focus {
    border: 1px solid #dadada !important;
  }
  h2.page-title {
    padding: 15px 0;
  }
  .float-end {
    margin-left: 10px;
  }
  .profile-image img {
    max-width: 50px;
    max-height: 50px;
    vertical-align: middle;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .profile-image {
    position: relative;
    text-align: center;
    width: 60px;
    padding: 5px;
    height: 60px;
    background: #ccc;
  }
  .companies-list {
    padding-left: 20px;
  }
</style>
