<template>
  <div class="page">
    <Navigation />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title mt-4 mb-4">Users</h1>
              <div class="row">
                <div :class="'col-sm-5'">
                  <div class="panel-box table-box searchBox">
                    <div class="row mt-6">
                      <div class="col-sm-5">
                        <div class="mb-4">
                          <input
                            type="Email"
                            class="form-control"
                            v-model="filter.email"
                            placeholder="Email Address"
                          />
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-4">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Surname"
                            v-model="filter.surname"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="mb-4">
                          <button
                            name="serach"
                            type="button"
                            class="btn btn-primary next-step btn-lg"
                            v-on:click="getUsers()"
                          >
                            Serach user
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <h4>Results Based on Email or Surname</h4>
                      <p>Below is a list of users based on the above search</p>
                    </div>
                    <div class="table-responsive mt-4 search-user">
                      <table
                        class="table"
                        width="100%"
                        border="0"
                        id="users_list"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th style="width: 125px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(user, index) in userData" :key="index">
                            <td>
                              <img
                                v-if="!user.image"
                                :src="'/assets/images/user/avatar.svg'"
                                width="32"
                              />
                              <img
                                v-if="user.image"
                                :src="user.image"
                                width="32"
                              />
                              <a
                                href="#"
                                style="color: #2b2b2b"
                                :title="user.forename + ' ' + user.surname"
                                >{{
                                  (user.forename + " " + user.surname)
                                    | str_limit(10)
                                }}</a
                              >
                            </td>
                            <td>
                              <a
                                :href="'mailto:' + user.email"
                                style="color: #2b2b2b"
                                :title="user.email"
                                >{{ user.email | str_limit(20) }}</a
                              >
                            </td>
                            <td>
                              <button
                                name="edit"
                                :id="`user_` + user.id"
                                type="button"
                                v-on:click="getUserDetails(user.id)"
                                class="btn btn-xs btn-primary"
                              >
                                Edit
                              </button>
                              <button
                                name="edit"
                                :id="`user_` + user.id"
                                type="button"
                                v-on:click="UserDelete(user.id)"
                                class="btn btn-xs btn-danger"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" v-if="userSingle.id > 0">
                  <div class="panel-box">
                    <h5 class="mb-4">Edit user Account</h5>
                    <div class="form-step-col">
                      <form
                        @submit.prevent="updateDetails"
                        v-if="!showPassword"
                      >
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="First-Name"
                                >First Name</label
                              >
                              <input
                                type="text"
                                v-model="userSingle.forename"
                                class="form-control"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.userSingle.forename.$error,
                                }"
                                id="First-Name"
                                placeholder="First Name"
                              />
                              <small
                                v-if="
                                  submitted && !$v.userSingle.forename.required
                                "
                                class="invalid-feedback"
                              >
                                First Name is required
                              </small>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="Last-Name"
                                >Last Name</label
                              >
                              <input
                                type="text"
                                v-model="userSingle.surname"
                                class="form-control"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.userSingle.surname.$error,
                                }"
                                id="Last-Name"
                                placeholder="Last Name"
                              />
                              <small
                                v-if="
                                  submitted && !$v.userSingle.surname.required
                                "
                                class="invalid-feedback"
                              >
                                First Name is required
                              </small>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="Email-Address"
                                >Email Address</label
                              >
                              <input
                                type="Email"
                                v-model="userSingle.email"
                                class="form-control"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.userSingle.email.$error,
                                }"
                                id="Email-Address"
                                placeholder="Email Address"
                              />
                              <small
                                v-if="
                                  submitted && !$v.userSingle.email.required
                                "
                                class="invalid-feedback"
                              >
                                Email is required
                              </small>
                              <small
                                v-if="submitted && !$v.userSingle.email.email"
                                class="invalid-feedback"
                              >
                                Valid Email Address is required
                              </small>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="Phone-Number"
                                >Phone Number</label
                              >
                              <input
                                type="text"
                                v-model="userSingle.mobile"
                                class="form-control"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.userSingle.mobile.$error,
                                }"
                                id="Phone-Number"
                                placeholder="Phone Number"
                              />
                              <small
                                v-if="
                                  submitted && !$v.userSingle.mobile.required
                                "
                                class="invalid-feedback"
                              >
                                Phone Number is required
                              </small>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="Address"
                                >Address</label
                              >
                              <input
                                type="text"
                                v-model="userSingle.address"
                                class="form-control"
                                id="Address"
                                placeholder="Address"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="Postcode"
                                >Postcode</label
                              >
                              <input
                                type="text"
                                v-model="userSingle.postcode"
                                class="form-control"
                                id="Postcode"
                                placeholder="Postcode"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="City">City</label>
                              <Select2
                                id="City"
                                class="custom_search"
                                v-model="userSingle.city"
                                :options="Cities"
                                placeholder="Select City"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="County"
                                >County</label
                              >
                              <Select2
                                id="County"
                                class="custom_search"
                                v-model="userSingle.county"
                                :options="Counties"
                                placeholder="Select County"
                              />
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="Birthday"
                                >Birthday</label
                              >
                              <input
                                type="date"
                                class="form-control"
                                id="Birthday"
                                name="dob"
                                placeholder="DOB: DD/MM/YYYY"
                                v-model="userSingle.dob"
                              />
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="Gender"
                                >Gender</label
                              >
                              <select
                                class="form-control"
                                v-model="userSingle.gender"
                                id="Gender"
                              >
                                <option value="1">Male</option>
                                <option value="2">Female</option>
                                <option value="3">Others</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="vehicle_limit"
                                >Vehicle Limit</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                id="vehicle_limit"
                                name="vehicle_limit"
                                placeholder="Vehicle Limit"
                                v-model="userSingle.vehicle_limit"
                              />
                            </div>
                          </div>
                          <div class="text-end">
                            <ul
                              class="list-unstyled list-inline buttons-col mt-4"
                            >
                              <li>
                                <button
                                  type="button"
                                  @click="showPassword = !showPassword"
                                  class="btn btn-primary"
                                >
                                  Password Reset
                                </button>
                              </li>
                              <li class="mx-2">
                                <button
                                  v-on:click="updateDetails()"
                                  type="button"
                                  class="btn btn-primary"
                                  :disabled="Saving"
                                >
                                  Save User Info
                                </button>
                              </li>
                            </ul>
                            <Loading v-if="Saving" />
                          </div>
                        </div>
                      </form>
                      <form @submit.prevent="setPassword" v-if="showPassword">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="New-Password"
                                >New Password</label
                              >
                              <input
                                type="password"
                                v-model="updatePassword.password"
                                class="form-control"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.updatePassword.password.$error,
                                }"
                                id="New-Password"
                                placeholder="New Passowrd"
                              />
                              <small
                                v-if="
                                  submitted &&
                                  !$v.updatePassword.password.required
                                "
                                class="invalid-feedback"
                              >
                                password is required
                              </small>
                              <small v-if="submitted && !$v.updatePassword.password.minLength" class="d-block invalid-feedback">Password must be at least 8 characters long.</small>
                              <small v-if="submitted && !$v.updatePassword.password.strong" class="d-block invalid-feedback">Password must be strong (eg. include at least 1 capital letter, 1 number and 1 special character)</small>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="mb-4">
                              <label class="form-label" for="Conf-Password"
                                >Confirm Password</label
                              >
                              <input
                                type="password"
                                v-model="updatePassword.confpassword"
                                class="form-control"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.updatePassword.confpassword.$error,
                                }"
                                id="Conf-Password"
                                placeholder="Confirm Passowrd"
                              />
                              <small
                                v-if="
                                  submitted &&
                                  !$v.updatePassword.confpassword.required
                                "
                                class="invalid-feedback"
                              >
                                Confirm password is required
                              </small>
                              <small
                                v-if="
                                  submitted &&
                                  !$v.updatePassword.confpassword.sameAsPassword
                                "
                                class="invalid-feedback"
                              >
                                Passwords must be identical
                              </small>
                            </div>
                          </div>
                          <div class="text-end">
                            <ul
                              class="list-unstyled list-inline buttons-col mt-4"
                            >
                              <li>
                                <button
                                  type="button"
                                  v-on:click="showPassword = !showPassword"
                                  class="btn btn-primary"
                                >
                                  Back
                                </button>
                              </li>
                              <li class="mx-2">
                                <button
                                  v-on:click="setPassword()"
                                  type="button"
                                  class="btn btn-primary"
                                  :disabled="Saving"
                                >
                                  Update Password
                                </button>
                              </li>
                            </ul>
                            <Loading v-if="Saving" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    required,
    email,
    minLength,
    sameAs,
    requiredIf,
  } from "vuelidate/lib/validators";
  import Navigation from "@/components/Navigation";
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Select2 from "v-select2-component";
  import citiesData from "@/assets/JsonData/cities.json";
  import countiesData from "@/assets/JsonData/counties.json";
  import countriesData from "@/assets/JsonData/countries.json";
  import Swal from "sweetalert2";
  import Loading from "@/components/Loading";

  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "jquery/dist/jquery.min.js";
  import $ from "jquery";
  // set cities data
  var citiesList = [];
  var keys = Object.keys(citiesData);
  keys.forEach(function (key) {
    citiesList.push(citiesData[key]);
  });

  // set counties data
  var countiesList = [];
  var keys = Object.keys(countiesData);
  keys.forEach(function (key) {
    countiesList.push(countiesData[key]);
  });
  // set countries data
  var countriesList = [];
  var keys = Object.keys(countriesData);
  keys.forEach(function (key) {
    countriesList.push(countriesData[key]);
  });
  export default {
    filters: {
      str_limit(value, size) {
        if (!value) return "";
        value = value.toString();

        if (value.length <= size) {
          return value;
        }
        return value.substr(0, size) + "...";
      },
    },
    components: {
      Navigation,
      Select2,
      Loading,
    },
    data() {
      return {
        showPassword: false,
        submitted: false,
        Saving: false,
        formValidate: false,
        Cities: citiesList,
        Counties: countiesList,
        Countries: countriesList,
        userData: [],
        userSingle: {
          id: 0,
          email: null,
          forename: null,
          surname: null,
          gender: 0,
          dob: null,
          address: null,
          city: null,
          county: null,
          postcode: null,
          landline: null,
          mobile: null,
          vehicle_limit: null,
        },
        filter: {
          email: "",
          surname: "",
        },
        updatePassword: {
          password: "",
          confpassword: "",
        },
      };
    },
    validations: {
      userSingle: {
        forename: {
          required,
        },
        surname: {
          required,
        },
        email: {
          required,
          email,
        },
        mobile: {
          required,
        },
      },
      updatePassword: {
        password: {
          required,
          minLength: minLength(8),
          strong: (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(value)
        },
        confpassword: {
          required,
          sameAsPassword: sameAs("password"),
        },
      },
    },
    mounted() {
      document.title = "PocketBox Admin - Users";
      this.getUsers();
    },
    methods: {
      getUsers() {
        this.userData = [];
        $("#users_list").DataTable().clear().destroy();
        /*Get User Data*/
        axios
          .post(Endpoints.User.Get, {
            token: localStorage.getItem("token"),
            email: this.filter.email,
            surname: this.filter.surname,
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              this.userData = RequestResponse.data.data;
              for (let index in this.userData) {
                let img = this.userData[index].image;
                if (img !== null) {
                  this.userData[index].image = this.GetUserImage(img, index);
                }
              }
              setTimeout(() => {
                $("#users_list").DataTable({
                  aaSorting: [],
                  bDestroy: true,
                  searching: false,
                  language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                      next: "&#8594;", // or '→'
                      previous: "&#8592;", // or '←'
                    },
                  },
                  oLanguage: {
                    sLengthMenu: "Show _MENU_",
                  },
                  columnDefs: [
                    {
                      targets: [2],
                      orderable: false,
                    },
                  ],
                });
              }, 1000);
            }
          });
      },
      getUserDetails(id) {
        axios
          .post(Endpoints.User.Details, {
            token: localStorage.getItem("token"),
            id: id,
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              var userSingle = RequestResponse.data.record[0];
              this.userSingle.id = userSingle.id;
              this.userSingle.email = userSingle.email;
              this.userSingle.forename = userSingle.forename;
              this.userSingle.surname = userSingle.surname;
              this.userSingle.gender = userSingle.gender;
              this.userSingle.dob = userSingle.dob;
              this.userSingle.address = userSingle.address;
              this.userSingle.city = userSingle.city;
              this.userSingle.county = userSingle.county;
              this.userSingle.postcode = userSingle.postcode;
              this.userSingle.landline = userSingle.landline;
              this.userSingle.mobile = userSingle.mobile;
              this.userSingle.vehicle_limit = userSingle.vehicle_limit;
              window.scrollTo(0, 0);
            }
          });
      },
      GetUserImage(image) {
        axios
          .post(Endpoints.Helper.File.Get, {
            token: localStorage.getItem("token"),
            file: image,
          })
          .then((FileGetResponse) => {
            var FileData = FileGetResponse.data;
            if (FileData.success === true) {
              return FileData.file;
            }
          })
          .catch((error) => {});
      },
      updateDetails() {
        this.submitted = true;
        this.$v.userSingle.email.$touch();
        this.$v.userSingle.forename.$touch();
        this.$v.userSingle.surname.$touch();
        this.$v.userSingle.mobile.$touch();
        if (
          this.$v.userSingle.email.$invalid ||
          this.$v.userSingle.forename.$invalid ||
          this.$v.userSingle.surname.$invalid ||
          this.$v.userSingle.mobile.$invalid
        ) {
          return;
        }
        this.Saving = true;
        axios
          .post(Endpoints.User.Update, {
            token: localStorage.getItem("token"),
            ...this.userSingle,
          })
          .then((Response) => {
            this.Saving = false;
            if (Response.data.success) {
              Swal.fire({
                title: "Success",
                text: Response.data.message,
                icon: "success",
                confirmButtonText: "Close",
              });
              this.getUsers();
              Object.keys(this.userSingle).forEach(
                (i) => (this.userSingle[i] = null)
              );
            } else {
              Swal.fire({
                title: "Oops!",
                text: Response.data.message,
                icon: "error",
                confirmButtonText: "Close",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Oops!",
              text: "Something went Wrong",
              icon: "error",
              confirmButtonText: "Close",
            });
            this.Saving = false;
          });
      },
      setPassword() {
        this.submitted = true;
        this.$v.updatePassword.password.$touch();
        this.$v.updatePassword.confpassword.$touch();
        if (
          this.$v.updatePassword.password.$invalid ||
          this.$v.updatePassword.confpassword.$invalid
        ) {
          return;
        }
        this.Saving = true;
        axios
          .post(Endpoints.User.UpdatePassword, {
            token: localStorage.getItem("token"),
            id: this.userSingle.id,
            new_password: this.updatePassword.password,
            confirm_new_password: this.updatePassword.confpassword,
          })
          .then((Response) => {
            this.Saving = false;
            if (Response.data.success) {
              Swal.fire({
                title: "Success",
                text: Response.data.message,
                icon: "success",
                confirmButtonText: "Close",
              });
              this.getUsers();
              Object.keys(this.userSingle).forEach(
                (i) => (this.userSingle[i] = null)
              );
              this.showPassword = false;
            } else {
              Swal.fire({
                title: "Oops!",
                text: Response.data.message,
                icon: "error",
                confirmButtonText: "Close",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Oops!",
              text: "Something went Wrong",
              icon: "error",
              confirmButtonText: "Close",
            });
            this.Saving = false;
          });
      },
      UserDelete(id) {
        Swal.fire({
          title: "Are you sure to delete this User?",
          text: "This action will delete all traces of users from Pocket Box Database and you will not be able to retrieve it back",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Deleting...",
              text: "Please wait...",
              imageUrl: require("../../assets/images/LoadingTwo.gif"),
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            axios
              .post(Endpoints.User.Remove, {
                token: localStorage.getItem("token"),
                id: id,
              })
              .then((Response) => {
                if (Response.data.success == true) {
                  Swal.fire("User Deleted Successfully", "", "success").then(
                    () => {
                      this.getUsers();
                    }
                  );
                } else {
                  Swal.fire({
                    title: "Oops!",
                    html: Response.data.message,
                    icon: "error",
                    confirmButtonText: "Close",
                  });
                }
              });
          }
        });
      },
    },
  };
</script>
<style scoped>
  .searchBox .btn-primary {
    padding: 8px 15px;
    margin-right: 2px;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    color: #fff !important;
  }
  .searchBox .btn-danger {
    padding: 8px 15px;
    color: #fff !important;
    background-color: #dd3333;
    border-color: #dd3333;
  }
  .searchBox .btn-danger:hover {
    background-color: #db4f4f;
    border-color: #db4f4f;
    font-weight: 500;
  }
</style>
