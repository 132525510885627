<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title mt-2 mb-4">Update Plus Company User</h1>
              <div class="panel-box p-5">
                <div class="row">
                  <div class="form-step-col p-5">
                    <form
                      ref="updateCompanyUserForm"
                      @submit.prevent="updateCompanyUser"
                    >
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >User Type<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <select
                              v-model="EditCompanyUser.company_role"
                              class="form-control"
                            >
                              <option value="1">Admin</option>
                              <option value="2">Sales Person</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >First Name<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter First Name"
                              v-model="EditCompanyUser.forename"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.EditCompanyUser.forename.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted &&
                                !$v.EditCompanyUser.forename.required
                              "
                              class="invalid-feedback"
                            >
                              First Name is required
                            </small>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >Last Name<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Last Name"
                              v-model="EditCompanyUser.surname"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.EditCompanyUser.surname.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted &&
                                !$v.EditCompanyUser.surname.required
                              "
                              class="invalid-feedback"
                            >
                              Last Name is required
                            </small>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >Email Address<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Email Address"
                              v-model="EditCompanyUser.email"
                              :class="{
                                'is-invalid':
                                  submitted && $v.EditCompanyUser.email.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted && !$v.EditCompanyUser.email.required
                              "
                              class="invalid-feedback"
                            >
                              Email Address is required
                            </small>
                            <small
                              v-if="
                                submitted && !$v.EditCompanyUser.email.email
                              "
                              class="invalid-feedback"
                            >
                              Valid Email Address is required
                            </small>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >Contact Number<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Contact Number"
                              v-model="EditCompanyUser.phone"
                              :class="{
                                'is-invalid':
                                  submitted && $v.EditCompanyUser.phone.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted && !$v.EditCompanyUser.phone.required
                              "
                              class="invalid-feedback"
                            >
                              Contact Number is required
                            </small>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label">Profile Image</label>
                            <input
                              class="input form-control"
                              type="file"
                              name="profile_image"
                              ref="ProfileImageFile"
                              :class="{
                                'is-invalid':
                                  $v.EditCompanyUser.profile_image.$error,
                              }"
                              @change="imageFileChanged($event)"
                              accept="image/*"
                            />
                            <small
                              v-if="
                                submitted &&
                                !$v.EditCompanyUser.profile_image
                                  .file_size_validation
                              "
                              class="invalid-feedback"
                            >
                              Please upload less than 1 MB file size image
                            </small>
                            <small
                              v-if="
                                submitted &&
                                !$v.EditCompanyUser.profile_image
                                  .image_type_validation
                              "
                              class="invalid-feedback"
                            >
                              Please upload jpeg, png, gif or svg file only
                            </small>
                          </div>
                          <center>
                            <div
                              class="profile-image mt-10"
                              v-show="
                                EditCompanyUser.profile_image &&
                                EditCompanyUser.profile_image != 1
                              "
                            >
                              <img
                                :src="GetProfileImage(EditCompanyUser.id)"
                                class="icon__preview"
                                :title="EditCompanyUser.forename"
                                :alt="EditCompanyUser.forename"
                                @error="
                                  $event.target.src = GetProfileImage(
                                    EditCompanyUser.id
                                  )
                                "
                                height="200"
                                width="200"
                              />
                            </div>
                          </center>
                        </div>
                      </div>
                      <ul
                        class="list-unstyled list-inline float-end buttons-col mt-4"
                      >
                        <li>
                          <router-link
                            :to="{
                              name: 'CompanyUsers',
                              params: { CompanyId: EditCompanyUser.company_id },
                            }"
                            class="btn btn-default"
                          >
                            &larr; Back</router-link
                          >
                        </li>
                        <li>
                          <button
                            type="button"
                            class="btn btn-info next-step"
                            v-on:click="updateCompanyUser()"
                            :disabled="Saving"
                          >
                            Update User
                          </button>
                          <Loading v-if="Saving" />
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required, email } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";

  const file_size_validation = (value, vm) => {
    if (!value || !value.size) {
      return true;
    }
    let file = value;
    return file.size < 1048576;
  };
  const image_type_validation = (value, vm) => {
    if (!value || !value.size) {
      return true;
    }
    let file = value;
    if (
      file.type == "image/svg+xml" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif" ||
      file.type == "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };

  export default {
    //props: ["EditCompanyUser"],
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        UserId: 0,
        Saving: false,
        submitted: false,
        EditCompanyUser: {
          forename: "",
          surname: "",
          email: "",
          phone: "",
          profile_image: "",
        },
      };
    },
    validations: {
      EditCompanyUser: {
        forename: { required },
        surname: { required },
        email: { required, email },
        phone: { required },
        profile_image: { file_size_validation, image_type_validation },
      },
    },
    mounted() {
      this.UserId = this.$route.params.UserId;
      this.getUserData();
    },
    methods: {
      getUserData() {
        this.Saving = true;
        Axios.post(Endpoints.Company.User.Get, {
          token: localStorage.getItem("token"),
          id: this.UserId,
        }).then((Response) => {
          if (typeof Response.data.record != "undefined") {
            this.EditCompanyUser = Response.data.record;
            this.GetProfileImage(this.EditCompanyUser.profile_image);
          }

          this.Saving = false;
        });
      },
      GetProfileImage(Id) {
        return (
          Endpoints.Helper.MarketplaceImage +
          Id +
          "/" +
          localStorage.getItem("token") +
          "/user-profile-image"
        );
      },
      imageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.EditCompanyUser.profile_image = files[0];
        var output = document.querySelector(".icon__preview");
        output.src = URL.createObjectURL(files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
      },
      updateCompanyUser() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Company.User.Update, {
          token: localStorage.getItem("token"),
          ...this.EditCompanyUser,
        }).then((Response) => {
          this.Saving = false;
          if (Response.data.success) {
            this.UpdateProfileImage(Response.data.last_id);
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      UpdateProfileImage(id) {
        var LogoFormData = new FormData();
        if (this.$refs.ProfileImageFile.files[0]) {
          LogoFormData.append("storage_type", "CPU");
          LogoFormData.append("token", localStorage.getItem("token"));
          LogoFormData.append("file", this.$refs.ProfileImageFile.files[0]);
          LogoFormData.append("company_id", this.EditCompanyUser.company_id);
          LogoFormData.append("user_id", id);
          Axios.post(Endpoints.Helper.File.Upload, LogoFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Company.User.ProfileImageSet, {
                  token: localStorage.getItem("token"),
                  profile_image: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  Swal.fire(
                    "Plus Company User Updated Successfully",
                    "",
                    "success"
                  ).then(() => {
                    this.$router.push(
                      "/company/users/" + this.EditCompanyUser.company_id
                    );
                  });
                });
              }
            }
          );
        } else {
          Swal.fire(
            "Plus Company User Updated Successfully",
            "",
            "success"
          ).then(() => {
            this.$router.push(
              "/company/users/" + this.EditCompanyUser.company_id
            );
          });
        }
      },
      clearCompanyUserModal() {
        this.$refs.updateCompanyUserForm.reset();
        var self = this;
        Object.keys(this.updateCompanyUser).forEach(function (subsKey, index) {
          self.updateCompanyUser[subsKey] = "";
        });
        this.$refs.editProfileImagePreview.src = "";
        this.submitted = false;
      },
      // GetProfileImage(profileImage) {
      //   Axios.post(Endpoints.Helper.File.Get, {
      //     token: localStorage.getItem("token"),
      //     file: profileImage,
      //   }).then((FileGetResponse) => {
      //     var FileData = FileGetResponse.data;
      //     if (FileData.success === true) {
      //       this.ProfileImage = FileData.file;
      //       this.$refs.editProfileImagePreview.src = FileData.file;
      //     }
      //   });
      // },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
  .modal-backdrop {
    background-color: #232e3c !important;
  }
  .mt-10 {
    margin-top: 10px;
  }
</style>
