<template>
  <div class="page">
    <Navigation />
    <div class="app-wrapper">
      <!-- Page Header -->
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title">Edit Company</h1>

              <div class="panel-box">
                <div class="row row-deck row-cards">
                  <div class="col-sm-12 col-lg-12">
                    <form @submit.prevent="UpdateCompanyData">
                      <!-- Step 1 start -->
                      <div class="step_1 activeForm" ref="step_1">
                        <div class="row">
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Company Name<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Company Name"
                                v-model="UpdateCompany.name"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.UpdateCompany.name.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted && !$v.UpdateCompany.name.required
                                "
                                class="invalid-feedback"
                              >
                                Company Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-2">
                            <div class="mb-3">
                              <label class="form-label">
                                Mileage Unit<span class="text-danger">*</span>
                              </label>
                              <select
                                v-model="UpdateCompany.mileage_unit"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.mileage_unit.$error,
                                }"
                                class="form-select"
                              >
                                <option value="">Select Mileage Unit</option>
                                <option value="1">Miles</option>
                                <option value="2">KM</option>
                              </select>

                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.mileage_unit.required
                                "
                                class="invalid-feedback"
                              >
                                Mileage Unit is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Company Type<span class="text-danger">*</span>
                              </label>
                              <select
                                v-model="UpdateCompany.default_category"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.default_category.$error,
                                }"
                                class="form-select"
                              >
                                <option value="">Select Company Type</option>
                                <option
                                  v-for="Category in Categories"
                                  v-bind:value="Category.id"
                                  v-bind:key="Category.id"
                                >
                                  {{ Category.category_name }}
                                </option>
                              </select>

                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.default_category.required
                                "
                                class="invalid-feedback"
                              >
                                Company Type is required
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-lg-3">
                            <div class="mb-3">
                              <label class="form-label">Company Logo</label>
                              <input
                                class="input form-control company__logo__input"
                                type="file"
                                name="resume"
                                ref="logoFile"
                                accept="image/*"
                                :class="{
                                  'is-invalid':
                                    $v.UpdateCompany.logo_image.$error,
                                }"
                                @change="imageFileChanged($event)"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.logo_image
                                    .file_size_validation
                                "
                                class="invalid-feedback"
                              >
                                Please upload less than 1 MB file size image
                              </div>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.logo_image
                                    .image_type_validation
                                "
                                class="invalid-feedback"
                              >
                                Please upload jpeg, png, gif or svg file only
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-1">
                            <div class="mb-3" v-show="UpdateCompany.logo">
                              <label class="form-label"> <br />&nbsp;</label>
                              <img
                                ref="CompanyLogoPreview"
                                class="company__logo__preview"
                                style="position: initial"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Website<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Website URL"
                                v-model="UpdateCompany.website"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.website.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.website.required
                                "
                                class="invalid-feedback"
                              >
                                Website is required
                              </div>
                              <div
                                v-if="
                                  submitted && !$v.UpdateCompany.website.url
                                "
                                class="invalid-feedback"
                              >
                                Enter valid Website URL
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Email Address<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Company Email Address"
                                v-model="UpdateCompany.email"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.UpdateCompany.email.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted && !$v.UpdateCompany.email.required
                                "
                                class="invalid-feedback"
                              >
                                Email Address is required
                              </div>
                              <div
                                v-if="
                                  submitted && !$v.UpdateCompany.email.email
                                "
                                class="invalid-feedback"
                              >
                                Valid Email Address is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4 colorSection">
                            <div class="mb-3">
                              <label class="form-label"
                                >Corporate Colours</label
                              >
                              <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                  <span>Colour 1:</span>
                                  <input
                                    type="color"
                                    class="form-control"
                                    v-model="UpdateCompany.colour_1"
                                  />
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                  <span>Colour 2:</span>
                                  <input
                                    type="color"
                                    class="form-control"
                                    v-model="UpdateCompany.colour_2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-2">
                            <div class="mb-3">
                              <label class="form-label">
                                Phone Number<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Company Phone Number"
                                v-model="UpdateCompany.contact_number"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.contact_number.$error,
                                }"
                                maxlength="16"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.contact_number.required
                                "
                                class="invalid-feedback"
                              >
                                Phone Number is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Address Line 1<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Building Number and Street Name"
                                v-model="UpdateCompany.address"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.address.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.address.required
                                "
                                class="invalid-feedback"
                              >
                                Address is required
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-sm-12 col-lg-4">
                            <div
                              class="mb-3"
                              :class="{
                                'is-invalid':
                                  submitted && $v.UpdateCompany.town.$error,
                              }"
                            >
                              <label class="form-label">
                                Town / City<span class="text-danger">*</span>
                              </label>
                              <Select2
                                class="custom_search"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.UpdateCompany.town.$error,
                                }"
                                v-model="UpdateCompany.town"
                                :options="Cities"
                                placeholder="Select City"
                              />
                              <div
                                v-if="
                                  submitted && !$v.UpdateCompany.town.required
                                "
                                class="invalid-feedback"
                              >
                                Town / City is required
                              </div>
                            </div>
                          </div> -->
                          <!-- <div class="col-sm-12 col-lg-4">
                            <div
                              class="mb-3"
                              :class="{
                                'is-invalid':
                                  submitted && $v.UpdateCompany.county.$error,
                              }"
                            >
                              <label class="form-label"
                                >County<span class="text-danger">*</span></label
                              >
                              <Select2
                                class="custom_search"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.UpdateCompany.county.$error,
                                }"
                                v-model="UpdateCompany.county"
                                :options="Counties"
                                placeholder="Select County"
                              />
                              <div
                                v-if="
                                  submitted && !$v.UpdateCompany.county.required
                                "
                                class="invalid-feedback"
                              >
                                County is required
                              </div>
                            </div>
                          </div> -->
                          <div class="col-sm-12 col-lg-2">
                            <div class="mb-3">
                              <label class="form-label">
                                Postcode<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Postcode"
                                v-model="UpdateCompany.postcode"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.postcode.$error,
                                }"
                                maxlength="8"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.postcode.required
                                "
                                class="invalid-feedback"
                              >
                                Postcode is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12 col-lg-3">
                            <div class="mb-3">
                              <label class="form-label">
                                Company CEO Forename<span class="text-danger"
                                  >*</span
                                >
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="CEO Forename"
                                v-model="UpdateCompany.forename"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.forename.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.forename.required
                                "
                                class="invalid-feedback"
                              >
                                CEO Forename is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-3">
                            <div class="mb-3">
                              <label class="form-label">
                                CEO Last Name<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="CEO Last Name"
                                v-model="UpdateCompany.surname"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.surname.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.surname.required
                                "
                                class="invalid-feedback"
                              >
                                CEO Last Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-3">
                            <div class="mb-3">
                              <label class="form-label">
                                CEO Email<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="CEO Email Address"
                                v-model="UpdateCompany.ceo_email"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.ceo_email.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.ceo_email.required
                                "
                                class="invalid-feedback"
                              >
                                CEO Email Address is required
                              </div>
                              <div
                                v-if="
                                  submitted && !$v.UpdateCompany.ceo_email.email
                                "
                                class="invalid-feedback"
                              >
                                Valid Email Address is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-3">
                            <div class="mb-3">
                              <label class="form-label">
                                CEO Phone Number<span class="text-danger"
                                  >*</span
                                >
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="CEO Phone Number"
                                v-model="UpdateCompany.ceo_contact_number"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.ceo_contact_number.$error,
                                }"
                                maxlength="16"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.ceo_contact_number.required
                                "
                                class="invalid-feedback"
                              >
                                CEO Phone Number is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"
                                >PocketBox Champion<span class="text-danger"
                                  >*</span
                                ></label
                              >
                              <select
                                class="form-select"
                                v-model="UpdateCompany.contact_type"
                              >
                                <option value="1">CEO</option>
                                <option value="2">Other</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-2">
                            <div class="mb-3">
                              <label class="form-label"
                                >Fleet Contract Type
                              </label>
                              <select
                                v-model="UpdateCompany.fleet_subscription"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.fleet_subscription.$error,
                                }"
                                class="form-select"
                              >
                                <option value="">
                                  Select Fleet Contract Type
                                </option>
                                <option
                                  v-for="FleetSubscription in FleetSubscriptions"
                                  v-bind:value="FleetSubscription.id"
                                  v-bind:key="FleetSubscription.id"
                                >
                                  {{ FleetSubscription.title }}
                                </option>
                              </select>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.fleet_subscription.required
                                "
                                class="invalid-feedback"
                              >
                                Fleet Contract Type Type is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-2">
                            <div class="mb-3">
                              <label class="form-label"
                                >Plus Contract Type<span class="text-danger"
                                  >*</span
                                ></label
                              >
                              <select
                                v-model="UpdateCompany.marketplace_plan"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.marketplace_plan.$error,
                                }"
                                class="form-select"
                              >
                                <option value="">
                                  Select Plus Contract Type
                                </option>
                                <option
                                  v-for="Subscription in Subscriptions"
                                  v-bind:value="Subscription.id"
                                  v-bind:key="Subscription.id"
                                >
                                  {{ Subscription.title }}
                                </option>
                              </select>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.marketplace_plan.required
                                "
                                class="invalid-feedback"
                              >
                                Plus Contract Type is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-2">
                            <div class="mb-3">
                              <label class="form-label">
                                Service Start Date<span class="text-danger"
                                  >*</span
                                >
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                placeholder="Service Start Date"
                                v-model="UpdateCompany.service_start_date"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.service_start_date.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.service_start_date.required
                                "
                                class="invalid-feedback"
                              >
                                Service Date is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-2">
                            <div class="mb-3">
                              <label class="form-label"
                                >Contract Length<span class="text-danger"
                                  >*</span
                                ></label
                              >
                              <select
                                v-model="UpdateCompany.contract_length"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.contract_length.$error,
                                }"
                                class="form-select"
                              >
                                <option value="">Select Contract Length</option>
                                <option
                                  v-for="index in 12"
                                  :key="index"
                                  v-bind:value="index"
                                >
                                  {{ index }} Month
                                </option>
                              </select>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.contract_length.required
                                "
                                class="invalid-feedback"
                              >
                                Contract Length is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="UpdateCompany.contact_type == '2'"
                        >
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Champion First Name<span class="text-danger"
                                  >*</span
                                >
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Pocket Box Champion"
                                v-model="UpdateCompany.champion_firstname"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.champion_firstname.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.champion_firstname.required
                                "
                                class="invalid-feedback"
                              >
                                Champion First Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Champion Last Name<span class="text-danger"
                                  >*</span
                                >
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Pocket Box Champion"
                                v-model="UpdateCompany.champion_lastname"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.champion_lastname.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.champion_lastname.required
                                "
                                class="invalid-feedback"
                              >
                                Champion Last Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Champion Email<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Champion Email Address"
                                v-model="UpdateCompany.champion_email"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.champion_email.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.champion_email.required
                                "
                                class="invalid-feedback"
                              >
                                Champion Email Address is required
                              </div>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.champion_email.email
                                "
                                class="invalid-feedback"
                              >
                                Valid Email Address is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Champion Phone Number<span class="text-danger"
                                  >*</span
                                >
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Champion Phone Number"
                                v-model="UpdateCompany.champion_contact_number"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.champion_contact_number
                                      .$error,
                                }"
                                maxlength="16"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.champion_contact_number
                                    .required
                                "
                                class="invalid-feedback"
                              >
                                Champion Phone Number is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12 col-lg-12">
                            <div class="mb-3">
                              <label class="form-label">Company Notes</label>
                              <textarea
                                type="text"
                                class="form-control"
                                placeholder="Enter Note"
                                cols="10"
                                rows="10"
                                style="height: 60px"
                                v-model="UpdateCompany.company_note"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.UpdateCompany.company_note.$error,
                                }"
                              ></textarea>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.company_note.required
                                "
                                class="invalid-feedback"
                              >
                                Company Notes is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                MOT Partner Company
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="MOT Partner Company"
                                v-model="UpdateCompany.MOTPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Tax Partner Company
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Tax Partner Company"
                                v-model="UpdateCompany.TaxPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                License Check Partner
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="License Check Partner"
                                v-model="UpdateCompany.LicenseCheckPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Insurance Partner Company
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Insurance Partner Company"
                                v-model="UpdateCompany.InsurancePartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Car Sell Partner
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Car Sell Partner"
                                v-model="UpdateCompany.CarSellPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Accident Report Partner
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Accident Report Partner"
                                v-model="UpdateCompany.AccidentReportPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Garage Booking Partner
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Garage Booking Partner"
                                v-model="UpdateCompany.GarageBookingPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> Facebook </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Facebook URL"
                                v-model="UpdateCompany.Facebook"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> Twitter </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Twitter URL"
                                v-model="UpdateCompany.Twitter"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> LinkedIn </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="LinkedIn URL"
                                v-model="UpdateCompany.LinkedIn"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> Instagram </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Instagram URL"
                                v-model="UpdateCompany.Instagram"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> YouTube </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="YouTube URL"
                                v-model="UpdateCompany.YouTube"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Send Message Duration
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Send Message Duration"
                                v-model="UpdateCompany.MessageDuration"
                                max="2"
                              />
                              <small>Enter send message duration in days</small>
                            </div>
                          </div>

                          <div class="col-sm-12 col-lg-8">
                            <div class="row">
                              <div class="col-sm-12 col-lg-12">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Vehicle Check Templates</label
                                  >
                                  <div class="row">
                                    <template v-for="(classData, index) in Classes">
                                      <div
                                        class="col-sm-12 col-lg-3 p-t-10"
                                      >
                                        <input
                                          type="checkbox"
                                          v-model="UpdateCompany.check_templates"
                                          :value="classData.id"
                                          :true-value="[]"
                                          :disabled="classData.total_uses > 0"
                                          :id="'row+' + index"
                                        />
                                        <label
                                          style="padding-left: 5px; cursor: pointer"
                                          :for="'row+' + index"
                                          >{{ classData.title }}</label
                                        >
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-12">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Inspection Templates</label
                                  >
                                  <div class="row">
                                    <template v-for="(classData, index) in InspectionClasses">
                                      <div
                                        class="col-sm-12 col-lg-3 p-t-10"
                                      >
                                        <input
                                          type="checkbox"
                                          v-model="UpdateCompany.inspection_templates"
                                          :value="classData.id"
                                          :true-value="[]"
                                          :disabled="classData.total_uses > 0"
                                          :id="'row+' + index"
                                        />
                                        <label
                                          style="padding-left: 5px; cursor: pointer"
                                          :for="'row+' + index"
                                          >{{ classData.title }}</label
                                        >
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"
                                >Email Bottom Banner</label
                              >
                              <input
                                class="input form-control"
                                type="file"
                                name="banner"
                                ref="bannerFile"
                                accept="image/*"
                                :class="{
                                  'is-invalid':
                                    $v.UpdateCompany.banner_image.$error,
                                }"
                                @change="bannerImageFileChanged($event)"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.banner_image
                                    .file_size_validation
                                "
                                class="invalid-feedback"
                              >
                                Please upload less than 1 MB file size image
                              </div>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.UpdateCompany.banner_image
                                    .image_type_validation
                                "
                                class="invalid-feedback"
                              >
                                Please upload jpeg, png, gif or svg file only
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-lg-12"
                            v-show="UpdateCompany.Banner"
                          >
                            <div class="mb-3">
                              <label class="form-label"> Email Banner </label>
                              <img
                                ref="CompanyBannerPreview"
                                class="company__banner__preview"
                              />
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-lg-12 text-right button-section"
                          >
                            <router-link
                              :to="{ name: 'Companies' }"
                              class="btn btn-default"
                            >
                              &larr; Back</router-link
                            >
                            <button
                              class="btn btn-pocketbox"
                              v-on:click="UpdateCompanyData()"
                              :disabled="Saving"
                            >
                              Update Company
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- Step 1 start -->
                      <Loading v-if="Saving" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    required,
    url,
    email,
    minLength,
    sameAs,
    requiredIf,
  } from "vuelidate/lib/validators";
  import Navigation from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  import Select2 from "v-select2-component";
  import citiesData from "@/assets/JsonData/cities.json";
  import countiesData from "@/assets/JsonData/counties.json";

  // set cities data
  var citiesList = [];
  var keys = Object.keys(citiesData);
  keys.forEach(function (key) {
    citiesList.push(citiesData[key]);
  });

  // set counties data
  var countiesList = [];
  var keys = Object.keys(countiesData);
  keys.forEach(function (key) {
    countiesList.push(countiesData[key]);
  });

  const file_size_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    return file.size < 1048576;
  };
  const image_type_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    if (
      file.type == "image/svg+xml" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif" ||
      file.type == "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };
  export default {
    components: {
      Navigation,
      Loading,
      Select2,
    },
    data() {
      return {
        CompanyId: 0,
        Saving: false,
        submitted: false,
        Companies: false,
        Categories: {},
        Counties: countiesList,
        Cities: citiesList,
        Subscriptions: {},
        FleetSubscriptions: {},
        CompanyLogoFilename: "",
        CompanyBannerFilename: "",
        UpdateCompany: {
          name: "",
          default_category: "",
          mileage_unit:"",
          fleet_subscription: "",
          logo_image: "",
          website: "",
          email: "",
          colour_1: "",
          colour_2: "",
          Banner: "",
          contact_number: "",
          address: "",
          /* town: "",
          county: "", */
          postcode: "",
          forename: "",
          surname: "",
          ceo_email: "",
          ceo_contact_number: "",
          contact_type: "",
          champion_firstname: "",
          champion_lastname: "",
          champion_email: "",
          champion_contact_number: "",
          company_note: "",
          marketplace_plan: "",
          service_start_date: "",
          contract_length: "",
          Facebook: "",
          Twitter: "",
          LinkedIn: "",
          YouTube: "",
          Instagram: "",
          banner_image: "",
          MOTPartner: "",
          TaxPartner: "",
          MessageDuration: "",
          LicenseCheckPartner: "",
          InsurancePartner: "",
          CarSellPartner: "",
          AccidentReportPartner: "",
          GarageBookingPartner: "",
          check_templates: [],
          inspection_templates: [],
        },
        Classes: [],
        InspectionClasses: [],
      };
    },
    validations: {
      UpdateCompany: {
        name: { required },
        default_category: { required },
        mileage_unit: { required },
        logo_image: { file_size_validation, image_type_validation },
        website: {
          required,
          url,
        },
        email: { required, email },
        contact_number: { required },
        address: { required },
        /* town: { required },
        county: { required }, */
        postcode: { required },
        forename: { required },
        surname: { required },
        ceo_email: { required, email },
        ceo_contact_number: { required },
        champion_firstname: {
          required: requiredIf(function () {
            return this.UpdateCompany.contact_type == 2 ? true : false;
          }),
        },
        champion_lastname: {
          required: requiredIf(function () {
            return this.UpdateCompany.contact_type == 2 ? true : false;
          }),
        },
        champion_email: {
          required: requiredIf(function () {
            return this.UpdateCompany.contact_type == 2 ? true : false;
          }),
          email,
        },
        champion_contact_number: {
          required: requiredIf(function () {
            return this.UpdateCompany.contact_type == 2 ? true : false;
          }),
        },
        company_note: {},
        marketplace_plan: { required },
        service_start_date: { required },
        contract_length: { required },
        fleet_subscription: {},
        banner_image: {
          file_size_validation,
          image_type_validation,
        },
      },
    },
    mounted() {
      document.title = " PocketBox Admin - Add New Company";
      this.CompanyId = this.$route.params.CompanyId;
      this.GetCategoriesList();
      this.GetSubscriptionsList();
      this.GetFleetSubscriptionsList();
      this.getCompanyData();
    },
    methods: {
      getCompanyData() {
        this.Saving = true;
        Axios.post(Endpoints.Company.Get, {
          token: localStorage.getItem("token"),
          id: this.CompanyId,
        }).then((Response) => {
          if (typeof Response.data.record != "undefined") {
            //set contact type
            Response.data.record.contact_type = 1;
            Response.data.record.forename = "";
            Response.data.record.surname = "";
            Response.data.record.ceo_email = "";
            Response.data.record.ceo_contact_number = "";
            Response.data.record.champion_firstname = "";
            Response.data.record.champion_lastname = "";
            Response.data.record.champion_email = "";
            Response.data.record.champion_contact_number = "";
            this.UpdateCompany = Response.data.record;

            // set social links
            var settingData = JSON.parse(Response.data.record.email_settings);
            this.UpdateCompany.Banner = settingData.Banner;
            this.UpdateCompany.Facebook = settingData.Social.Facebook;
            this.UpdateCompany.Twitter = settingData.Social.Twitter;
            this.UpdateCompany.Instagram = settingData.Social.Instagram;
            this.UpdateCompany.LinkedIn = settingData.Social.LinkedIn;
            this.UpdateCompany.YouTube = settingData.Social.YouTube;

            if (settingData.MessageDuration != "undefined") {
              this.UpdateCompany.MessageDuration = settingData.MessageDuration;
            }

            if (typeof settingData.CompanyPartner != "undefined") {
              this.UpdateCompany.MOTPartner =
                settingData.CompanyPartner.MOTPartner;
              this.UpdateCompany.TaxPartner =
                settingData.CompanyPartner.TaxPartner;
              this.UpdateCompany.LicenseCheckPartner =
                settingData.CompanyPartner.LicenseCheckPartner;
              this.UpdateCompany.InsurancePartner =
                settingData.CompanyPartner.InsurancePartner;
              if (
                typeof settingData.CompanyPartner.CarSellPartner != "undefined"
              ) {
                this.UpdateCompany.CarSellPartner =
                  settingData.CompanyPartner.CarSellPartner;
                this.UpdateCompany.AccidentReportPartner =
                  settingData.CompanyPartner.AccidentReportPartner;
                this.UpdateCompany.GarageBookingPartner =
                  settingData.CompanyPartner.GarageBookingPartner;
              }
            }

            this.UpdateCompany.contact_type =
              this.UpdateCompany.contacts.length > 0
                ? this.UpdateCompany.contacts.length
                : 1;
            //set contact data
            this.UpdateCompany.contacts.forEach((Contact) => {
              if (typeof Contact.contact_type != "undefined") {
                if (Contact.contact_type == 1) {
                  //set ceo contact data
                  this.UpdateCompany.forename = Contact.forename;
                  this.UpdateCompany.surname = Contact.surname;
                  this.UpdateCompany.ceo_email = Contact.email;
                  this.UpdateCompany.ceo_contact_number =
                    Contact.contact_number;
                } else if (Contact.contact_type == 2) {
                  //set champion contact data
                  this.UpdateCompany.champion_firstname = Contact.forename;
                  this.UpdateCompany.champion_lastname = Contact.surname;
                  this.UpdateCompany.champion_email = Contact.email;
                  this.UpdateCompany.champion_contact_number =
                    Contact.contact_number;
                }
              }
            });

            this.getClasses();
            this.getInspectionClasses();
          }
          this.Saving = false;
          // get old company logo
          this.CompanyLogoFilename = this.UpdateCompany.logo;
          if (this.UpdateCompany.logo != "") {
            this.GetCompanyLogo();
          }
          // get old banner
          this.CompanyBannerFilename = this.UpdateCompany.Banner;
          if (this.UpdateCompany.Banner != "") {
            this.GetCompanyBanner();
          }
        });
      },
      GetCompanyLogo() {
        Axios.post(Endpoints.Helper.File.Get, {
          token: localStorage.getItem("token"),
          file: this.CompanyLogoFilename,
          company_id: this.CompanyId,
        }).then((FileGetResponse) => {
          var FileData = FileGetResponse.data;
          if (FileData.success === true) {
            this.CompanyLogo = FileData.file;
            this.$refs.CompanyLogoPreview.src = FileData.file;
          }
        });
      },
      GetCompanyBanner() {
        Axios.post(Endpoints.Helper.File.Get, {
          token: localStorage.getItem("token"),
          file: this.CompanyBannerFilename,
          company_id: this.CompanyId,
        }).then((FileGetResponse) => {
          var FileData = FileGetResponse.data;
          if (FileData.success === true) {
            this.CompanyBanner = FileData.file;
            this.$refs.CompanyBannerPreview.src = FileData.file;
          }
        });
      },
      GetCategoriesList() {
        Axios.post(Endpoints.Category.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Categories = Response.data.records;
        });
      },
      GetSubscriptionsList() {
        Axios.post(Endpoints.Subscription.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Subscriptions = Response.data.records;
        });
      },
      getClasses() {
        Axios.post(Endpoints.Vehicle.Classes, {
          token: localStorage.getItem("token"),
          company_id: this.CompanyId,
        }).then((Response) => {
          this.Classes = Response.data.records;
          this.UpdateCompany.check_templates = [];
          this.Classes.forEach((classData) => {
            if (classData.total_checks > 0) {
              this.UpdateCompany.check_templates.push(classData.id);
            }
          });
        });
      },
      getInspectionClasses() {
        Axios.post(Endpoints.Inspection.Classes, {
          token: localStorage.getItem("token"),
          company_id: this.CompanyId,
        }).then((Response) => {
          this.InspectionClasses = Response.data.records;
          this.UpdateCompany.inspection_templates = [];
          this.InspectionClasses.forEach((classData) => {
            if (classData.total_uses > 0) {
              this.UpdateCompany.inspection_templates.push(classData.id);
            }
          });
        });
      },
      GetFleetSubscriptionsList() {
        Axios.post(Endpoints.FleetSubscription.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.FleetSubscriptions = Response.data.records;
        });
      },
      imageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        console.log(files.length);
        if (!files.length) {
          return;
        }
        this.UpdateCompany.logo_image = files[0];
        var output = document.querySelector(".company__logo__preview");
        output.src = URL.createObjectURL(files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
      },
      bannerImageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.UpdateCompany.banner_image = files[0];
      },
      UpdateCompanyData() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Company.Update, {
          token: localStorage.getItem("token"),
          ...this.UpdateCompany,
        }).then((Response) => {
          if (Response.data.success) {
            this.SaveCompanyLogo(Response.data.last_id);
          } else {
            this.Saving = false;
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      SaveCompanyLogo(id) {
        var LogoFormData = new FormData();
        if (this.$refs.logoFile.files[0]) {
          LogoFormData.append("storage_type", "CC");
          LogoFormData.append("token", localStorage.getItem("token"));
          LogoFormData.append("file", this.$refs.logoFile.files[0]);
          LogoFormData.append("company_id", id);
          Axios.post(Endpoints.Helper.File.Upload, LogoFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Company.LogoSet, {
                  token: localStorage.getItem("token"),
                  logo: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  this.SaveBannerImage(id);
                });
              }
            }
          );
        } else {
          this.SaveBannerImage(id);
        }
      },
      SaveBannerImage(id) {
        var BannerFormData = new FormData();
        if (this.$refs.bannerFile.files[0]) {
          BannerFormData.append("storage_type", "CC");
          BannerFormData.append("token", localStorage.getItem("token"));
          BannerFormData.append("file", this.$refs.bannerFile.files[0]);
          BannerFormData.append("company_id", id);
          Axios.post(Endpoints.Helper.File.Upload, BannerFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Company.BannerSet, {
                  token: localStorage.getItem("token"),
                  banner: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  this.Saving = false;
                  Swal.fire("Company Updated Successfully", "", "success").then(
                    () => {
                      this.$router.push("/companies");
                    }
                  );
                });
              }
            }
          );
        } else {
          this.Saving = false;
          Swal.fire("Company Updated Successfully", "", "success").then(() => {
            this.$router.push("/companies");
          });
        }
      },
    },
  };
</script>
<style>
  .colorSection span {
    display: inline-block;
    width: 80px;
  }
  .colorSection input {
    display: inline-block;
    width: 40px;
    padding: 0;
    height: 40px;
    vertical-align: middle;
  }
  .button-section a,
  .button-section .btn-default {
    padding: 8px 20px;
    margin-right: 20px;
  }
  .button-section .btn-pocketbox {
    padding: 8px 20px;
    background-color: #357ffa;
    border-color: #357ffa;
    border-radius: 4px;
  }

  .activeForm {
    display: block;
  }
  .select2-selection--single {
    display: block;
    width: 100%;
    padding: 0.4375rem 2.25rem 0.4375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714;
    color: #232e3c;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #dadcde !important;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 36px !important;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    display: block;
    width: 100%;
    padding: 7px 12px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714;
    color: #232e3c;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dadcde' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 4px center;
    background-size: 16px 12px;
    border: none;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    right: 0%;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    right: 22px;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 20px;
    padding-left: 0px;
  }
  .is-invalid .select2-selection--single {
    border-color: #d63939 !important;
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dadcde' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23d63939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
    background-position: right 10px center, center right 35px;
    background-size: 16px 12px,
      calc(0.7142857em + 0.4375rem) calc(0.7142857em + 0.4375rem);
  }
  .custom_search .select2-selection {
    display: block;
    width: 100%;
    padding: 0.4375rem 2.25rem 0.4375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714;
    color: #232e3c;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #dadcde !important;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 36px !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dadcde' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    margin-top: 0px !important;
  }
</style>
