<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-box">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-6 p-4">
                    <h4>Fleet Subscriptions</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 px-4 mt-3 mb-3">
                    <div class="table-responsive table-color">
                      <table
                        class="table"
                        width="100%"
                        border="0"
                        id="subscriptions_list"
                      >
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Title</th>
                            <th>Max Drivers</th>
                            <th>Max Vehicle</th>
                            <th>Max Fleet Users</th>
                            <th>Custom Vehicle Checks</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="showLoader">
                          <tr>
                            <td>
                              <Loading />
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr
                            v-for="(Subscription, index) in Subscriptions"
                            :key="index"
                          >
                            <td class="w-1">{{ ++index }}</td>
                            <td>{{ Subscription.title }}</td>
                            <td>{{ Subscription.max_drivers }}</td>
                            <td>{{ Subscription.max_vehicle }}</td>
                            <td>{{ Subscription.max_fleet_users }}</td>
                            <td>
                              {{ Subscription.allowed_custom_vehicle_checks }}
                            </td>
                            <td>
                              <router-link
                                :to="{
                                  name: 'UpdateFleetSubscription',
                                  params: { SubscriptionId: Subscription.id },
                                }"
                                class="btn btn-primary btn-xs"
                                title="Edit User"
                                >Edit</router-link
                              >

                              <button
                                class="btn btn-primary btn-xs"
                                style="margin-left: 10px"
                                @click="SubscriptionDelete(Subscription.id)"
                                title="Delete Record"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "jquery/dist/jquery.min.js";
  import $ from "jquery";

  import Nav from "@/components/Navigation";
  import Axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";

  export default {
    components: {
      Nav,
      Loading,
    },
    mounted() {
      document.title = "PocketBox Admin - Fleet Subscriptions";
      this.GetSubscriptionsList(1, 1000);
    },
    data() {
      return {
        Subscriptions: {},
        editRec: {
          fleet_modules: {
            "Vehicle Checks": "",
          },
        },
        showLoader: false,
        search_keyword: "",
        ComponentKey: 0,
      };
    },
    methods: {
      forceRerender() {
        this.ComponentKey += 1;
      },
      GetSubscriptionsList(Start, Limit) {
        this.showLoader = true;
        Axios.post(Endpoints.FleetSubscription.List, {
          token: localStorage.getItem("token"),
          start: Start,
          limit: Limit,
        }).then((Response) => {
          this.Subscriptions = Response.data.records;
          this.showLoader = false;
          setTimeout(() => {
            $("#subscriptions_list").DataTable();
          }, 1000);
        });
      },
      SubscriptionDelete(id) {
        Swal.fire({
          title: "Are you sure to delete this fleet subscription?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Deleting...",
              text: "Please wait...",
              imageUrl: require("../../assets/images/LoadingTwo.gif"),
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            Axios.post(Endpoints.FleetSubscription.Remove, {
              token: localStorage.getItem("token"),
              id: id,
            }).then((Response) => {
              if (Response.data.success == true) {
                this.GetSubscriptionsList(1, 1000);
                Swal.fire(
                  "Fleet Subscription Deleted Successfully",
                  "",
                  "success"
                ).then(() => {});
              } else {
                Swal.fire({
                  title: "Oops!",
                  html: Response.data.message,
                  icon: "error",
                  confirmButtonText: "Close",
                });
              }
            });
          }
        });
      },
      refreshList() {
        this.$router.go();
      },
    },
  };
</script>
<style></style>
