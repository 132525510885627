<template>
  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="table-box">
      <div class="p-4 border-bottom">
        <h4>UK Map</h4>
        <p>Pocket Box UK Maps</p>
      </div>
      <div class="p-3">
        <div v-if="Loader" class="loading-class">
          <Loading />
        </div>
        <div id="regions_div" style="width: 100%; height: 400px"></div>
      </div>

      <!-- <img src="/assets/images/user/UK Maps.png" style="width:100%">  -->
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import UKsvg from "@/Admin/Uk.svg";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        SentNotificationToday: "",
        TotalDocuments: "",
        TotalVehicles: "",
        AverageVehiclesPerAccount: "",
        Loader: false,
        TotalDocumentsLoader: false,
        TotalVehiclesLoader: false,
        AverageVehiclesPerAccountLoader: false,
      };
    },
    mounted() {
      this.Loader = true;
      this.GetAnalytics("UsersByRegion");
    },
    methods: {
      GetAnalytics(type) {
        this.Loader = true;

        axios
          .post(Endpoints.Data.UsersFragment, {
            token: localStorage.getItem("token"),
            data: type,
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              this.SentNotificationToday =
                RequestResponse.data.data.UsersByRegion;
              this.Loader = false;
              /*Get Uk map stats*/
              var UsersByRegion = RequestResponse.data.data;
              if (UsersByRegion.Country.length > 0) {
                for (let uRIndex in UsersByRegion.Country) {
                  this.ukmap.push([uRIndex, UsersByRegion.Country[uRIndex]]);
                }
              }
              this.drawMap();
            }
          });
      },
      drawMap() {
        AmCharts.makeChart("regions_div", {
          type: "map",
          theme: "dark",
          dataProvider: {
            mapURL: UKsvg,
            getAreasFromMap: true,
          },
          areasSettings: {
            autoZoom: true,
            selectedColor: "#357ffa",
          },
        });
      },
    },
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .rowData {
    width: 100%;
    padding: 0;
    margin: 0;
  }
</style>
