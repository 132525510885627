var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('Nav'),_c('div',{staticClass:"app-wrapper"},[_c('div',{staticClass:"app-content pt-3 p-md-3 p-lg-4"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-md-6 col-lg-6 p-4"},[_c('h4',[_vm._v("Pocket Box Plus Companies Fleet Users")]),_c('p',[_vm._v(_vm._s(_vm.CompanyName)+" - Fleet Users")])]),_c('div',{staticClass:"col-sm-6 col-md-6 col-lg-6 p-4"},[_c('router-link',{staticClass:"btn btn-primary float-end",attrs:{"to":{ name: 'Companies' }}},[_vm._v(" ← Back")]),_c('router-link',{staticClass:"btn btn-primary float-end",attrs:{"to":'/company/add-fleet-user/' + _vm.CompanyId}},[_c('img',{attrs:{"src":"/assets/images/icons/Icon-add-company.svg"}}),_vm._v(" Add New User")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 px-4 mt-3 mb-3"},[_c('div',{staticClass:"table-responsive table-color"},[_c('table',{staticClass:"table table-vcenter card-table",attrs:{"id":"company_users_list"}},[_vm._m(0),(_vm.showLoader)?_c('tbody',[_c('tr',[_c('td',[_c('Loading')],1)])]):_vm._e(),_c('tbody',_vm._l((_vm.FleetUsers),function(FleetUser,index){return _c('tr',{key:index},[_c('td',[_c('div',{staticClass:"profile-image"},[_c('img',{staticClass:"listingIMG",attrs:{"src":_vm.GetProfileImage(
                                    FleetUser.id,
                                    FleetUser.profile_image
                                  ),"title":FleetUser.forename,"alt":FleetUser.forename},on:{"error":function($event){$event.target.src = _vm.GetProfileImage(
                                    FleetUser.id,
                                    FleetUser.profile_image
                                  )}}})])]),_c('td',[_vm._v(" "+_vm._s(FleetUser.forename)+" "+_vm._s(FleetUser.surname)+" ")]),_c('td',[_vm._v(_vm._s(FleetUser.email))]),_c('td',[_vm._v(" "+_vm._s(FleetUser.user_role)+" ")]),_c('td',[_vm._v(_vm._s(FleetUser.phone))]),_c('td',[_c('router-link',{staticClass:"btn btn-primary btn-xs",attrs:{"to":{
                                name: 'UpdateCompanyFleetUser',
                                params: { UserId: FleetUser.id },
                              },"title":"Edit User"}},[_vm._v("Edit")]),_c('button',{staticClass:"btn btn-primary btn-xs",staticStyle:{"margin-left":"10px"},attrs:{"title":"Delete Record"},on:{"click":function($event){return _vm.UserDelete(FleetUser.id)}}},[_vm._v(" Delete ")]),_c('button',{staticClass:"btn btn-primary btn-xs",staticStyle:{"margin-left":"10px"},attrs:{"type":"button","data-bs-toggle":"modal","data-bs-target":"#updateCompanyFleetUserPassword","title":"Update Password"},on:{"click":function($event){return _vm.UpdatePassword(FleetUser.id)}}},[_vm._v(" Update Password ")]),(FleetUser.is_2fa_enabled && FleetUser.google_2fa_secret_key != '' && FleetUser.google_2fa_secret_key != null)?_c('button',{staticClass:"btn btn-primary btn-xs",staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.Reset2F(FleetUser.id)}}},[_vm._v("Reset 2FA")]):_vm._e()],1)])}),0)])])])])])])])])])]),_c('div',{attrs:{"id":"modal"}},[_c('updateCompanyFleetUserPassword',{key:_vm.ComponentKey + 20,ref:"updateCompanyFleeUserPassword",attrs:{"updatePasswordID":_vm.updatePasswordID}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Profile Image")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Email Address")]),_c('th',[_vm._v("User Type")]),_c('th',[_vm._v("Contact Number")]),_c('th',{staticClass:"text-center"},[_vm._v("Edit")])])])}]

export { render, staticRenderFns }