<template>
  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="table-box table-full-height">
      <div class="p-3">
        <div class="col">
          <h4>Vehicles with upcoming expiries</h4>
        </div>
      </div>

      <div class="p-3">
        <div v-if="Loader" class="loading-class">
          <Loading />
        </div>
        <div
          class="line_cont"
          v-for="(VehicleUpcomingExpire, index) of VehicleUpcomingExpires"
          :key="index"
        >
          <md-progress-bar
            :class="'number_color_' + index"
            md-mode="determinate"
            :md-value="VehicleUpcomingExpire.expiring_percentage"
          >
          </md-progress-bar>
          <div class="lint_text" style="width: 100%">
            <span>
              {{ VehicleUpcomingExpire.title }} ({{
                VehicleUpcomingExpire.expiring_count
              }}/{{ VehicleUpcomingExpire.total_count }})</span
            >
            <span class="float-end">
              {{ VehicleUpcomingExpire.expiring_percentage }}%
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        VehicleUpcomingExpires: {},
        Loader: false,
      };
    },
    mounted() {
      this.Loader = true;
      axios
        .post(Endpoints.Data.VehiclesFragment, {
          token: localStorage.getItem("token"),
          data: "VehicleUpcomingExpires",
        })
        .then((RequestResponse) => {
          if (RequestResponse.data.success) {
            this.VehicleUpcomingExpires = RequestResponse.data.data;
            delete this.VehicleUpcomingExpires.TotalVehicleUpcomingExpire;
            this.Loader = false;
          }
        });
    },
    methods: {},
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
</style>
