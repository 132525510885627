<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="table-box">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 p-4">
                <h4>Fleet Account Roles</h4>
                <p>Pocket Box Fleet User Account Roles Information</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 px-4 mt-3 mb-3">
                <div class="table-responsive table-color">
                  <table class="table table-vcenter card-table" id="Roles_list">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Title</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="showLoader">
                      <tr>
                        <td colspan="5">
                          <Loading />
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr v-for="(Role, index) in Roles" :key="index">
                        <td class="w-1">{{ ++index }}</td>
                        <td>{{ Role.title }}</td>
                        <td>
                          <router-link
                            :to="{
                              name: 'UpdateFleetAccountRole',
                              params: { RoleId: Role.id },
                            }"
                            class="btn btn-primary"
                            title="Edit User"
                            >Edit</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "jquery/dist/jquery.min.js";
  import $ from "jquery";

  import Nav from "@/components/Navigation";
  import Axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";

  export default {
    components: {
      Nav,
      Loading,
    },
    mounted() {
      document.title = "PocketBox Admin - Fleet Account Roles";
      this.GetRolesList(1, 1000);
    },
    data() {
      return {
        Roles: {},
        editRec: {},
        showLoader: false,
        search_keyword: "",
        ComponentKey: 0,
      };
    },
    methods: {
      forceRerender() {
        this.ComponentKey += 1;
      },
      GetRolesList(Start, Limit) {
        this.showLoader = true;
        Axios.post(Endpoints.AccountRole.List, {
          token: localStorage.getItem("token"),
          start: Start,
          limit: Limit,
        }).then((Response) => {
          this.Roles = Response.data.records;
          this.showLoader = false;
          setTimeout(() => {
            $("#Roles_list").DataTable();
          }, 1000);
        });
      },
      refreshList() {
        this.GetRolesList(1, 1000);
        this.forceRerender();
      },
    },
  };
</script>
<style></style>
