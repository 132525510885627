<template>
  <div class="page">
    <Navigation />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-3 col-md-3 col-lg-3 flex">
              <div class="user-block blue-block p-3">
                <div v-if="TotalUserLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="new-users total-users">
                  <p class="user-month">Total Users</p>
                  <p class="arrow-text" v-if="TotalUser.Users">
                    <img src="assets/images/dashboard/logo-icon.svg" />
                    {{ TotalUser.Users.Total }}
                  </p>
                  <p class="per-text">
                    {{ TotalUser.NewUsersUpInLast30Days }} Up in the last 30
                    days
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart.svg" />
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 flex">
              <div class="user-block green-block p-3">
                <div v-if="NewUserLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="new-users">
                  <p class="user-month">NEW USERS THIS MONTH</p>
                  <p class="arrow-text">
                    <img src="assets/images/icons/arrow_upward.svg" />
                    {{ NewUsersMonth.NewUsersThisMonth }}
                  </p>
                  <p class="per-text">
                    {{ NewUsersMonth.NewUsersUpInLast30Days }} in the last 30
                    days
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart.svg" />
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 flex">
              <div class="user-block orange-block p-3">
                <div v-if="NewUserWeekLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="new-users">
                  <p class="user-month">New users this week</p>
                  <p class="arrow-text">{{ NewUsersWeek.NewUsersThisWeek }}</p>
                  <p class="per-text">
                    {{ NewUsersWeek.NewUsersUpInLastWeek }} Off New users this
                    week
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart1.svg" />
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 flex">
              <div class="user-block purple-block p-3">
                <div v-if="NewUserTodayLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="new-users">
                  <p class="user-month">New Users Today</p>
                  <p class="arrow-text">
                    <img src="assets/images/icons/user-clock.svg" />
                    {{ NewUsersToday.NewUsersToday }}
                  </p>
                  <p class="per-text">
                    {{ NewUsersToday.NewUsersUpInYesterday }} Up since yesterday
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart.svg" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-3 col-md-3 col-lg-3 mb-4">
              <div class="total-block d-flex">
                <img src="assets/images/icons/email.svg" />
                <div class="total-noti">
                  <p class="total-notification-col">
                    Total Notifications Sent This Week
                  </p>
                  <div v-if="NotificationLoader" class="loading-class">
                    <Loading />
                  </div>
                  <p class="total-not-no" v-if="!NotificationLoader">
                    {{ TotalNotifications }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mb-4">
              <div class="total-block d-flex">
                <img src="assets/images/icons/documents.svg" />
                <div class="total-noti">
                  <p class="total-notification-col">Total Documents</p>
                  <div v-if="TotalDocLoader" class="loading-class">
                    <Loading />
                  </div>
                  <p class="total-not-no" v-if="TotalDocuments.Documents">
                    {{ TotalDocuments.Documents.Total }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 mb-4">
              <div class="total-block d-flex">
                <img src="assets/images/icons/cars.svg" />
                <div class="total-noti">
                  <p class="total-notification-col">Total Vehicles</p>
                  <div v-if="TotalVehicleLoader" class="loading-class">
                    <Loading />
                  </div>
                  <p class="total-not-no">{{ TotalVehicles.TotalVehicles }}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3">
              <div class="total-block d-flex">
                <img src="assets/images/icons/company.svg" />
                <div class="total-noti">
                  <p class="total-notification-col">Total Companies</p>
                  <div v-if="TotalCompanyLoader" class="loading-class">
                    <Loading />
                  </div>
                  <p class="total-not-no" v-if="!TotalCompanyLoader">
                    {{ TotalCompanies }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-sm-8 col-md-8 col-lg-8">
              <div class="table-box">
                <div class="row p-3">
                  <div class="col">
                    <h4>Active User</h4>
                    <p>User which have viewed the app on their device</p>
                  </div>
                  <div class="col text-end blurDiv"></div>
                </div>
                <div v-if="ActiveUserLoader" class="loading-class">
                  <Loading />
                </div>
                <canvas
                  ref="chart_DailyUsers"
                  style="width: 100%; height: 400px"
                ></canvas>
              </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <div class="table-box">
                <div class="row p-3">
                  <h4>Popular User Areas</h4>
                  <p>Areas with most user downloads</p>
                </div>
                <div class="table-responsive">
                  <table class="table" width="100%" border="0">
                    <thead>
                      <tr>
                        <th width="50%">Area/City</th>
                        <th width="25%">Users</th>
                        <th width="25%">New Users</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div class="table-responsive h-350">
                  <table class="table" width="100%" border="0">
                    <div v-if="UserAreaLoader" class="loading-class">
                      <Loading />
                    </div>
                    <tbody>
                      <tr
                        v-for="(PopularUserArea, index) in PopularUserAreas"
                        :key="index"
                      >
                        <td width="50%">{{ PopularUserArea.area }}</td>
                        <td width="25%" class="text-center">
                          {{ PopularUserArea.users }}
                        </td>
                        <td width="25%" class="text-center">
                          {{ PopularUserArea.new_users }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-box">
                <div class="row">
                  <div class="col-sm-8 col-md-8 col-lg-8 p-4">
                    <h4>Plus Companies</h4>
                    <p>
                      Details of companies recently added to pocket box plus
                    </p>
                  </div>
                </div>
                <div class="tab-table">
                  <ul
                    class="nav nav-pills mb-3 mx-2"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-new-company-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-new-company"
                        type="button"
                        role="tab"
                        aria-controls="pills-new-company"
                        aria-selected="true"
                      >
                        New Companies
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-popular-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-popular"
                        type="button"
                        role="tab"
                        aria-controls="pills-popular"
                        aria-selected="false"
                      >
                        Popular
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-unpopular-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-unpopular"
                        type="button"
                        role="tab"
                        aria-controls="pills-unpopular"
                        aria-selected="false"
                      >
                        Unpopular
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-up-renewal-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-up-renewal"
                        type="button"
                        role="tab"
                        aria-controls="pills-up-renewal"
                        aria-selected="false"
                      >
                        Upcoming Renewals
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-new-company"
                      role="tabpanel"
                      aria-labelledby="pills-new-company-tab"
                    >
                      <div class="table-responsive">
                        <table class="table" width="100%" border="0">
                          <thead>
                            <tr>
                              <th>Company Name</th>
                              <th>Type</th>
                              <th>Sales Date</th>
                              <th>Renewal Date</th>
                              <th>Subscription</th>
                              <th>Users</th>
                              <th>Change</th>
                              <th>% Change</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody v-if="!Companies.length">
                            <tr>
                              <td colspan="9" class="text-center">
                                Loading...
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-if="Companies.length">
                            <tr
                              v-for="(Company, Index) in Companies"
                              :key="Index"
                            >
                              <td>{{ Company.name }}</td>
                              <td>{{ Company.business_type }}</td>
                              <td>{{ Company.service_start_date }}</td>
                              <td>{{ Company.service_end_date }}</td>
                              <td>£{{ Company.package_price }}</td>
                              <td>{{ Company.total_users }}</td>
                              <td>
                                <canvas
                                  :id="'companies_' + Index"
                                  style="width: 100%; height: 40px"
                                ></canvas>
                              </td>
                              <td>
                                <img
                                  src="assets/images/icons/arrow_upward-2.svg"
                                  width="16"
                                />
                                {{ Company.change_percentage.Up }}
                                <img
                                  src="assets/images/icons/arrow_downward-2.svg"
                                  width="16"
                                />
                                {{ Company.change_percentage.Down }}
                              </td>
                              <td>
                                <a :href="'/company-update/' + Company.id"
                                  ><img src="assets/images/icons/more.png"
                                /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="p-4 view-all">
                          <a :href="'/companies'">View All</a>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-popular"
                      role="tabpanel"
                      aria-labelledby="pills-popular-tab"
                    >
                      <div class="table-responsive">
                        <table class="table" width="100%" border="0">
                          <thead>
                            <tr>
                              <th>Company Name</th>
                              <th>Type</th>
                              <th>Sales Date</th>
                              <th>Renewal Date</th>
                              <th>Subscription</th>
                              <th>Users</th>
                              <th>Change</th>
                              <th>% Change</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody v-if="!PopularComapnies.length">
                            <tr>
                              <td colspan="9" class="text-center">
                                Loading...
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-if="PopularComapnies.length">
                            <tr
                              v-for="(Company, Index) in PopularComapnies"
                              :key="Index"
                            >
                              <td>{{ Company.name }}</td>
                              <td>{{ Company.business_type }}</td>
                              <td>{{ Company.service_start_date }}</td>
                              <td>{{ Company.service_end_date }}</td>
                              <td>£{{ Company.package_price }}</td>
                              <td>{{ Company.total_users }}</td>
                              <td>
                                <canvas
                                  :id="'popularcompanies_' + Index"
                                  style="width: 100%; height: 40px"
                                ></canvas>
                              </td>
                              <td>
                                <img
                                  src="assets/images/icons/arrow_upward-2.svg"
                                  width="16"
                                />
                                {{ Company.change_percentage.Up }}
                                <img
                                  src="assets/images/icons/arrow_downward-2.svg"
                                  width="16"
                                />
                                {{ Company.change_percentage.Down }}
                              </td>
                              <td>
                                <a :href="'/company-update/' + Company.id"
                                  ><img src="assets/images/icons/more.png"
                                /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="p-4 view-all">
                          <a :href="'/companies'">View All</a>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-unpopular"
                      role="tabpanel"
                      aria-labelledby="pills-unpopular-tab"
                    >
                      <div class="table-responsive">
                        <table class="table" width="100%" border="0">
                          <thead>
                            <tr>
                              <th>Company Name</th>
                              <th>Type</th>
                              <th>Sales Date</th>
                              <th>Renewal Date</th>
                              <th>Subscription</th>
                              <th>Users</th>
                              <th>Change</th>
                              <th>% Change</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody v-if="!UnpopularComapnies.length">
                            <tr>
                              <td class="text-center">Loading...</td>
                            </tr>
                          </tbody>
                          <tbody v-if="UnpopularComapnies.length">
                            <tr
                              v-for="(Company, Index) in UnpopularComapnies"
                              :key="Index"
                            >
                              <td>{{ Company.name }}</td>
                              <td>{{ Company.business_type }}</td>
                              <td>{{ Company.service_start_date }}</td>
                              <td>{{ Company.service_end_date }}</td>
                              <td>£{{ Company.package_price }}</td>
                              <td>{{ Company.total_users }}</td>
                              <td>
                                <canvas
                                  :id="'unpopularcompanies_' + Index"
                                  style="width: 100%; height: 40px"
                                ></canvas>
                              </td>
                              <td>
                                <img
                                  src="assets/images/icons/arrow_upward-2.svg"
                                  width="16"
                                />
                                {{ Company.change_percentage.Up }}
                                <img
                                  src="assets/images/icons/arrow_downward-2.svg"
                                  width="16"
                                />
                                {{ Company.change_percentage.Down }}
                              </td>
                              <td>
                                <a :href="'/company-update/' + Company.id"
                                  ><img src="assets/images/icons/more.png"
                                /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="p-4 view-all">
                          <a :href="'/companies'">View All</a>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-up-renewal"
                      role="tabpanel"
                      aria-labelledby="pills-up-renewal-tab"
                    >
                      <div class="table-responsive">
                        <table class="table" width="100%" border="0">
                          <thead>
                            <tr>
                              <th>Company Name</th>
                              <th>Type</th>
                              <th>Sales Date</th>
                              <th>Renewal Date</th>
                              <th>Subscription</th>
                              <th>Users</th>
                              <th>Change</th>
                              <th>% Change</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody v-if="!UncomingRenewalCompanies.length">
                            <tr>
                              <td colspan="9" class="text-center">
                                Loading...
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-if="UncomingRenewalCompanies.length">
                            <tr
                              v-for="(
                                Company, Index
                              ) in UncomingRenewalCompanies"
                              :key="Index"
                            >
                              <td>{{ Company.name }}</td>
                              <td>{{ Company.business_type }}</td>
                              <td>{{ Company.service_start_date }}</td>
                              <td>{{ Company.service_end_date }}</td>
                              <td>£{{ Company.package_price }}</td>
                              <td>{{ Company.total_users }}</td>
                              <td>
                                <canvas
                                  :id="'reniewcompanies_' + Index"
                                  style="width: 100%; height: 40px"
                                ></canvas>
                              </td>
                              <td>
                                <img
                                  src="assets/images/icons/arrow_upward-2.svg"
                                  width="16"
                                />
                                {{ Company.change_percentage.Up }}
                                <img
                                  src="assets/images/icons/arrow_downward-2.svg"
                                  width="16"
                                />
                                {{ Company.change_percentage.Down }}
                              </td>
                              <td>
                                <a :href="'/company-update/' + Company.id"
                                  ><img src="assets/images/icons/more.png"
                                /></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="p-4 view-all">
                          <a :href="'/companies'">View All</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Navigation from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import axios from "axios";
  import Chart from "chart.js";
  import Loading from "@/components/Loading";

  export default {
    components: {
      Navigation,
      Loading,
    },
    mounted() {
      document.title = "PocketBox Admin - Dashboard";
      if (document.querySelector("body").classList.contains("login-body")) {
        this.$router.go();
      }
      var actions = [
        "TotalUsers",
        "NewUsersThisMonth",
        "NewUsersThisWeek",
        "NewUsersToday",
        "SentNotificationThisWeek",
        "TotalDocuments",
        "TotalVehicles",
        "TotalCompanies",
        "PopularUserArea",
        "ActiveUsers",
      ];
      var setTime = 2000;
      actions.forEach((action) => {
        setTimeout(() => {
          this.getDashboardDataFragment(action);
        }, setTime);
        setTime = parseInt(setTime) + 100;
      });
      this.getCompanies();
    },
    methods: {
      getDashboardDataFragment(type) {
        axios
          .post(Endpoints.Data.DashboardFragment, {
            token: localStorage.getItem("token"),
            data: type,
          })
          .then((Response) => {
            if (Response.data.success) {
              if (type == "TotalUsers") {
                this.TotalUser = Response.data.data;
                this.TotalUserLoader = false;
              }
              if (type == "NewUsersThisMonth") {
                this.NewUsersMonth = Response.data.data;
                this.NewUserLoader = false;
              }
              if (type == "NewUsersThisWeek") {
                this.NewUsersWeek = Response.data.data;
                this.NewUserWeekLoader = false;
              }
              if (type == "NewUsersToday") {
                this.NewUsersToday = Response.data.data;
                this.NewUserTodayLoader = false;
              }
              if (type == "SentNotificationThisWeek") {
                this.TotalNotifications =
                  Response.data.data.SentNotificationThisWeek;
                this.NotificationLoader = false;
              }
              if (type == "TotalDocuments") {
                this.TotalDocuments = Response.data.data;
                this.TotalDocLoader = false;
              }
              if (type == "TotalVehicles") {
                this.TotalVehicles = Response.data.data;
                this.TotalVehicleLoader = false;
              }
              if (type == "TotalCompanies") {
                this.TotalCompanies = Response.data.data;
                this.TotalCompanyLoader = false;
              }
              if (type == "PopularUserArea") {
                this.PopularUserAreas = Response.data.data.PopularUserAreas;
                this.UserAreaLoader = false;
              }
              if (type == "ActiveUsers") {
                this.ActiveThisMonth = Response.data.data;
                this.ActiveUserLoader = false;
                for (let Index in this.ActiveThisMonth) {
                  let Item = this.ActiveThisMonth[Index];
                  this.ActiveUserData.Labels.push(Index);
                  this.ActiveUserData.Dataset.push(Item);
                }
                this.DrawCharts();
              }
            }
          })
          .catch((error) => {
            this.getDashboardDataFragment(type);
          });
      },
      DrawCharts() {
        if (document.body.contains(this.$refs.chart_DailyUsers)) {
          var chart_DailyUsers2D = this.$refs.chart_DailyUsers.getContext("2d"),
            gradient = chart_DailyUsers2D.createLinearGradient(0, 0, 0, 450);
          gradient.addColorStop(0, "rgba( 44, 121, 249 , 0.5)");
          gradient.addColorStop(0.5, "rgba( 44, 121, 249 , 0.25)");
          gradient.addColorStop(1, "rgba( 44, 121, 249 , 0)");
          let chart_DailyUsers = new Chart(chart_DailyUsers2D, {
            type: "line",
            data: {
              labels: this.ActiveUserData.Labels,
              datasets: [
                {
                  label: "Active Users",
                  backgroundColor: gradient,
                  pointBackgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "#347ef9",
                  data: this.ActiveUserData.Dataset,
                },
              ],
            },
            options: {
              layout: {
                padding: 20,
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    position: "right",
                    ticks: {
                      display: true,
                      beginAtZero: true,
                      min: 0,
                    },

                    distribution: "linear",
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                  },
                ],
              },
              tooltips: {
                backgroundColor: "#3cb054",
                borderColor: "#3cb054",
                borderWidth: 1,
                borderRadius: 10,
                titleFontColor: "white",
                titleFontStyle: "bold",
                displayColors: false,
                bodyFontColor: "white",
                yAlign: "bottom",
              },
            },
          });
        }
      },
      DrawComapnyChart() {
        if (this.Companies.length > 0) {
          for (let Index in this.Companies) {
            let Change = this.Companies[Index].change;
            let ChangeUp = this.Companies[Index].change_percentage.Up.replace(
              "%",
              ""
            );
            let months = [];
            for (let cIndex in Change) {
              months.push(cIndex.replace(/\d/g, ""));
            }

            let data = [];
            for (let dIndex in Change) {
              data.push(Change[dIndex]);
            }

            if (eval(data.join("+")) > 0) {
              let refCanvas = "companies_" + Index;
              if (document.body.contains(document.getElementById(refCanvas))) {
                var ctx = document.getElementById(refCanvas).getContext("2d");
                new Chart(ctx, {
                  type: "line",
                  data: {
                    labels: months,
                    datasets: [
                      {
                        label: "Change",
                        borderWidth: 1,
                        backgroundColor: "white",
                        pointColor: ChangeUp > 0 ? "#76d967" : "#bc1c1c",
                        pointBackgroundColor: "white",
                        borderColor: ChangeUp > 0 ? "#76d967" : "#bc1c1c",
                        data: data,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                    },
                    elements: {
                      point: {
                        radius: 0,
                      },
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: true,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: true,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                    },
                  },
                });
              }
            }
          }
        }

        if (this.PopularComapnies.length > 0) {
          for (let Index in this.PopularComapnies) {
            let popularChange = this.PopularComapnies[Index].change;
            let popularChangeUp = this.PopularComapnies[
              Index
            ].change_percentage.Up.replace("%", "");
            let popularmonths = [];
            for (let cIndex in popularChange) {
              popularmonths.push(cIndex.replace(/\d/g, ""));
            }

            let populardata = [];
            for (let dIndex in popularChange) {
              populardata.push(popularChange[dIndex]);
            }

            if (eval(populardata.join("+")) > 0) {
              let popularRefCanvas = "popularcompanies_" + Index;
              if (
                document.body.contains(
                  document.getElementById(popularRefCanvas)
                )
              ) {
                var ctxPopular = document
                  .getElementById(popularRefCanvas)
                  .getContext("2d");
                new Chart(ctxPopular, {
                  type: "line",
                  data: {
                    labels: popularmonths,
                    datasets: [
                      {
                        label: "Change",
                        borderWidth: 1,
                        backgroundColor: "white",
                        pointColor: popularChangeUp > 0 ? "#76d967" : "#bc1c1c",
                        pointBackgroundColor: "white",
                        borderColor:
                          popularChangeUp > 0 ? "#76d967" : "#bc1c1c",
                        data: populardata,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                    },
                    elements: {
                      point: {
                        radius: 0,
                      },
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: true,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: true,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                    },
                  },
                });
              }
            }
          }
        }

        if (this.UnpopularComapnies.length > 0) {
          for (let Index in this.UnpopularComapnies) {
            let unpopularChange = this.UnpopularComapnies[Index].change;
            let unpopularChangeUp = this.UnpopularComapnies[
              Index
            ].change_percentage.Up.replace("%", "");
            let unpopularmonths = [];
            for (let cIndex in unpopularChange) {
              unpopularmonths.push(cIndex.replace(/\d/g, ""));
            }

            let unpopulardata = [];
            for (let dIndex in unpopularChange) {
              unpopulardata.push(unpopularChange[dIndex]);
            }

            if (eval(unpopulardata.join("+")) > 0) {
              let popularRefCanvas = "unpopularcompanies_" + Index;
              if (
                document.body.contains(
                  document.getElementById(popularRefCanvas)
                )
              ) {
                var ctxPopular = document
                  .getElementById(popularRefCanvas)
                  .getContext("2d");
                new Chart(ctxPopular, {
                  type: "line",
                  data: {
                    labels: unpopularmonths,
                    datasets: [
                      {
                        label: "Change",
                        borderWidth: 1,
                        backgroundColor: "white",
                        pointColor:
                          unpopularChangeUp > 0 ? "#76d967" : "#bc1c1c",
                        pointBackgroundColor: "white",
                        borderColor:
                          unpopularChangeUp > 0 ? "#76d967" : "#bc1c1c",
                        data: unpopulardata,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                    },
                    elements: {
                      point: {
                        radius: 0,
                      },
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: true,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: true,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                    },
                  },
                });
              }
            }
          }
        }
        if (this.UncomingRenewalCompanies.length > 0) {
          for (let Index in this.UncomingRenewalCompanies) {
            let upcomingChange = this.UncomingRenewalCompanies[Index].change;
            let upcomingChangeUp = this.UncomingRenewalCompanies[
              Index
            ].change_percentage.Up.replace("%", "");
            let upcomingmonths = [];
            for (let cIndex in upcomingChange) {
              upcomingmonths.push(cIndex.replace(/\d/g, ""));
            }

            let upcomingdata = [];
            for (let dIndex in upcomingChange) {
              upcomingdata.push(upcomingChange[dIndex]);
            }

            if (eval(upcomingdata.join("+")) > 0) {
              let upcomingRefCanvas = "reniewcompanies_" + Index;
              if (
                document.body.contains(
                  document.getElementById(upcomingRefCanvas)
                )
              ) {
                var ctxUpcomaing = document
                  .getElementById(upcomingRefCanvas)
                  .getContext("2d");
                new Chart(ctxUpcomaing, {
                  type: "line",
                  data: {
                    labels: upcomingmonths,
                    datasets: [
                      {
                        label: "Change",
                        borderWidth: 1,
                        backgroundColor: "white",
                        pointColor:
                          upcomingChangeUp > 0 ? "#76d967" : "#bc1c1c",
                        pointBackgroundColor: "white",
                        borderColor:
                          upcomingChangeUp > 0 ? "#76d967" : "#bc1c1c",
                        data: upcomingdata,
                      },
                    ],
                  },
                  options: {
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                    },
                    elements: {
                      point: {
                        radius: 0,
                      },
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: true,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: true,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                    },
                  },
                });
              }
            }
          }
        }
      },
      getCompanies() {
        /*New Companies*/
        axios
          .post(Endpoints.Company.List, {
            token: localStorage.getItem("token"),
            sort_by: "service_start_date",
            direction: "desc",
          })
          .then((DataResponse) => {
            let ResponseData = DataResponse.data;
            this.Companies = ResponseData.records;
          });
        /*Popular*/
        axios
          .post(Endpoints.Company.List, {
            token: localStorage.getItem("token"),
            sort_by: "total_users",
            direction: "DESC",
          })
          .then((DataResponse) => {
            let ResponseData = DataResponse.data;
            this.PopularComapnies = ResponseData.records;
          });

        /*unPopular*/
        axios
          .post(Endpoints.Company.List, {
            token: localStorage.getItem("token"),
            sort_by: "total_users",
            direction: "ASC",
          })
          .then((DataResponse) => {
            let ResponseData = DataResponse.data;
            this.UnpopularComapnies = ResponseData.records;
          });

        /*Uncoming Renewal Companies*/
        axios
          .post(Endpoints.Company.List, {
            token: localStorage.getItem("token"),
            sort_by: "service_end_date",
            direction: "ASC",
          })
          .then((DataResponse) => {
            let ResponseData = DataResponse.data;
            this.UncomingRenewalCompanies = ResponseData.records;
          });

        let self = this;
        setTimeout(function () {
          self.DrawComapnyChart();
        }, 2000);
      },
      filterData(array = [], sortOrder = "asc", sortKey = "") {
        sortOrder = sortOrder && sortOrder.toLowerCase();

        if (!array || (sortOrder !== "asc" && sortOrder !== "desc"))
          return array;

        let numberArray = [];
        let stringArray = [];
        if (!sortKey) {
          numberArray = array.filter((item) => typeof item === "number").sort();
          stringArray = array.filter((item) => typeof item === "string").sort();
        } else {
          numberArray = array
            .filter((item) => typeof item[sortKey] === "number")
            .sort((a, b) => a[sortKey] - b[sortKey]);
          stringArray = array
            .filter((item) => typeof item[sortKey] === "string")
            .sort((a, b) => {
              if (a[sortKey] < b[sortKey]) return -1;
              else if (a[sortKey] > b[sortKey]) return 1;
              else return 0;
            });
        }
        const sorted = numberArray.concat(stringArray);
        return sortOrder === "asc" ? sorted : sorted.reverse();
      },
    },
    data() {
      return {
        TotalUser: false,
        NewUsersMonth: false,
        NewUsersWeek: false,
        NewUsersToday: false,
        TotalNotifications: 0,
        TotalDocuments: 0,
        TotalVehicles: 0,
        TotalCompanies: 0,
        ActiveThisMonth: 0,
        Companies: [],
        PopularComapnies: [],
        UnpopularComapnies: [],
        UncomingRenewalCompanies: [],
        UserData: [],
        PopularUserAreas: [],
        NewUsersToday: 0,
        NewUsersThisWeek: 0,
        SentNotificationThisWeek: 0,
        ActiveUserData: {
          Labels: [],
          Dataset: [],
        },
        TotalUserLoader: true,
        NewUserLoader: true,
        NewUserWeekLoader: true,
        NewUserTodayLoader: true,
        NotificationLoader: true,
        TotalDocLoader: true,
        TotalVehicleLoader: true,
        TotalCompanyLoader: true,
        UserAreaLoader: true,
        ActiveUserLoader: true,
      };
    },
  };
</script>
<style>
  .h-350 {
    height: 350px;
  }
</style>
