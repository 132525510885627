<template>
  <div class="page">
    <Navigation />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <UserTopSections :key="11" ref="UserTopSections"></UserTopSections>
          </div>
          <div class="row gy-4 mt-1">
            <VehicleTopSections
              :key="10"
              ref="VehicleTopSections"
            ></VehicleTopSections>
          </div>
          <div class="row mt-4">
            <UserTopBottomSections
              :key="12"
              ref="UserTopBottomSections"
            ></UserTopBottomSections>
          </div>
          <div class="row mt-4">
            <UKMapSection :key="2" ref="UKMapSection"></UKMapSection>
            <PopularAreasSection
              :key="3"
              ref="PopularAreasSection"
            ></PopularAreasSection>
          </div>
          <div class="row mt-4">
            <UserAgeBreakdownSection
              :key="3"
              ref="PopularAreasSection"
            ></UserAgeBreakdownSection>
            <UserGenderBreakdownSection
              :key="132"
              ref="UserGenderBreakdownSection"
            ></UserGenderBreakdownSection>
          </div>
          <div class="row mt-4">
            <!-- pending -->
            <div class="col-sm-12">
              <div class="table-box p-4 table-full-height">
                <div class="p-3">
                  <h4>General Users Vs Plus Users</h4>
                  <p></p>
                </div>
                <div class="col users-charts user-chart-col">
                  <span
                    >General Users <br />
                    <b>{{ TotalGeneralUsers }}</b></span
                  >
                  <span
                    >Plus Users <br />
                    <b>{{ TotalPlusUsers }}</b>
                  </span>
                  <span
                    >Average User Per Company<br />
                    <b>{{ AvgUserPerCompany }}</b></span
                  >
                </div>
                <canvas
                  ref="GeneralUsersVsPlusUsers"
                  style="height: 350px; width: 100%"
                ></canvas>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <VehicleByType :key="1" ref="VehicleByType"></VehicleByType>
            <VehicleUpcomingExpiries
              :key="2"
              ref="VehicleUpcomingExpiries"
            ></VehicleUpcomingExpiries>
          </div>
          <div class="row mt-4">
            <PopularMakes :key="3" ref="PopularMakes"></PopularMakes>
            <VehiclesByMonth :key="4" ref="PopularMakes"></VehiclesByMonth>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Navigation from "@/components/Navigation";
  import Chart from "chart.js";
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import UserTopSections from "@/views/Users/Sections/UserTopSections";
  import UserTopBottomSections from "@/views/Users/Sections/UserTopBottomSections";
  import VehicleTopSections from "@/views/Vehicle/VehicleTopSections";
  import VehicleByType from "@/views/Vehicle/VehicleByType";
  import VehicleUpcomingExpiries from "@/views/Vehicle/VehicleUpcomingExpiries";
  import PopularMakes from "@/views/Vehicle/PopularMakes";
  import VehiclesByMonth from "@/views/Vehicle/VehiclesByMonth";
  import UKMapSection from "@/views/Users/Sections/UKMapSection";
  import PopularAreasSection from "@/views/Users/Sections/PopularAreasSection";
  import UserAgeBreakdownSection from "@/views/Users/Sections/UserAgeBreakdownSection";
  import UserGenderBreakdownSection from "@/views/Users/Sections/UserGenderBreakdownSection";
  export default {
    components: {
      Navigation,
      VehicleByType,
      VehicleUpcomingExpiries,
      PopularMakes,
      VehiclesByMonth,
      VehicleTopSections,
      UserTopSections,
      UserTopBottomSections,
      UKMapSection,
      PopularAreasSection,
      UserAgeBreakdownSection,
      UserGenderBreakdownSection,
    },
    data() {
      return {
        generalUsers: [],
        plusUsers: [],
        TotalGeneralUsers: 0,
        TotalPlusUsers: 0,
        AvgUserPerCompany: 0,
      };
    },
    mounted() {
      document.title = "PocketBox Admin - Analytics";

      /*Import External JS*/
      let PieceLabel = document.createElement("script");
      PieceLabel.setAttribute(
        "src",
        "https://rawgit.com/beaver71/Chart.PieceLabel.js/master/build/Chart.PieceLabel.min.js"
      );
      document.head.appendChild(PieceLabel);

      /*Get Data*/
      axios
        .post(Endpoints.Data.ChartsFragment, {
          token: localStorage.getItem("token"),
          data: "GeneralUsersVsPlusUsers",
        })
        .then((RequestResponse) => {
          if (RequestResponse.data.success) {
            /*General Users Vs Plus Users Graphs Data*/
            let GeneralUsersVsPlusUsersMonth =
              RequestResponse.data.data.GeneralUsersVsPlusUsers.Month;
            this.TotalGeneralUsers =
              RequestResponse.data.data.GeneralUsersVsPlusUsers.TotalGeneralUsers;
            this.TotalPlusUsers =
              RequestResponse.data.data.GeneralUsersVsPlusUsers.TotalPlusUsers;
            this.AvgUserPerCompany =
              RequestResponse.data.data.GeneralUsersVsPlusUsers.AvgUserPerCompany;

            for (let mIndex in GeneralUsersVsPlusUsersMonth) {
              let monthData = GeneralUsersVsPlusUsersMonth[mIndex];
              this.generalUsers.push(monthData.GeneralUsers);
              this.plusUsers.push(monthData.PlusUsers);
            }
          }
          this.DrawCharts();
        });
    },
    methods: {
      DrawCharts() {
        if (document.body.contains(this.$refs.GeneralUsersVsPlusUsers)) {
          var stackedBar = new Chart(
            this.$refs.GeneralUsersVsPlusUsers.getContext("2d"),
            {
              type: "line",
              data: {
                labels: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                datasets: [
                  {
                    label: "General Users",
                    data: this.generalUsers,
                    borderColor: ["#ef653d"],
                    backgroundColor: ["#ee6037"],
                    yAxisID: "y",
                  },
                  {
                    label: "Plus Users",
                    data: this.plusUsers,
                    borderColor: ["#528af1"],
                    backgroundColor: ["#528af1"],
                    yAxisID: "y1",
                  },
                ],
              },
              options: {
                legend: {
                  position: "top",
                  align: "end",
                },
                scales: {
                  yAxes: [
                    {
                      id: "y",
                      type: "linear",
                      position: "left",
                      gridLines: {
                        drawBorder: false,
                      },
                    },
                    {
                      id: "y1",
                      type: "linear",
                      position: "left",
                      gridLines: {
                        drawBorder: false,
                      },
                    },
                  ],
                },
              },
            }
          );
        }
      },
    },
  };
</script>
<style scoped>
  .rowData {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 20px;
  }
</style>
