const Locations = {
	//'localhost:8080':'http://localhost/pocket-box/api/',
	'localhost:8080':'https://dev-api.pocketbox.co/',
	'pocketbox.rkmarketing.net':'https://dev-api.pocketbox.co/',
	'dev-admin.pocketbox.co':'https://dev-api.pocketbox.co/',
	'admin.pocketbox.co':'https://api.pocketbox.co/',
}
const Endpoint = Locations[window.location.host] + 'admin/';
const GlobalEndpoint = Locations[window.location.host];
//const GlobalEndpoint = 'https://dev-api.pocketbox.co/';
// const Endpoint = 'https://api.pocketbox.co/admin/'
// const Endpoint = 'http://localhost:8081/admin/'
export default {
	Helper: {
		File: {
			Upload: GlobalEndpoint + 'admin/file/upload',
			Get: GlobalEndpoint + 'admin/file/get'
		},
		VehicleImage: GlobalEndpoint + 'vehicle/image/',
		MarketplaceImage: GlobalEndpoint + 'file/serve-listing-image/',
		Profile: Endpoint + 'profile/details/get'
	},
	QR : {
		Generate : GlobalEndpoint+'qr/generate'
	},
	User: {
		Login: Endpoint + 'user/login',
		Verify: Endpoint + 'user/verify',
		Get: Endpoint + 'users/get',
		Details: Endpoint + 'user/get',
		Update:Endpoint + 'user/update',
		UpdatePassword:Endpoint + 'user/update/password',
		Remove: Endpoint + 'user/remove',
		Reset2FA : Endpoint+'user/google/2fa/reset',
	},
	Data: {
		Dashboard: Endpoint + 'data/dashboard',
		DashboardFragment: Endpoint + 'data/dashboard/fragment',
		Users: Endpoint + 'data/users',
		UsersFragment: Endpoint + 'data/users/fragment',
		Vehicles: Endpoint + 'data/vehicles',
		VehiclesFragment: Endpoint + 'data/vehicles/fragment',
		Documents: Endpoint + 'data/documents',
		DocumentsFragment: Endpoint + 'data/documents/fragment',
		Charts: Endpoint + 'data/charts',
		ChartsFragment: Endpoint + 'data/charts/fragment'
	},
	Company: {
		List: Endpoint + 'companies/get',
		CompanyFragment: Endpoint + 'data/companies/fragment',
		Create: Endpoint + 'company/create',
		Get: Endpoint + 'company/get',
		Update: Endpoint + 'company/update',
		LogoSet: Endpoint + 'company/logo/set',
		BannerSet: Endpoint + 'company/banner/set',
		User : {
			List : Endpoint+'company/users/get',
			Create : Endpoint+'company/user/create',
			Get: Endpoint + 'company/user/get',
			Update: Endpoint + 'company/user/update',
			ProfileImageSet: Endpoint + 'company/user/profile-image/set',
			UpdatePassword: Endpoint + 'company/user/update/password',
			Remove: Endpoint + 'company/user/remove',
		},
		FleetUser : {
			List : Endpoint+'company/fleet/users/get',
			Create : Endpoint+'company/fleet/user/create',
			Get: Endpoint + 'company/fleet/user/get',
			Update: Endpoint + 'company/fleet/user/update',
			ProfileImageSet: Endpoint + 'company/fleet/user/profile-image/set',
			UpdatePassword: Endpoint + 'company/fleet/user/update/password',
			Remove: Endpoint + 'company/fleet/user/remove',
		},
		LeaderBoard :{
			List : Endpoint+'leaderboard/data',
		}
	},
	Marketplace: {
		List: Endpoint + 'marketplace/get/list',
		Get: Endpoint + 'marketplace/get',
		Create: Endpoint + 'marketplace/create',
		Update: Endpoint + 'marketplace/update',
		Remove: Endpoint + 'marketplace/remove',
		LogoSet: Endpoint + 'marketplace/logo/set'
	},
	Subscription: {
		List: Endpoint + 'subscriptions/get',
		Get: Endpoint + 'subscription/get',
		Create: Endpoint + 'subscription/create',
		Update: Endpoint + 'subscription/update',
		Remove: Endpoint + 'subscription/remove'
	},
	Category: {
		List: Endpoint + 'categories/get',
		Get: Endpoint + 'category/get',
		Create: Endpoint + 'category/create',
		Update: Endpoint + 'category/update',
		Remove: Endpoint + 'category/remove',
		setIcon: Endpoint + 'category/icon/set'
	},
	FleetSubscription: {
		List: Endpoint + 'fleet/subscriptions/get',
		Get: Endpoint + 'fleet/subscription/get',
		Create: Endpoint + 'fleet/subscription/create',
		Update: Endpoint + 'fleet/subscription/update',
		Remove: Endpoint + 'fleet/subscription/remove'
	},
	FleetModules: {
		List: Endpoint + 'fleet/modules/get',
		Create: Endpoint + 'fleet/module/create',
	},
	PlusModules: {
		List: Endpoint + 'plus/modules/get',
		Create: Endpoint + 'plus/module/create',
	},
	Vehicle: {
		Classes: Endpoint + 'vehicle/class/list',
	},
	Inspection: {
		Classes: Endpoint + 'inspection/class/list',
	},
	AccountRole: {
		List: Endpoint + 'fleet/account/roles/get',
		Get: Endpoint + 'fleet/account/role/get',
		Create: Endpoint + 'fleet/account/role/create',
		Update: Endpoint + 'fleet/account/role/update',
		Remove: Endpoint + 'fleet/account/role/remove',
		PermissionsList: Endpoint + 'fleet/account/role/permissions/get'
	},
	Modules:{
		List : Endpoint+'fleet/company/modules/list',
	},
	Branch : {
		List : Endpoint+'fleet/branches/list',
	},
	Roles : {
		List : Endpoint+'fleet/roles/list',
	},
}
