<template>
  <div class="page">
    <Navigation />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title mt-2 mb-4">Add Plus Company User</h1>
              <div class="panel-box p-5">
                <div class="row">
                  <div class="form-step-col">
                    <form
                      ref="CreateCompanyUserForm"
                      @submit.prevent="CreateCompanyUser"
                      autocomplete="off"
                    >
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >User Type<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <select
                              v-model="NewCompanyUser.company_role"
                              class="form-control"
                            >
                              <option value="1">Admin</option>
                              <option value="2">Sales Person</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-4 company-search">
                          <div class="mb-4">
                            <label class="form-label"
                              >Search Plus Company</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              v-model="search"
                              @blur="toggle = false"
                              @focus="toggle = true"
                              placeholder="Start Typing"
                            />
                            <div class="results" v-if="toggle">
                              <div
                                class="result"
                                v-for="(Company, index) in SearchCompanies"
                                :key="index"
                              >
                                <span
                                  v-on:click="selectResult(Company)"
                                  @mousedown.prevent
                                >
                                  {{ Company.name }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label">Selected Company</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="company"
                              placeholder="Company Name"
                            />
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >Name<span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter First Name"
                              v-model="NewCompanyUser.forename"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.NewCompanyUser.forename.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted &&
                                !$v.NewCompanyUser.forename.required
                              "
                              class="invalid-feedback"
                            >
                              First Name is required
                            </small>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >Last Name<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Last Name"
                              v-model="NewCompanyUser.surname"
                              :class="{
                                'is-invalid':
                                  submitted && $v.NewCompanyUser.surname.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted && !$v.NewCompanyUser.surname.required
                              "
                              class="invalid-feedback"
                            >
                              Last Name is required
                            </small>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >Email Address<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Email Address"
                              v-model="NewCompanyUser.email"
                              :class="{
                                'is-invalid':
                                  submitted && $v.NewCompanyUser.email.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted && !$v.NewCompanyUser.email.required
                              "
                              class="invalid-feedback"
                            >
                              Email Address is required
                            </small>
                            <small
                              v-if="submitted && !$v.NewCompanyUser.email.email"
                              class="invalid-feedback"
                            >
                              Valid Email Address is required
                            </small>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >Confirm Email Address<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Email Address"
                              v-model="NewCompanyUser.confirmemail"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.NewCompanyUser.confirmemail.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted &&
                                !$v.NewCompanyUser.confirmemail.required
                              "
                              class="invalid-feedback"
                            >
                              Confirm Email Address is required
                            </small>
                            <small
                              v-if="
                                submitted &&
                                !$v.NewCompanyUser.confirmemail.email
                              "
                              class="invalid-feedback"
                            >
                              Valid Email Address is required
                            </small>
                            <small
                              v-else-if="
                                submitted &&
                                !$v.NewCompanyUser.confirmemail.sameAsPassword
                              "
                              class="invalid-feedback"
                            >
                              Emails must match
                            </small>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >Password<span class="text-danger">*</span></label
                            >
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Enter Password"
                              v-model="NewCompanyUser.password"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.NewCompanyUser.password.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted &&
                                !$v.NewCompanyUser.password.required
                              "
                              class="invalid-feedback"
                            >
                              Password is required
                            </small>
                            <small v-if="submitted && !$v.NewCompanyUser.password.minLength" class="d-block invalid-feedback">Password must be at least 8 characters long.</small>
                            <small v-if="submitted && !$v.NewCompanyUser.password.strong" class="d-block invalid-feedback">Password must be strong (eg. include at least 1 capital letter, 1 number and 1 special character)</small>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label"
                              >Contact Number<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Contact Number"
                              v-model="NewCompanyUser.phone"
                              :class="{
                                'is-invalid':
                                  submitted && $v.NewCompanyUser.phone.$error,
                              }"
                            />
                            <small
                              v-if="
                                submitted && !$v.NewCompanyUser.phone.required
                              "
                              class="invalid-feedback"
                            >
                              Contact Number is required
                            </small>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="mb-4">
                            <label class="form-label">Profile Image</label>
                            <input
                              class="input form-control"
                              type="file"
                              name="profile_image"
                              ref="ProfileImageFile"
                              :class="{
                                'is-invalid':
                                  $v.NewCompanyUser.profile_image.$error,
                              }"
                              @change="imageFileChanged($event)"
                              accept="image/*"
                            />
                            <small
                              v-if="
                                submitted &&
                                !$v.NewCompanyUser.profile_image
                                  .file_size_validation
                              "
                              class="invalid-feedback"
                            >
                              Please upload less than 1 MB file size image
                            </small>
                            <small
                              v-if="
                                submitted &&
                                !$v.NewCompanyUser.profile_image
                                  .image_type_validation
                              "
                              class="invalid-feedback"
                            >
                              Please upload jpeg, png, gif or svg file only
                            </small>
                            <center>
                              <img
                                v-show="NewCompanyUser.profile_image"
                                ref="ProfileImagePreview"
                                class="icon__preview"
                                height="200"
                                width="200"
                              />
                            </center>
                          </div>
                        </div>
                      </div>
                      <ul
                        class="list-unstyled list-inline float-end buttons-col mt-4"
                      >
                        <li>
                          <router-link
                            :to="{ name: 'Companies' }"
                            class="btn btn-default"
                          >
                            &larr; Back</router-link
                          >
                        </li>
                        <li>
                          <button
                            type="button"
                            class="btn btn-info next-step"
                            v-on:click="CreateCompanyUser()"
                            :disabled="Saving"
                          >
                            Add User
                          </button>
                          <Loading v-if="Saving" />
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
  import Navigation from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";

  const file_size_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    return file.size < 1048576;
  };
  const image_type_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    if (
      file.type == "image/svg+xml" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif" ||
      file.type == "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };

  export default {
    //props: ["CompanyId"],
    components: {
      Navigation,
      Loading,
    },
    mounted() {
      this.GetCompanies();
    },
    watch: {
      search(val) {
        this.SearchCompanies = [];
        this.Companies.forEach((element) => {
          if (element.name.includes(val) && val != "") {
            this.SearchCompanies.push(element);
          }
        });
      },
    },
    data() {
      return {
        search: "",
        company: "",
        toggle: false,
        Saving: false,
        submitted: false,
        NewCompanyUser: {
          forename: "",
          surname: "",
          email: "",
          confirmemail: "",
          password: "",
          company_id: 0,
          phone: "",
          profile_image: "",
          company_role: "1",
        },
        CompanyId: 0,
        Companies: [],
        SearchCompanies: [],
      };
    },
    validations: {
      NewCompanyUser: {
        forename: { required },
        surname: { required },
        email: { required, email },
        confirmemail: { required, email, sameAsPassword: sameAs("email") },
        password: { required, minLength: minLength(8),
          strong: (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(value)
        },
        phone: { required },
        profile_image: { file_size_validation, image_type_validation },
      },
    },
    methods: {
      GetCompanies() {
        Axios.post(Endpoints.Company.List, {
          token: localStorage.getItem("token"),
          start: 0,
          limit: 100,
        }).then((Response) => {
          let Companies = Response.data.records;
          Companies.forEach((element) => {
            this.Companies.push({ id: element.id, name: element.name });
          });
        });
      },
      selectResult(company) {
        this.company = company.name;
        this.NewCompanyUser.company_id = company.id;
        this.CompanyId = company.id;
        this.search = "";
      },
      imageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.NewCompanyUser.profile_image = files[0];
        var output = document.querySelector(".icon__preview");
        output.src = URL.createObjectURL(files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
      },
      CreateCompanyUser() {
        this.submitted = true;
        this.$v.$touch();
        this.NewCompanyUser.company_id = this.CompanyId;
        if (this.$v.$invalid) {
          return;
        }
        delete this.NewCompanyUser.confirmemail;
        this.Saving = true;
        Axios.post(Endpoints.Company.User.Create, {
          token: localStorage.getItem("token"),
          ...this.NewCompanyUser,
        }).then((Response) => {
          if (Response.data.success) {
            this.SaveProfileImage(Response.data.last_id);
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      SaveProfileImage(id) {
        var LogoFormData = new FormData();
        if (this.$refs.ProfileImageFile.files[0]) {
          LogoFormData.append("storage_type", "CPU");
          LogoFormData.append("token", localStorage.getItem("token"));
          LogoFormData.append("file", this.$refs.ProfileImageFile.files[0]);
          LogoFormData.append("company_id", this.CompanyId);
          LogoFormData.append("user_id", id);
          Axios.post(Endpoints.Helper.File.Upload, LogoFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Company.User.ProfileImageSet, {
                  token: localStorage.getItem("token"),
                  profile_image: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  Swal.fire(
                    "Plus Company User Added Successfully",
                    "",
                    "success"
                  ).then(() => {
                    this.$router.push("/company/users/" + this.CompanyId);
                  });
                });
              }
            }
          );
        } else {
          Swal.fire("Plus Company User Added Successfully", "", "success").then(
            () => {
              this.$router.push("/company/users/" + this.CompanyId);
            }
          );
        }
      },
      clearCompanyUserModal() {
        this.$refs.CreateCompanyUserForm.reset();
        var self = this;
        Object.keys(this.CreateCompanyUser).forEach(function (subsKey, index) {
          self.CreateCompanyUser[subsKey] = "";
        });
        this.submitted = false;
      },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
  .modal-backdrop {
    background-color: #232e3c !important;
  }
  .company-search .results {
    position: absolute;
    width: 30%;
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .company-search .results .result {
    padding: 20px 0.75rem;
  }
  .company-search .results .result:hover {
    background: #efefef;
  }
</style>
