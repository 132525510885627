var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('Nav'),_c('div',{staticClass:"app-wrapper"},[_c('div',{staticClass:"app-content pt-3 p-md-3 p-lg-4"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row gy-4"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('h1',{staticClass:"main-title mt-2 mb-4"},[_vm._v("Update Plus Company User")]),_c('div',{staticClass:"panel-box p-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-step-col p-5"},[_c('form',{ref:"updateCompanyUserForm",on:{"submit":function($event){$event.preventDefault();return _vm.updateCompanyUser.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-4"},[_vm._m(0),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.EditCompanyUser.company_role),expression:"EditCompanyUser.company_role"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.EditCompanyUser, "company_role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Admin")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Sales Person")])])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-4"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.EditCompanyUser.forename),expression:"EditCompanyUser.forename"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted &&
                                _vm.$v.EditCompanyUser.forename.$error,
                            },attrs:{"type":"text","placeholder":"Enter First Name"},domProps:{"value":(_vm.EditCompanyUser.forename)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.EditCompanyUser, "forename", $event.target.value)}}}),(
                              _vm.submitted &&
                              !_vm.$v.EditCompanyUser.forename.required
                            )?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" First Name is required ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-4"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.EditCompanyUser.surname),expression:"EditCompanyUser.surname"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted &&
                                _vm.$v.EditCompanyUser.surname.$error,
                            },attrs:{"type":"text","placeholder":"Enter Last Name"},domProps:{"value":(_vm.EditCompanyUser.surname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.EditCompanyUser, "surname", $event.target.value)}}}),(
                              _vm.submitted &&
                              !_vm.$v.EditCompanyUser.surname.required
                            )?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" Last Name is required ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-4"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.EditCompanyUser.email),expression:"EditCompanyUser.email"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.EditCompanyUser.email.$error,
                            },attrs:{"type":"text","placeholder":"Enter Email Address"},domProps:{"value":(_vm.EditCompanyUser.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.EditCompanyUser, "email", $event.target.value)}}}),(
                              _vm.submitted && !_vm.$v.EditCompanyUser.email.required
                            )?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" Email Address is required ")]):_vm._e(),(
                              _vm.submitted && !_vm.$v.EditCompanyUser.email.email
                            )?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" Valid Email Address is required ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-4"},[_vm._m(4),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.EditCompanyUser.phone),expression:"EditCompanyUser.phone"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.EditCompanyUser.phone.$error,
                            },attrs:{"type":"text","placeholder":"Enter Contact Number"},domProps:{"value":(_vm.EditCompanyUser.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.EditCompanyUser, "phone", $event.target.value)}}}),(
                              _vm.submitted && !_vm.$v.EditCompanyUser.phone.required
                            )?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" Contact Number is required ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Profile Image")]),_c('input',{ref:"ProfileImageFile",staticClass:"input form-control",class:{
                              'is-invalid':
                                _vm.$v.EditCompanyUser.profile_image.$error,
                            },attrs:{"type":"file","name":"profile_image","accept":"image/*"},on:{"change":function($event){return _vm.imageFileChanged($event)}}}),(
                              _vm.submitted &&
                              !_vm.$v.EditCompanyUser.profile_image
                                .file_size_validation
                            )?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" Please upload less than 1 MB file size image ")]):_vm._e(),(
                              _vm.submitted &&
                              !_vm.$v.EditCompanyUser.profile_image
                                .image_type_validation
                            )?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" Please upload jpeg, png, gif or svg file only ")]):_vm._e()]),_c('center',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                              _vm.EditCompanyUser.profile_image &&
                              _vm.EditCompanyUser.profile_image != 1
                            ),expression:"\n                              EditCompanyUser.profile_image &&\n                              EditCompanyUser.profile_image != 1\n                            "}],staticClass:"profile-image mt-10"},[_c('img',{staticClass:"icon__preview",attrs:{"src":_vm.GetProfileImage(_vm.EditCompanyUser.id),"title":_vm.EditCompanyUser.forename,"alt":_vm.EditCompanyUser.forename,"height":"200","width":"200"},on:{"error":function($event){$event.target.src = _vm.GetProfileImage(
                                  _vm.EditCompanyUser.id
                                )}}})])])],1)]),_c('ul',{staticClass:"list-unstyled list-inline float-end buttons-col mt-4"},[_c('li',[_c('router-link',{staticClass:"btn btn-default",attrs:{"to":{
                            name: 'CompanyUsers',
                            params: { CompanyId: _vm.EditCompanyUser.company_id },
                          }}},[_vm._v(" ← Back")])],1),_c('li',[_c('button',{staticClass:"btn btn-info next-step",attrs:{"type":"button","disabled":_vm.Saving},on:{"click":function($event){return _vm.updateCompanyUser()}}},[_vm._v(" Update User ")]),(_vm.Saving)?_c('Loading'):_vm._e()],1)])])])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label"},[_vm._v("User Type"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label"},[_vm._v("First Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label"},[_vm._v("Last Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label"},[_vm._v("Email Address"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label"},[_vm._v("Contact Number"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])}]

export { render, staticRenderFns }