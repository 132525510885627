<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
	<div class="app-content pt-3 p-md-3 p-lg-4">
		<div class="container-fluid">
			<div class="row gy-4">
				<div class="col-sm-4">
					 <h1 class="main-title mt-5">New Promotions</h1>
					<div class="promotion-box">
            <addMarketplace
                @recordAdded="refreshList"
                :Categories="Categories"
                :Companies="Companies"
                :key="AddComponentKey"
              ></addMarketplace>
					</div>
				</div>
				<div class="col-sm-4">
					<h1 class="main-title mt-5">Current Promotions</h1>
					<div class="table-box">
            	<div class="p-4">
             <form class="form-inline searchForm">
                      <div class="mb-4">
                        <input
                          type="text"
                          v-model="search_title"
                          class="form-control"
                          placeholder="Title"
                        />
                      </div>
                      <div class="mb-4">
                        <select v-model="search_category" class="form-select">
                          <option value="">Select Category</option>
                          <option
                            v-for="Category in Categories"
                            v-bind:value="Category.id"
                            v-bind:key="Category.id"
                          >
                            {{ Category.category_name }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="mb-4">
                        <select v-model="search_company" class="form-select">
                          <option value="">Select Company</option>
                          <option
                            v-for="Company in Companies"
                            v-bind:value="Company.id"
                            v-bind:key="Company.id"
                          >
                            {{ Company.name }}
                          </option>
                        </select>
                      </div>
                      <div class="text-right">
                        <div class="searchLoading" v-if="showSearchLoader">
                          <p>
                            <Loading />
                          </p>
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary mb-2"
                           v-on:click="searchCurrentData"
                        >
                          Search
                        </button>
                      </div>
                    </form>
					
						</div>
            <div v-if="showLoader">
                      <p>
                        <Loading />
                      </p>
            </div>
            <div class="promotion-detail" v-for="(Marketplace, index) in Marketplaces.data" :key="index">
              <div class="promotion-info p-4">
                <div  :class="[
                          'pro-image-info',
                          Marketplace.hot_deal == '1' ? 'hot-deal-col' : '',
                          Marketplace.is_featured === 1 ? 'hot-deal-col' : '',
                        ]">
                  <div class="card-col">
                    <div class="pro-images"> <img
                            :src="GetMarketplaceImage(Marketplace.id)"
                            width="70"
                            class="round"
                            :title="Marketplace.title"
                            :alt="Marketplace.title"
                            @error="
                              $event.target.src = GetMarketplaceImage(
                                Marketplace.id
                              )
                            "
                          /> </div>
                    <div class="pro-image-info-tag">
                      <h5>{{
                            Marketplace.title
                          }}</h5>
                      <p>{{ Marketplace.description }}</p> <a :href="Marketplace.url" target="_blank">{{
                              Marketplace.url
                            }}</a></div>
                  </div>
                  <div class="hot-deal-icon" v-if="Marketplace.hot_deal"> <img src="assets/images/hot-deal.png"> </div>
                  <div class="featued-deal-icon" v-if="Marketplace.is_featured"> <img src="assets/images/featured.png"> </div>
                </div>
                <div class="deal-col text-center">
                  <div class="deal-buttons mb-3">
                   <router-link
                            :to="{
                              name: 'MarketplaceUpdate',
                              params: { PromotionId: Marketplace.id },
                            }"
                            class="btn"
                            title="Edit Record"
                            >
                            <img src="assets/images/icons/feather-edit.svg">
                            </router-link>
                          <button
                            class="btn"
                             v-on:click="MarketplaceDelete(Marketplace.id)"
                            title="Delete Record"
                          >
                            <img src="assets/images/icons/material-delete.svg">
                          </button>
                  </div>
                  <p>Expiry: <b>{{ Marketplace.end_date | date }}</b></p>
                </div>
              </div>
            </div>

					</div>
				</div>
				<div class="col-sm-4">
					<h1 class="main-title mt-5">Expired Promotions</h1>
					<div class="table-box">
						<div class="p-4">
							 <form class="form-inline searchExpiredForm">
                      <div
                        class="mb-4"
                      >
                        <input
                          type="text"
                          v-model="search_exp_title"
                          class="form-control"
                          placeholder="Title"
                        />
                      </div>
                      <div
                       class="mb-4"
                      >
                        <select
                          v-model="search_exp_category"
                          class="form-select"
                        >
                          <option value="">Select Category</option>
                          <option
                            v-for="Category in Categories"
                            v-bind:key="Category.id"
                            v-bind:value="Category.id"
                          >
                            {{ Category.category_name }}
                          </option>
                        </select>
                      </div>
                      <div
                        class="mb-4"
                      >
                        <select
                          v-model="search_exp_company"
                          class="form-select"
                        >
                          <option value="">Select Company</option>
                          <option
                            v-for="Company in Companies"
                            v-bind:key="Company.id"
                            v-bind:value="Company.id"
                          >
                            {{ Company.name }}
                          </option>
                        </select>
                      </div>
                      <div class="text-right">
                        <div
                          class="searchLoading"
                          v-if="showExpiredSearchLoader"
                        >
                          <p>
                            <Loading />
                          </p>
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary mb-2"
                           v-on:click="searchExpiredData"
                        >
                          Search
                        </button>
                      </div>
                    </form>
						</div>
            <div class="promotion-detail" v-for="(Marketplace, index) in ExpiredMarketplaces.data" :key="index">
              <div class="promotion-info p-4">
                <div  :class="[
                          'pro-image-info',
                          Marketplace.hot_deal == '1' ? 'hot-deal-col' : '',
                          Marketplace.is_featured === 1 ? 'hot-deal-col' : '',
                        ]">
                  <div class="card-col">
                    <div class="pro-images"> <img
                            :src="GetMarketplaceImage(Marketplace.id)"
                            width="70"
                            class="round"
                            :title="Marketplace.title"
                            :alt="Marketplace.title"
                            @error="
                              $event.target.src = GetMarketplaceImage(
                                Marketplace.id
                              )
                            "
                          /> </div>
                    <div class="pro-image-info-tag">
                      <h5>{{
                            Marketplace.title
                          }}</h5>
                      <p>{{ Marketplace.description }}</p> <a :href="Marketplace.url" target="_blank">{{
                              Marketplace.url
                            }}</a></div>
                  </div>
                  <div class="hot-deal-icon" v-if="Marketplace.hot_deal"> <img src="assets/images/hot-deal.png"> </div>
                  <div class="featued-deal-icon" v-if="Marketplace.is_featured"> <img src="assets/images/featured.png"> </div>
                </div>
                <div class="deal-col text-center">
                  <div class="deal-buttons mb-3">
                   <router-link
                            :to="{
                              name: 'MarketplaceUpdate',
                              params: { PromotionId: Marketplace.id },
                            }"
                            class="btn"
                            title="Edit Record"
                            >
                            <img src="assets/images/icons/feather-edit.svg">
                            </router-link>
                          <button
                            class="btn"
                             v-on:click="MarketplaceDelete(Marketplace.id)"
                            title="Delete Record"
                          >
                            <img src="assets/images/icons/material-delete.svg">
                          </button>
                  </div>
                  <p>Expiry: <b>{{ Marketplace.end_date | date }}</b></p>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
  </div>
</template>
<script>
  import pagination from "laravel-vue-pagination";
  import "jquery/dist/jquery.min.js";
  import $ from "jquery";

  import addMarketplace from "@/views/Marketplace/Create";
  import Nav from "@/components/Navigation";
  import Axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  export default {
    components: {
      Nav,
      addMarketplace,
      Loading,
      pagination,
    },
    filters: {
      date: function (data) {
        var reqdString = "";
        for (let i = 0; i < 10; i++) {
          reqdString += data[i];
        }
        var arr = reqdString.split("-");
        reqdString = arr[2] + "-" + arr[1] + "-" + arr[0];
        return reqdString;
      },
    },
    mounted() {
      document.title = "PocketBox Admin - Marketplace Promotions";
      this.GetCompanyList();
      this.GetCategoriesList();
      this.GetMarketplaceList(1, 1000);
    },
    data() {
      return {
        Companies: false,
        Marketplaces: {},
        ExpiredMarketplaces: {},
        showLoader: false,
        showExpiredSearchLoader: false,
        showSearchLoader: false,
        Categories: {},
        search_category: "",
        search_company: "",
        search_exp_category: "",
        search_exp_company: "",
        search_title: "",
        search_exp_title: "",
        AddComponentKey: 0,
      };
    },
    methods: {
      forceRerender() {
        this.AddComponentKey += 1;
      },
      GetCompanyList() {
        Axios.post(Endpoints.Company.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Companies = Response.data.records;
          this.showLoader = false;
        });
      },
      GetCategoriesList() {
        Axios.post(Endpoints.Category.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Categories = Response.data.records;
          this.showLoader = false;
        });
      },
      GetMarketplaceList(Start, Limit) {
        this.showLoader = true;
        Axios.post(Endpoints.Marketplace.List, {
          token: localStorage.getItem("token"),
          start: Start,
          limit: Limit,
        }).then((Response) => {
          this.Marketplaces = Response.data.records;
          this.showLoader = false;
        });

        this.showExpiredSearchLoader = true;
        setTimeout(() => {
          Axios.post(Endpoints.Marketplace.List, {
            token: localStorage.getItem("token"),
            type: "Expired",
          }).then((Response) => {
            this.ExpiredMarketplaces = Response.data.records;
            this.showExpiredSearchLoader = false;
          });
        }, 1000);
      },
      MarketplaceDelete(id) {
        Swal.fire({
          title: "Are you sure to delete this promotion?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Deleting...",
              text: "Please wait...",
              imageUrl: require("../../assets/images/LoadingTwo.gif"),
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            Axios.post(Endpoints.Marketplace.Remove, {
              token: localStorage.getItem("token"),
              id: id,
            }).then((Response) => {
              if (Response.data.success == true) {
                this.GetMarketplaceList(1, 1000);
                Swal.fire(
                  "Marketplace Promotion Deleted Successfully",
                  "",
                  "success"
                ).then(() => {});
              } else {
                Swal.fire({
                  title: "Oops!",
                  text: Response.data.message,
                  icon: "error",
                  confirmButtonText: "Close",
                });
              }
            });
          }
        });
      },
      MarketplaceEdit(id) {
        this.$router.push("/marketplace-edit/" + id);
      },
      refreshList() {
        this.GetMarketplaceList(1, 1000);
        this.forceRerender();
      },
      searchCurrentData(page = 1) {
        this.showSearchLoader = true;
        Axios.post(Endpoints.Marketplace.List + "?page=" + page, {
          token: localStorage.getItem("token"),
          search_category: this.search_category,
          search_company: this.search_company,
          search_title: this.search_title,
          type: "Current",
        }).then((Response) => {
          this.Marketplaces = Response.data.records;
          this.showSearchLoader = false;
        });
      },
      searchExpiredData(page = 1) {
        this.showExpiredSearchLoader = true;
        Axios.post(Endpoints.Marketplace.List + "?page=" + page, {
          token: localStorage.getItem("token"),
          search_category: this.search_exp_category,
          search_company: this.search_exp_company,
          search_title: this.search_exp_title,
          type: "Expired",
        }).then((Response) => {
          this.ExpiredMarketplaces = Response.data.records;
          this.showExpiredSearchLoader = false;
        });
      },
      GetMarketplaceImage(Id) {
        return (
          Endpoints.Helper.MarketplaceImage +
          Id +
          "/" +
          localStorage.getItem("token") +
          "/promotion"
        );
      },
    },
  };
</script>
<style>
  .page-item.active .page-link {
    background-color: #963d8f;
    border-color: #963d8f;
  }
  .hot-deals-image,
  .featured-image {
    display: none;
  }
  .page-body {
    margin-bottom: 30px;
  }
  .list-item {
    vertical-align: middle;
  }
  .list-item:nth-child(odd) {
    background-color: FFF;
  }
  .imageBox {
    border: 1px solid #ddd;
    height: 65px;
    margin-right: 10px;
    padding: 2px;
    position: relative;
    background: #fff;
  }
  .list-item:nth-child(even) {
    background-color: #eee;
    padding: 10px;
  }
  .list-item .list-details p {
    margin-bottom: 0px;
    font-size: 13px;
    line-height: normal;
  }
  .list-item .list-details a {
    font-weight: bold;
    color: #f00;
  }
  .list-item .list-details p a {
    font-weight: normal;
    font-size: 13px;
  }
  .current-details .list-details a {
    color: #b335a9;
  }
  .hotDeal,
  .isFeaturedBox {
    border: 1px solid #b335a9;
    display: block;
    padding: 5px;
    border-radius: 8px;
    box-shadow: inset 0 -1em 2em rgb(50 50 50 / 10%),
      0 0 9px 2px rgb(191 190 190), 0.3em 0.3em 2px rgb(181 178 180 / 30%);
  }
  .descrBox {
    display: inline-flex;
    margin-right: 10px;
    position: relative;
    width: 72%;
    padding: 12px;
  }
  .hotDeal .hot-deals-image {
    display: block;
    position: absolute;
    right: 2px;
    width: 77px;
    height: auto;
    top: 2px;
  }
  hr {
    margin: 10px 0 30px;
  }
  .searchForm .fa-3x,
  .searchExpiredForm .fa-3x {
    font-size: 25px;
    margin-left: 8px;
  }
  .searchLoading {
    display: inline-block;
    margin-right: 10px;
  }
  .imageBox .listingIMG {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .list-item .btn {
    padding: 4px 10px;
  }
  .text-right {
    text-align: right;
  }
  .text-uppercase::-webkit-input-placeholder,
  .text-uppercase::placeholder {
    text-transform: capitalize !important;
  }
  .textDanger {
    display: inline-block;
    padding: 2px 10px;
    background: #5bc0de;
    color: #fff;
    font-size: 11px;
    text-align: center;
    border-radius: 4px;
    margin-top: 10px;
    text-transform: uppercase;
    line-height: 20px;
    vertical-align: bottom;
  }
  .isFeaturedBox {
    display: block;
  }
  .isFeaturedBox .imageBox {
    border: none;
    height: auto;
    margin-right: 10px;
    padding: 2px;
    position: relative;
    background: none;
    display: block;
  }
  .isFeaturedBox .imageBox .listingIMG {
    position: relative;
  }
  .isFeaturedBox .list-details,
  .isFeaturedBox .imageBox {
    width: 100% !important;
    text-align: center;
  }
  .isFeaturedBox .featured-image {
    display: block;
    position: absolute;
    left: 0px;
    width: 77px;
    height: auto;
    top: 0px;
  }
  .new-buttons {
    text-align: right;
    margin-top: 20px;
  }
  .new-buttons a {
    margin-left: 20px;
  }
  .expiry_section {
    margin-top: 10px;
  }
  .expiry_section b {
    font-size: 12px;
  }
</style>
