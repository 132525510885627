<template>
  <div class="row rowData">
    <div class="col-sm-3 col-md-3 col-lg-3">
      <div class="total-block d-flex">
        <img src="/assets/images/icons/email.svg" />
        <div class="total-noti">
          <p class="total-notification-col">Total Notifications Sent Today</p>
          <p class="total-not-no">{{ SentNotificationToday }}</p>
          <div v-if="Loader" class="loading-class">
            <Loading />
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3">
      <div class="total-block d-flex">
        <img src="/assets/images/icons/documents.svg" />
        <div class="total-noti">
          <p class="total-notification-col">Total Documents</p>
          <p class="total-not-no">{{ TotalDocuments }}</p>
          <div v-if="TotalDocumentsLoader" class="loading-class">
            <Loading />
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3">
      <div class="total-block d-flex">
        <img src="/assets/images/icons/cars.svg" />
        <div class="total-noti">
          <p class="total-notification-col">Total Vehicles</p>
          <p class="total-not-no">{{ TotalVehicles }}</p>
          <div v-if="TotalVehiclesLoader" class="loading-class">
            <Loading />
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3">
      <div class="total-block d-flex">
        <img src="/assets/images/icons/company.svg" />
        <div class="total-noti">
          <p class="total-notification-col">Average Vehicles Per Account</p>
          <p class="total-not-no">
            {{ AverageVehiclesPerAccount }}
          </p>
          <div v-if="AverageVehiclesPerAccountLoader" class="loading-class">
            <Loading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        SentNotificationToday: "",
        TotalDocuments: "",
        TotalVehicles: "",
        AverageVehiclesPerAccount: "",
        Loader: false,
        TotalDocumentsLoader: false,
        TotalVehiclesLoader: false,
        AverageVehiclesPerAccountLoader: false,
      };
    },
    mounted() {
      this.Loader = true;
      // data fragment
      var actions = [
        "SentNotificationToday",
        "TotalDocuments",
        "TotalVehicles",
        "AverageVehiclePerAccount",
      ];
      var setTime = 2000;
      this.Loader = true;
      this.TotalVehiclesLoader = true;
      this.TotalDocumentsLoader = true;
      this.AverageVehiclesPerAccountLoader = true;
      actions.forEach((action) => {
        setTimeout(() => {
          this.GetVehiclesAnalytics(action);
        }, setTime);
        setTime = parseInt(setTime) + 500;
      });
    },
    methods: {
      GetVehiclesAnalytics(type) {
        axios
          .post(Endpoints.Data.UsersFragment, {
            token: localStorage.getItem("token"),
            data: type,
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              if (type == "SentNotificationToday") {
                this.SentNotificationToday =
                  RequestResponse.data.data.SentNotificationToday;
                this.Loader = false;
              } else if (type == "TotalDocuments") {
                this.TotalDocuments = RequestResponse.data.data.Documents.Total;
                this.TotalDocumentsLoader = false;
              } else if (type == "TotalVehicles") {
                this.TotalVehicles = RequestResponse.data.data.Vehicles.Total;
                this.TotalVehiclesLoader = false;
              } else if (type == "AverageVehiclePerAccount") {
                this.AverageVehiclesPerAccount =
                  RequestResponse.data.data.AverageVehiclesPerAccount;
                this.AverageVehiclesPerAccountLoader = false;
              }
            }
          });
      },
    },
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .rowData {
    width: 100%;
    padding: 0;
    margin: 0;
  }
</style>
