<template>
  <div class="page">
    <Nav />
     <div class="app-wrapper">
	<div class="app-content pt-3 p-md-3 p-lg-4">
		<div class="container-fluid">
      <div class="table-box">
						<div class="row">
							<div class="col-sm-12 col-md-12 col-lg-12 p-4">
								<h4>Categories</h4>
								<p>Pocket Box Categories information</p>
							</div>
						</div>
						<div class="row">
							<div class="col-12 px-4 mt-3 mb-3">
								<div class="table-responsive table-color">
								 <table
                      class="table table-vcenter card-table"
                      id="categories_list"
                    >
                      <thead>
                        <tr>
                          <th width="10%">S.No.</th>
                          <th width="15%">Icon</th>
                          <th width="60%">Title</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="showLoader">
                        <tr>
                          <td>
                            <Loading />
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr
                          v-for="(Category, index) in Categories"
                          :key="index"
                        >
                          <td class="w-1">{{ ++index }}</td>
                          <td>
                            <div class="category-icon">
                              <img
                                :src="GetCategoryIcon(Category.id)"
                                class="listingIMG"
                                :title="Category.category_name"
                                :alt="Category.category_name"
                                @error="
                                  $event.target.src = GetCategoryIcon(
                                    Category.id
                                  )
                                "
                              />
                            </div>
                          </td>
                          <td>{{ Category.category_name }}</td>
                          <td>
                             <router-link
                              :to="{
                                name: 'UpdateCategory',
                                params: { CategoryId:Category.id },
                              }"
                              class="btn btn-primary"
                              title="Edit User"
                              >Edit</router-link>
                            <button
                              class="btn btn-primary"
                               v-on:click="CategoryDelete(Category.id)"
                              style="margin-left: 10px"
                              title="Delete Record"
                            >Delete</button>
                          </td>
                        </tr>
                      </tbody>
                </table>
					       </div>
              </div>
			      </div>
		      </div>
    </div>
    </div>
     </div>
     </div>
 
</template>
<script>
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "jquery/dist/jquery.min.js";
  import $ from "jquery";

  import addCategory from "@/views/Categories/Create";
  import editCategory from "@/views/Categories/Update";
  import Nav from "@/components/Navigation";
  import Axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";

  export default {
    components: {
      Nav,
      addCategory,
      editCategory,
      Loading,
    },
    mounted() {
      document.title = "PocketBox Admin - Categories";
      this.GetCategoriesList(1, 1000);
    },
    data() {
      return {
        Categories: {},
        editRec: {},
        showLoader: false,
        search_keyword: "",
        ComponentKey: 0,
      };
    },
    methods: {
      forceRerender() {
        this.ComponentKey += 1;
      },
      GetCategoriesList(Start, Limit) {
        this.showLoader = true;
        Axios.post(Endpoints.Category.List, {
          token: localStorage.getItem("token"),
          start: Start,
          limit: Limit,
        }).then((Response) => {
          this.Categories = Response.data.records;
          this.showLoader = false;
          setTimeout(() => {
            $("#categories_list").DataTable();
          }, 1000);
        });
      },
      CategoryDelete(id) {
        Swal.fire({
          title: "Are you sure to delete this category?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Deleting...",
              text: "Please wait...",
              imageUrl: require("../../assets/images/LoadingTwo.gif"),
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            Axios.post(Endpoints.Category.Remove, {
              token: localStorage.getItem("token"),
              id: id,
            }).then((Response) => {
              if (Response.data.success == true) {
                this.GetCategoriesList(1, 1000);
                Swal.fire("Category Deleted Successfully", "", "success").then(
                  () => {}
                );
              } else {
                Swal.fire({
                  title: "Oops!",
                  html: Response.data.message,
                  icon: "error",
                  confirmButtonText: "Close",
                });
              }
            });
          }
        });
      },
      getRecord(id) {
        Axios.post(Endpoints.Category.Get, {
          token: localStorage.getItem("token"),
          id: id,
        }).then((Response) => {
          this.editRec = Response.data.record;
          this.$refs.editCategory.GetCategoryLogo(this.editRec.icon);
        });
      },
      refreshList() {
        this.$router.go();
        this.GetCategoriesList(1, 1000);
        this.forceRerender();
      },
      GetCategoryIcon(Id) {
        return (
          Endpoints.Helper.MarketplaceImage +
          Id +
          "/" +
          localStorage.getItem("token") +
          "/category"
        );
      },
    },
  };
</script>
<style>
  .category-icon img {
    max-width: 50px;
    max-height: 50px;
  }
  .category-icon {
    text-align: center;
    width: 60px;
    padding: 5px;
    background: #ccc;
  }
</style>
