<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title mt-2 mb-4">Edit Plus Subscription</h1>
              <div class="panel-box">
                <div class="row">
                  <div class="form-step-col p-5">
                    <form
                      ref="EditSubscriptionForm"
                      @submit.prevent="EditSubscription"
                    >
                      <div class="row">
                        <!-- Company Name -->
                        <div class="col-sm-12 col-lg-12">
                          <div class="mb-3">
                            <label class="form-label">
                              Title<span class="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Title"
                              v-model="Update_Subscription.title"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.Update_Subscription.title.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.Update_Subscription.title.required
                              "
                              class="invalid-feedback"
                            >
                              Title is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label"
                              >Price<span class="text-danger">*</span></label
                            >
                            <input
                              type="number"
                              maxlength="5"
                              class="form-control"
                              placeholder="Enter Price"
                              v-model="Update_Subscription.price"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.Update_Subscription.price.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.Update_Subscription.price.required
                              "
                              class="invalid-feedback"
                            >
                              Price is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label"
                              >Allowed Ads<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="number"
                              maxlength="5"
                              class="form-control"
                              placeholder="Enter Allowed Ads"
                              v-model="Update_Subscription.allowed_ads"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.Update_Subscription.allowed_ads.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.Update_Subscription.allowed_ads.required
                              "
                              class="invalid-feedback"
                            >
                              Allowed Ads is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label"
                              >Max User Import Allow<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="number"
                              maxlength="5"
                              class="form-control"
                              placeholder="Enter Max User Import Allow"
                              v-model="Update_Subscription.allow_max_user_import"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.Update_Subscription.allow_max_user_import.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.Update_Subscription.allow_max_user_import.required
                              "
                              class="invalid-feedback"
                            >
                            Max user import allow is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">
                              Max Hot Deal Allow
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="number"
                              maxlength="5"
                              class="form-control"
                              placeholder="Enter Max Hot Deal Allow"
                              v-model="Update_Subscription.max_hot_deal_allow"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.Update_Subscription.max_hot_deal_allow
                                    .$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.Update_Subscription.max_hot_deal_allow
                                  .required
                              "
                              class="invalid-feedback"
                            >
                              Max Hot Deal Allow is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">Feature Ad</label>
                            <label
                              class="form-check form-check-single form-switch radio-label"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="Update_Subscription.is_feature_ad"
                              />
                            </label>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">
                              Show only ads from company default category
                            </label>
                            <label
                              class="form-check form-check-single form-switch radio-label"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="Update_Subscription.is_default_ad"
                              />
                            </label>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Modules</label>
                          <hr />
                        </div>
                        <div class="row module-section">
                          <div
                            class="col-sm-12 col-lg-4"
                            v-for="(PlusModule, index) in PlusModules"
                            :key="'Social' + index"
                          >
                            <div class="mb-3">
                              <label class="form-label switch-label">{{
                                PlusModule.title
                              }}</label>
                              <label
                                class="form-check form-check-single form-switch"
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  v-model="
                                    Update_Subscription.plus_modules[
                                      PlusModule.title
                                    ]
                                  "
                                  @change="
                                    $emit('input', $event.target.checked)
                                  "
                                />
                              </label>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-lg-12"
                            @click="isModuleShow = !isModuleShow"
                            v-if="isModuleShow == false"
                          >
                            <button class="btn smallBtn btn-info">
                              New Module?
                            </button>
                          </div>
                          <div
                            class="col-sm-12 col-lg-12"
                            v-if="isModuleShow == true"
                          >
                            <div class="form-inline add-new-modules">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Module Title"
                                  v-model="New_Module.title"
                                />
                              </div>
                              <button
                                type="button"
                                class="btn btn-primary mb-2"
                                @click="CreateModule()"
                              >
                                Save
                              </button>
                              <Loading
                                v-if="ModuleSaving"
                                class="module-saving"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-right">
                        <router-link
                          :to="{ name: 'Subscriptions' }"
                          class="btn btn-default btn-xs"
                        >
                          &larr; Back</router-link
                        >
                        <button
                          class="btn btn-primary"
                          v-on:click="EditSubscription()"
                          :disabled="Saving"
                        >
                          Save
                        </button>
                        <Loading v-if="Saving" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  const isAfterDate = (value, vm) => {
    return new Date(value).getTime() > new Date(vm.start_date).getTime();
  };
  export default {
    //props: ["Update_Subscription"],
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        isModuleShow: false,
        Saving: false,
        ModuleSaving: false,
        submitted: false,
        PlusModules: {},
        New_Module: {
          title: "",
        },
        Update_Subscription: {
          title: "",
          price: "",
          allowed_ads: "",
          allow_max_user_import: "",
          max_hot_deal_allow: "",
          plus_modules: {},
        },
      };
    },
    validations: {
      Update_Subscription: {
        title: { required },
        price: { required },
        allowed_ads: { required },
        allow_max_user_import: { required },
        max_hot_deal_allow: { required },
      },
    },
    mounted() {
      this.SubscriptionId = this.$route.params.SubscriptionId;
      this.GetPlusModulesList();
      setTimeout(() => {
        this.getSubscription();
      }, 500);
    },
    methods: {
      GetPlusModulesList() {
        var self = this;
        Axios.post(Endpoints.PlusModules.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          Response.data.records.forEach((moduleName) => {
            self.Update_Subscription.plus_modules[moduleName.title] = "";
          });
          this.PlusModules = Response.data.records;
        });
      },
      getSubscription() {
        this.Saving = true;
        Axios.post(Endpoints.Subscription.Get, {
          token: localStorage.getItem("token"),
          id: this.SubscriptionId,
        }).then((Response) => {
          if (typeof Response.data.record != "undefined") {
            this.Update_Subscription = Response.data.record;
            this.Update_Subscription.plus_modules = JSON.parse(
              Response.data.record.plus_modules
            );
          }

          this.Saving = false;
        });
      },
      EditSubscription() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Subscription.Update, {
          token: localStorage.getItem("token"),
          ...this.Update_Subscription,
        }).then((Response) => {
          if (Response.data.success) {
            this.Saving = false;
            Swal.fire("Subscription Updated Successfully", "", "success").then(
              () => {
                this.$router.push("/subscriptions");
                this.$emit("recordUpdated");
              }
            );
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      CreateModule() {
        var self = this;
        this.ModuleSaving = true;
        Axios.post(Endpoints.PlusModules.Create, {
          token: localStorage.getItem("token"),
          ...this.New_Module,
        }).then((Response) => {
          this.ModuleSaving = false;
          if (Response.data.success) {
            if (Response.data.record != "") {
              self.Update_Subscription.plus_modules[this.New_Module.title] = "";
              self.PlusModules.push(Response.data.record);
            }
            this.isModuleShow = false;
            this.New_Module.title = "";
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      clearModal() {
        this.$refs.EditSubscriptionForm.reset();
        var self = this;
        Object.keys(this.Update_Subscription).forEach(function (key, index) {
          self.Update_Subscription[key] = "";
        });
        this.submitted = false;
      },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
</style>
