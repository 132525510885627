<template>
  <div class="page">
    <Navigation />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-3 col-md-3 col-lg-3 flex">
              <div class="user-block blue-block p-3">
                <div v-if="totalDocLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="new-users total-users">
                  <p class="user-month">Total DOCS</p>
                  <p class="arrow-text">
                    <img src="assets/images/dashboard/logo-icon.svg" />
                    {{ TotalDocs }}
                  </p>
                  <p class="per-text">
                    {{ TotalDocPercent }}% Up in the last 24 Hours
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart.svg" />
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 flex">
              <div class="user-block green-block p-3">
                <div v-if="NewDocLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="new-users">
                  <p class="user-month">NEW DOCUMENTS THIS MONTH</p>
                  <p class="arrow-text">
                    <img src="assets/images/icons/arrow_upward.svg" />
                    {{ NewDocThisMonth }}
                  </p>
                  <p class="per-text">
                    {{ NewDocThisMonthPercent }}% Up in the last 30 days
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart.svg" />
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 flex">
              <div class="user-block orange-block p-3">
                <div v-if="DocExpLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="new-users">
                  <p class="user-month">DOCUMENTS EXPIRING WITHIN 30 DAYS</p>
                  <p class="arrow-text">{{ DocumentExpiring }}</p>
                  <p class="per-text">
                    {{ DocumentExpiringPercent }}% Of Document
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart1.svg" />
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 flex">
              <div class="user-block red-block p-3">
                <div v-if="DocNoExpLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="new-users">
                  <p class="user-month">DOCUMENTS WITH NO EXPIRY</p>
                  <p class="arrow-text">
                    <img src="assets/images/icons/user-clock.svg" />
                    {{ DocumentNoExpiring }}
                  </p>
                  <p class="per-text">
                    {{ DocumentNoExpiringPercent }}% Of Vehicles
                  </p>
                </div>
                <div class="bar-chart-col">
                  <img src="assets/images/dashboard/bar-chart.svg" />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-box">
                <div class="p-3">
                  <div class="col">
                    <h4>Documents By Type</h4>
                    <p>Documents submitted to Pocket Box by type</p>
                  </div>
                </div>
                <div v-if="DocByTypeLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="three-block-gen-bre p-4 bar-pd">
                  <div class="row">
                    <div
                      :class="[
                        'col ',
                        DocumentByType.type == 'insurance'
                          ? 'bg-blue-col'
                          : DocumentByType.type == 'warranty'
                          ? 'bg-orange-col'
                          : DocumentByType.type == 'service'
                          ? 'bg-yellow-col'
                          : DocumentByType.type == 'passport'
                          ? 'bg-voilet-col'
                          : DocumentByType.type == 'finance'
                          ? 'bg-pink-col'
                          : DocumentByType.type == 'other'
                          ? 'bg-green-col'
                          : DocumentByType.type == 'driving_license'
                          ? 'bg-blue-col'
                          : 'bg-blue-col',
                      ]"
                      v-for="DocumentByType in DocumentsByType"
                      :key="DocumentByType.index"
                    >
                      <img
                        :src="
                          'assets/images/document/' +
                          (DocumentByType.type == 'insurance'
                            ? 'insurance.svg'
                            : DocumentByType.type == 'warranty'
                            ? 'warranty.svg'
                            : DocumentByType.type == 'service'
                            ? 'servicing.svg'
                            : DocumentByType.type == 'passport'
                            ? 'passport.svg'
                            : DocumentByType.type == 'finance'
                            ? 'finance.svg'
                            : DocumentByType.type == 'driving_license'
                            ? 'driving.svg'
                            : DocumentByType.type == 'other'
                            ? 'other.svg'
                            : 'driving.svg')
                        "
                      />
                      <p class="mt-4">{{ DocumentByType.key }}</p>
                      <p>
                        <b>{{ DocumentByType.total_percent }}%</b>
                      </p>
                      <p class="mark-num">{{ DocumentByType.total }}</p>
                    </div>
                  </div>
                </div>
                <div
                  class="chart-container p-2"
                  style="height: 30.5vh; width: 100%"
                >
                  <canvas
                    ref="chart_DocumentByType"
                    style="height: 430px; width: 100%"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="table-box table-full-height">
                <div class="p-3">
                  <div class="col">
                    <h4>Document Expiring</h4>
                  </div>
                </div>
                <div v-if="DocExpiringLoader" class="loading-class">
                  <Loading />
                </div>
                <div class="p-3">
                  <div
                    class="line_cont"
                    v-for="(
                      DocumentUpcomingExpire, index
                    ) of DocumentUpcomingExpires"
                    :key="index"
                  >
                    <md-progress-bar
                      :class="'doc_color_' + index"
                      md-mode="determinate"
                      :md-value="DocumentUpcomingExpire.percentage"
                    >
                    </md-progress-bar>
                    <div class="lint_text" style="width: 100%">
                      <span>
                        {{ DocumentUpcomingExpire.title }} ({{
                          DocumentUpcomingExpire.expiring_count
                        }}/{{ DocumentUpcomingExpire.total_count }})</span
                      >
                      <span class="float-end">
                        {{ DocumentUpcomingExpire.percentage + "%" }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <div class="table-box table-full-height">
                <div class="p-4">
                  <h4>New Documents By Month</h4>
                  <p>Documents Added To Pocket Box By Month</p>
                </div>
                <div v-if="NewDocMonthLoader" class="loading-class">
                  <Loading />
                </div>
                <canvas
                  ref="chart_NewDocumentsByMonth"
                  style="width: 100%; height: 400px"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Chart from "chart.js";
  import axios from "axios";
  import Navigation from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  const {
    ucwords,
  } = require("@/Utils/MooseTheCoder/PHPJSLangFunctions/LanguageFunctions.js");
  import ChartOptions from "@/Admin/ChartOptions";

  export default {
    components: {
      Navigation,
      Loading,
    },
    data() {
      return {
        TotalDocs: 0,
        TotalDocPercent: 0,
        NewDocThisMonth: 0,
        NewDocThisMonthPercent: 0,
        DocumentExpiring: 0,
        DocumentExpiringPercent: 0,
        DocumentNoExpiring: 0,
        DocumentNoExpiringPercent: 0,
        DocumentsByType: [],
        DocumentsByTypeGraph: [],
        DocumentUpcomingExpires: [],
        Monthly: [],
        totalDocLoader: true,
        NewDocLoader: true,
        DocExpLoader: true,
        DocNoExpLoader: true,
        DocByTypeLoader: true,
        DocExpiringLoader: true,
        NewDocMonthLoader: true,
      };
    },
    mounted() {
      document.title = "PocketBox Admin - Documents";

      var actions = [
        "TotalDocuments",
        "NewDocumentThisMonth",
        "DocumentExpireWithIn30Days",
        "DocumentWithNoExpiry",
        "DocumentByType",
        "DocumentExpiring",
        "NewDocumentsByMonth",
      ];
      var setTime = 2000;
      actions.forEach((action) => {
        setTimeout(() => {
          this.getDocumentDataFragment(action);
        }, setTime);
        setTime = parseInt(setTime) + 100;
      });
    },
    methods: {
      getDocumentDataFragment(type) {
        axios
          .post(Endpoints.Data.DocumentsFragment, {
            token: localStorage.getItem("token"),
            data: type,
          })
          .then((RequestResponse) => {
            if (type == "TotalDocuments") {
              this.TotalDocs = RequestResponse.data.data.TotalDocs;
              this.TotalDocPercent = RequestResponse.data.data.TotalDocPercent;
              this.totalDocLoader = false;
            }
            if (type == "NewDocumentThisMonth") {
              this.NewDocThisMonth = RequestResponse.data.data.NewDocThisMonth;
              this.NewDocThisMonthPercent =
                RequestResponse.data.data.NewDocThisMonthPercent;
              this.NewDocLoader = false;
            }
            if (type == "DocumentExpireWithIn30Days") {
              this.DocumentExpiring =
                RequestResponse.data.data.DocumentExpiring;
              this.DocumentExpiringPercent =
                RequestResponse.data.data.DocumentExpiringPercent;
              this.DocExpLoader = false;
            }
            if (type == "DocumentWithNoExpiry") {
              this.DocumentNoExpiring =
                RequestResponse.data.data.DocumentNoExpiring;
              this.DocumentNoExpiringPercent =
                RequestResponse.data.data.DocumentNoExpiringPercent;
              this.DocNoExpLoader = false;
            }
            if (type == "DocumentExpiring") {
              this.DocumentUpcomingExpires =
                RequestResponse.data.data.DocumentUpcomingExpires;
              this.DocExpiringLoader = false;
            }
            if (type == "NewDocumentsByMonth") {
              this.Monthly = RequestResponse.data.data.Monthly;
              this.NewDocMonthLoader = false;
              this.DrawChartsForNewDocumentByMonth();
            }
            if (type == "DocumentByType") {
              this.DocumentsByType = RequestResponse.data.data.DocumentByType;
              this.DocByTypeLoader = false;
              /*Add Key In this.DocumentsByType*/
              for (let docTypeIndex in this.DocumentsByType) {
                this.DocumentsByType[docTypeIndex]["key"] =
                  this.DocumentsByType[docTypeIndex].type
                    .replace("_", " ")
                    .replace(/([A-Z])/g, " $1")
                    // uppercase the first character
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    });
              }

              this.DocumentsByType.forEach((doc) => {
                let color =
                  doc.type == "insurance"
                    ? "#4c8bfd"
                    : doc.type == "warranty"
                    ? "#F37137"
                    : doc.type == "service"
                    ? "#F49D37"
                    : doc.type == "passport"
                    ? "#6c68ff"
                    : doc.type == "finance"
                    ? "#ff33a2"
                    : doc.type == "other"
                    ? "#27bb7c"
                    : doc.type == "driving_license"
                    ? "#6c68ff"
                    : "#4c8bfd";
                this.DocumentsByTypeGraph.push({
                  data: [doc.total],
                  backgroundColor: [color],
                  borderWidth: 1,
                  barThickness: 40,
                  maxBarThickness: 40,
                });
              });
              this.DrawChartsForDocumentByType();
            }
          })
          .catch((error) => {
            this.getDocumentDataFragment(type);
          });
      },
      DrawChartsForDocumentByType() {
        if (document.body.contains(this.$refs.chart_DocumentByType)) {
          var stackedBar = new Chart(
            this.$refs.chart_DocumentByType.getContext("2d"),
            {
              type: "horizontalBar",
              data: {
                datasets: this.DocumentsByTypeGraph,
              },
              options: {
                responsive: false,
                legend: {
                  display: false,
                },
                tooltips: {
                  enabled: false,
                },
                scales: {
                  xAxes: [
                    {
                      stacked: true,
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        display: false, //this will remove only the label
                      },
                    },
                  ],
                  yAxes: [
                    {
                      stacked: true,
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        display: false, //this will remove only the label
                      },
                    },
                  ],
                },
              },
            }
          );
        }
      },
      DrawChartsForNewDocumentByMonth() {
        if (document.body.contains(this.$refs.chart_NewDocumentsByMonth)) {
          var chart_NewDocumentsByMonth2D =
              this.$refs.chart_NewDocumentsByMonth.getContext("2d"),
            gradient = chart_NewDocumentsByMonth2D.createLinearGradient(
              0,
              0,
              0,
              450
            );
          gradient.addColorStop(0, "rgba( 44, 121, 249 , 0.5)");
          gradient.addColorStop(0.5, "rgba( 44, 121, 249 , 0.25)");
          gradient.addColorStop(1, "rgba( 44, 121, 249 , 0)");
          let chart_NewDocumentsByMonth = new Chart(
            chart_NewDocumentsByMonth2D,
            {
              type: "line",
              data: {
                labels: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                datasets: [
                  {
                    label: "New Documents",
                    backgroundColor: gradient,
                    pointBackgroundColor: "white",
                    borderWidth: 1,
                    borderColor: "#347ef9",
                    pointRadius: 3,
                    pointHoverRadius: 4,
                    data: this.Monthly,
                  },
                ],
              },
              options: {
                layout: {
                  padding: 20,
                },
                legend: {
                  display: false,
                },
                scales: {
                  yAxes: [
                    {
                      position: "right",
                      ticks: {
                        display: true,
                        beginAtZero: true,
                        min: 0,
                      },

                      distribution: "linear",
                    },
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                },
                tooltips: {
                  backgroundColor: "#3cb054",
                  borderColor: "#3cb054",
                  borderWidth: 1,
                  borderRadius: 10,
                  titleFontColor: "white",
                  titleFontStyle: "bold",
                  displayColors: false,
                  bodyFontColor: "white",
                  yAlign: "bottom",
                },
              },
            }
          );
        }
      },
    },
  };
</script>

<style></style>
