var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"updateCompanyFleetUserPassword",staticClass:"modal fade",attrs:{"id":"updateCompanyFleetUserPassword","tabindex":"-1","role":"dialog","aria-labelledby":"updateCompanyFleetUserPassword","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"updateCompanyFleetUserPassword23"}},[_vm._v(" Update Fleet User Password ")]),_c('button',{ref:"closeChangePasswordModal",staticClass:"close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.clearFleetUserPasswordModal}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('form',{ref:"updateCompanyFleetUserPasswordForm",on:{"submit":function($event){$event.preventDefault();return _vm.updateCompanyUserPassword.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-lg-12"},[_c('div',{staticClass:"mb-3"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updatePassword.new_password),expression:"updatePassword.new_password"}],staticClass:"form-control",class:{
                    'is-invalid':
                      _vm.submitted && _vm.$v.updatePassword.new_password.$error,
                  },attrs:{"type":"password","placeholder":"Enter New Password"},domProps:{"value":(_vm.updatePassword.new_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updatePassword, "new_password", $event.target.value)}}}),(_vm.submitted && !_vm.$v.updatePassword.new_password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" New Password is required ")]):_vm._e(),(_vm.submitted && !_vm.$v.updatePassword.new_password.minLength)?_c('div',{staticClass:"d-block invalid-feedback"},[_vm._v("Password must be at least 8 characters long.")]):_vm._e(),(_vm.submitted && !_vm.$v.updatePassword.new_password.strong)?_c('div',{staticClass:"d-block invalid-feedback"},[_vm._v("Password must be strong (eg. include at least 1 capital letter, 1 number and 1 special character)")]):_vm._e()])]),_c('div',{staticClass:"col-sm-12 col-lg-12"},[_c('div',{staticClass:"mb-3"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updatePassword.confirm_new_password),expression:"updatePassword.confirm_new_password"}],staticClass:"form-control",class:{
                    'is-invalid':
                      _vm.submitted &&
                      _vm.$v.updatePassword.confirm_new_password.$error,
                  },attrs:{"type":"password","placeholder":"Enter Confirm New Password"},domProps:{"value":(_vm.updatePassword.confirm_new_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.updatePassword, "confirm_new_password", $event.target.value)}}}),(
                    _vm.submitted &&
                      !_vm.$v.updatePassword.confirm_new_password.required
                  )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Confirm New Password is required ")]):_vm._e(),(
                    _vm.submitted &&
                      !_vm.$v.updatePassword.confirm_new_password.sameAsPassword
                  )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Passwords must be identical ")]):_vm._e()])])]),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.Saving},on:{"click":function($event){return _vm.updateCompanyUserPasswordPasword()}}},[_vm._v(" Save ")]),(_vm.Saving)?_c('Loading'):_vm._e()],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label"},[_vm._v("New Password"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label"},[_vm._v("Confirm New Password"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])}]

export { render, staticRenderFns }