<template>
  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="table-box">
      <div class="p-4">
        <h4>Popular Makes</h4>
        <p>Top Vehicle Make</p>
      </div>
      <div class="table-responsive">
        <table class="table" width="100%" border="0">
          <thead>
            <tr>
              <th width="30%">Make</th>
              <th width="20%">Vehicles</th>
              <th width="30%">Popular Model</th>
              <th width="20%">Total %</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="table-responsive" style="height: 350px">
        <table class="table" width="100%" border="0">
          <tbody>
            <tr v-for="VehiclesMake in VehiclesMakes" :key="VehiclesMake.index">
              <td width="30%">{{ VehiclesMake.label }}</td>
              <td width="20%">{{ VehiclesMake.count }}</td>
              <td width="30%">{{ VehiclesMake.model.brand_model }}</td>
              <td width="20%">{{ VehiclesMake.model.percent }}%</td>
            </tr>
            <tr>
              <td colspan="5" v-if="Loader">
                <div class="loading-class"><Loading /></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        VehiclesMakes: [],
        VehicleMakes: {
          Labels: [],
          Data: [],
          Colors: [],
        },
        Loader: false,
      };
    },
    mounted() {
      this.Loader = true;
      axios
        .post(Endpoints.Data.VehiclesFragment, {
          token: localStorage.getItem("token"),
          data: "PopularMakes",
        })
        .then((RequestResponse) => {
          if (RequestResponse.data.success) {
            this.VehiclesMakes = RequestResponse.data.data.PopularMakes;
            this.Loader = false;
          }
        });
    },
    methods: {},
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
</style>
