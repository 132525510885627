<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title mt-2 mb-4">Edit Fleet Company User</h1>
              <div class="panel-box p-5">
                <div class="row">
                  <div class="form-step-col p-5">
                    <form
                      ref="updateCompanyFleetUserForm"
                      @submit.prevent="updateCompanyFleetUser"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-lg-6 d-none">
                          <div class="mb-3">
                            <label class="form-label"
                              >User Type<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <select
                              v-model="EditCompanyFleetUser.company_role"
                              class="form-select"
                            >
                              <option value="1">Admin</option>
                              <option value="2">Sales Person</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <div class="mb-3">
                            <label class="form-label"
                              >Account Role<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <select
                              class="form-control"
                              type="text"
                              v-model="EditCompanyFleetUser.role_id"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.EditCompanyFleetUser.role_id.$error,
                              }"
                            >
                              <option value="" selected>Select Role</option>
                              <option
                                v-for="(Role, RoleIndex) in Roles"
                                :key="RoleIndex"
                                :value="Role.id"
                              >
                                {{ Role.title }}
                              </option>
                            </select>
                            <div
                              v-if="
                                submitted &&
                                !$v.EditCompanyFleetUser.role_id.required
                              "
                              class="invalid-feedback"
                            >
                              Account role is required
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <div class="mb-3">
                            <label class="form-label"
                              >First Name<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter First Name"
                              v-model="EditCompanyFleetUser.forename"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.EditCompanyFleetUser.forename.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.EditCompanyFleetUser.forename.required
                              "
                              class="invalid-feedback"
                            >
                              First Name is required
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <div class="mb-3">
                            <label class="form-label"
                              >Last Name<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Last Name"
                              v-model="EditCompanyFleetUser.surname"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.EditCompanyFleetUser.surname.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.EditCompanyFleetUser.surname.required
                              "
                              class="invalid-feedback"
                            >
                              Last Name is required
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <div class="mb-3">
                            <label class="form-label"
                              >Email Address<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Email Address"
                              v-model="EditCompanyFleetUser.email"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.EditCompanyFleetUser.email.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.EditCompanyFleetUser.email.required
                              "
                              class="invalid-feedback"
                            >
                              Email Address is required
                            </div>
                            <div
                              v-if="
                                submitted &&
                                !$v.EditCompanyFleetUser.email.email
                              "
                              class="invalid-feedback"
                            >
                              Valid Email Address is required
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <div class="mb-3">
                            <label class="form-label"
                              >Contact Number<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Contact Number"
                              v-model="EditCompanyFleetUser.phone"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.EditCompanyFleetUser.phone.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.EditCompanyFleetUser.phone.required
                              "
                              class="invalid-feedback"
                            >
                              Contact Number is required
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <div class="mb-3">
                            <label>Branches</label>
                            <select
                              class="form-control"
                              type="text"
                              v-model="EditCompanyFleetUser.branch_ids"
                              multiple
                              style="height: 100px"
                            >
                              <option
                                v-for="(Branch, BranchIndex) in Branches"
                                :key="BranchIndex"
                                :value="Branch.id"
                              >
                                {{ Branch.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-6">
                        <div class="form-group">
                          <label>Two Factor Authentication</label>
                          <label
                            class="form-check form-check-single form-switch form-switchs"
                          >
                            <span class="off-button">OFF </span><input
                              class="form-check-input m-0"
                              type="checkbox"
                              v-model="EditCompanyFleetUser.is_2fa_enabled"
                              @change="
                                $emit('input', $event.target.checked)
                              "
                            /><span class="on-button">ON </span>
                          </label>
                        </div>
                      </div>
                        <div class="col-sm-12 col-lg-6">
                          <div class="mb-3">
                            <label class="form-label">Profile Image</label>
                            <input
                              class="input form-control"
                              type="file"
                              name="profile_image"
                              ref="ProfileImageFile"
                              :class="{
                                'is-invalid':
                                  $v.EditCompanyFleetUser.profile_image.$error,
                              }"
                              @change="imageFileChanged($event)"
                              accept="image/*"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.EditCompanyFleetUser.profile_image
                                  .file_size_validation
                              "
                              class="invalid-feedback"
                            >
                              Please upload less than 1 MB file size image
                            </div>
                            <div
                              v-if="
                                submitted &&
                                !$v.EditCompanyFleetUser.profile_image
                                  .image_type_validation
                              "
                              class="invalid-feedback"
                            >
                              Please upload jpeg, png, gif or svg file only
                            </div>
                            <div
                              class="profile-image mt-10"
                              v-show="
                                EditCompanyFleetUser.profile_image &&
                                EditCompanyFleetUser.profile_image != 1
                              "
                            >
                              <img
                                :src="GetProfileImage(EditCompanyFleetUser.id)"
                                class="icon__preview"
                                ref="editProfileImagePreview"
                                :title="EditCompanyFleetUser.forename"
                                :alt="EditCompanyFleetUser.forename"
                                @error="
                                  $event.target.src = GetProfileImage(
                                    EditCompanyFleetUser.id
                                  )
                                "
                                width="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-right">
                        <router-link
                          :to="{
                            name: 'CompanyFleetUsers',
                            params: {
                              CompanyId: EditCompanyFleetUser.company_id,
                            },
                          }"
                          class="btn btn-default"
                          title="Back"
                          >&larr; Back</router-link
                        >

                        <button
                          class="btn btn-primary"
                          v-on:click="updateCompanyFleetUser()"
                          :disabled="Saving"
                        >
                          Save
                        </button>
                        <Loading v-if="Saving" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required, email } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";

  const file_size_validation = (value, vm) => {
    if (!value || !value.size) {
      return true;
    }
    let file = value;
    return file.size < 1048576;
  };
  const image_type_validation = (value, vm) => {
    if (!value || !value.size) {
      return true;
    }
    let file = value;
    if (
      file.type == "image/svg+xml" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif" ||
      file.type == "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };

  export default {
    //props: ["EditCompanyFleetUser"],
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        Saving: false,
        submitted: false,
        Roles: [],
        Branches: [],
        EditCompanyFleetUser: {
          role_id: "",
          forename: "",
          surname: "",
          email: "",
          phone: "",
          profile_image: "",
          branch_ids: [],
          is_2fa_enabled: false,
        },
      };
    },
    validations: {
      EditCompanyFleetUser: {
        role_id: { required },
        forename: { required },
        surname: { required },
        email: { required, email },
        phone: { required },
        profile_image: { file_size_validation, image_type_validation },
      },
    },
    mounted() {
      this.UserId = this.$route.params.UserId;
      this.getFleetUserData();
    },
    methods: {
      Set2FA(type){
        this.NewCompanyFleetUser.is_2fa_enabled = type;
      },
      GetBranches() {
        var obj = {
          token: localStorage.getItem("token"),
          company_id: this.EditCompanyFleetUser.company_id,
        };
        Axios.post(Endpoints.Branch.List, obj).then((Response) => {
          if (Response.data.success) {
            this.Branches = Response.data.records;
          }
        });
      },
      GetRoles() {
        var obj = {
          token: localStorage.getItem("token"),
          company_id: this.EditCompanyFleetUser.company_id,
        };
        Axios.post(Endpoints.Roles.List, obj).then((Response) => {
          if (Response.data.success) {
            this.Roles = Response.data.records;
          }
        });
      },
      getFleetUserData() {
        this.Saving = true;
        Axios.post(Endpoints.Company.FleetUser.Get, {
          token: localStorage.getItem("token"),
          id: this.UserId,
        }).then((Response) => {
          if (typeof Response.data.record != "undefined") {
            this.EditCompanyFleetUser = Response.data.record;
            this.GetProfileImage(this.EditCompanyFleetUser.profile_image);

            this.GetRoles();
            this.GetBranches();
          }

          this.Saving = false;
        });
      },
      imageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.EditCompanyFleetUser.profile_image = files[0];
        var output = document.querySelector(".icon__preview");
        output.src = URL.createObjectURL(files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
      },
      updateCompanyFleetUser() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Company.FleetUser.Update, {
          token: localStorage.getItem("token"),
          ...this.EditCompanyFleetUser,
        }).then((Response) => {
          if (Response.data.success) {
            this.UpdateProfileImage(Response.data.last_id);
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
            this.Saving = false;
          }
        });
      },
      UpdateProfileImage(id) {
        var LogoFormData = new FormData();
        if (this.$refs.ProfileImageFile.files[0]) {
          LogoFormData.append("storage_type", "CFU");
          LogoFormData.append("token", localStorage.getItem("token"));
          LogoFormData.append("file", this.$refs.ProfileImageFile.files[0]);
          LogoFormData.append(
            "company_id",
            this.EditCompanyFleetUser.company_id
          );
          LogoFormData.append("user_id", id);
          Axios.post(Endpoints.Helper.File.Upload, LogoFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Company.FleetUser.ProfileImageSet, {
                  token: localStorage.getItem("token"),
                  profile_image: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  Swal.fire(
                    "Fleet User Updated Successfully",
                    "",
                    "success"
                  ).then(() => {
                    this.$router.push(
                      "/company/fleet/users/" +
                        this.EditCompanyFleetUser.company_id
                    );
                  });
                });
              }
            }
          );
        } else {
          Swal.fire("Fleet User Updated Successfully", "", "success").then(
            () => {
              this.$router.push(
                "/company/fleet/users/" + this.EditCompanyFleetUser.company_id
              );
            }
          );
        }
      },
      clearCompanyUserModal() {
        this.$refs.updateCompanyFleetUserForm.reset();
        var self = this;
        Object.keys(this.updateCompanyFleetUser).forEach(function (
          subsKey,
          index
        ) {
          self.updateCompanyFleetUser[subsKey] = "";
        });
        this.$refs.editProfileImagePreview.src = "";
        this.submitted = false;
      },
      GetProfileImage(profileImage) {
        Axios.post(Endpoints.Helper.File.Get, {
          token: localStorage.getItem("token"),
          file: profileImage,
        }).then((FileGetResponse) => {
          var FileData = FileGetResponse.data;
          if (FileData.success === true) {
            this.ProfileImage = FileData.file;
            this.$refs.editProfileImagePreview.src = FileData.file;
          }
        });
      },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
  .form-switchs .form-check-input{
    height: 20px;
    width: 45px !important;
    margin: 0px 10px 0px 10px !important;
  }
  .modal-backdrop {
    background-color: #232e3c !important;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .off-button{
    float: left;
  } 
  .on-button{
    display: inline-block;
  }
</style>
