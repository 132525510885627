<template>
<div class="wrapper">
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-lg-6 col-sm-6 no-padding"> <img src="assets/images/login-left-bg.png"> </div>
    <div class="col-md-6 col-lg-6 col-sm-6">
      <div class="row">
        <div class="col-md-6 col-lg-6 col-sm-6 offset-md-3 offset-lg-3 offset-sm-3">
      	<div id="login" >
			<div class="login-wrapper">
            <div class="logo"> <img src="assets/images/logo.png"> </div>
            <div id="login-box">
              <h2>Login to Pocket Box Admin</h2>
              <p>Please enter your email and password to continue</p>
              <form id="login-form" class="form" action="." method="get" autocomplete="off">
                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input type="email" placeholder="example@mail.com" name="email" class="form-control" v-model="User.email">
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <!-- <a href="#" class="forgot-tag float-end">Forgot Password?</a> -->
                  <input type="password" name="password" placeholder="********" class="form-control" autocomplete="off" v-model="User.password">
                </div>
                <!--<div class="form-group checkbox_col" >
                  <label>
                    <input name="remember-me" type="checkbox" disabled> 
                   <span>Remember Password</span>  </label>
                </div>-->
                <input type="button" name="submit" class="btn btn-submit btn-md" value="Sign In"  v-on:click="UserLogin()">
              </form>
            </div>
      </div>
    </div>
       </div>
        </div>
       </div>
  </div>
</div>
</div>
<!-- <div class="flex-fill d-flex flex-column justify-content-center py-4">
      <div class="container-tight py-6">
        <div class="text-center mb-4">
          <a href="."><img src="@/assets/logo.png" height="36" alt=""></a>
        </div>
        <div class="card card-md" action="." method="get" autocomplete="off">
          <div class="card-body">
            <h2 class="card-title text-center mb-4">Login to your account</h2>
            <div class="mb-3">
              <label class="form-label">Email address</label>
              <input type="email" class="form-control" placeholder="Enter email" v-model="User.email">
            </div>
            <div class="mb-2">
              <label class="form-label">
                Password
              </label>
              <div class="input-group input-group-flat">
                <input type="password" class="form-control" placeholder="Password" autocomplete="off" v-model="User.password">
              </div>
            </div>
            <div class="form-footer">
              <button type="button" class="btn btn-pocketbox w-100"  v-on:click="UserLogin()">Sign in</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
</template>

<script>
import Endpoints from '@/Admin/Endpoints';
import axios from 'axios';
import Swal from 'sweetalert2'
export default {
	mounted(){
		document.title="PocketBox Admin - Login";
		if(localStorage.getItem('SHOW_AUTH_ERR')){
			Swal.fire({
				icon: 'error',
				title: 'Session Expired',
				text: 'Your session expired. You need to log in again.',
			});
			localStorage.removeItem('SHOW_AUTH_ERR');
		}
		document.body.classList.add('login-body')
	},
	data(){
		return {
			User : {
				email : '',
				password: ''
			}
		}
	},
	methods : {
		UserLogin(){
      Swal.fire({
          title: "Checking...",
          text: "Please wait...",
          imageUrl: require("../assets/images/LoadingTwo.gif"),
          showConfirmButton: false,
          allowOutsideClick: false,
        });
			axios.post(Endpoints.User.Login, this.User).then(LoginResponse => {
				var ResponseData = LoginResponse.data;
				if(ResponseData.success === false){
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: ResponseData.message,
					})
				}
				if(ResponseData.success === true){
          Swal.close()
					localStorage.setItem('token', ResponseData.token);
          const redirectPath = this.$route.query.redirect || "/dashboard";
					this.$router.push(redirectPath);
				}
			})
		}
	}
}
</script>

<style>

</style>