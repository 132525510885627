<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="table-box">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 p-4">
                <h4>Plus Subscriptions</h4>
                <p>Pocket Box Plus Subscriptions information</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 px-4 mt-3 mb-3">
                <div class="table-responsive table-color">
                  <table
                    class="table table-vcenter card-table"
                    id="subscriptions_list"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Title</th>
                        <th>Price</th>
                        <th>Allowed Ads</th>
                        <th>Hot Deal Allow</th>
                        <th>Feature Ad</th>
                        <th>Default Ad</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="showLoader">
                      <tr>
                        <td>
                          <Loading />
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr
                        v-for="(Subscription, index) in Subscriptions"
                        :key="index"
                      >
                        <td class="w-1">{{ ++index }}</td>
                        <td>{{ Subscription.title }}</td>
                        <td>£{{ Subscription.price }}</td>
                        <td>{{ Subscription.allowed_ads }}</td>
                        <td>{{ Subscription.max_hot_deal_allow }}</td>
                        <td>
                          {{
                            Subscription.is_feature_ad == true ? "Yes" : "No"
                          }}
                        </td>
                        <td>
                          {{
                            Subscription.is_default_ad == true ? "Yes" : "No"
                          }}
                        </td>
                        <td>
                          <router-link
                            :to="{
                              name: 'UpdateSubscription',
                              params: { SubscriptionId: Subscription.id },
                            }"
                            class="btn btn-primary"
                            title="Edit User"
                            >Edit</router-link
                          >
                          <button
                            class="btn btn-primary"
                            v-on:click="SubscriptionDelete(Subscription.id)"
                            style="margin-left: 10px"
                            title="Delete Record"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "jquery/dist/jquery.min.js";
  import $ from "jquery";

  import addSubscription from "@/views/Subscriptions/Create";
  import editSubscription from "@/views/Subscriptions/Update";
  import Nav from "@/components/Navigation";
  import Axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";

  export default {
    components: {
      Nav,
      addSubscription,
      editSubscription,
      Loading,
    },
    mounted() {
      document.title = "PocketBox Admin - Subscriptions";
      this.GetSubscriptionsList(1, 1000);
    },
    data() {
      return {
        Subscriptions: {},
        editRec: {},
        showLoader: false,
        search_keyword: "",
        ComponentKey: 0,
      };
    },
    methods: {
      forceRerender() {
        this.ComponentKey += 1;
      },
      GetSubscriptionsList(Start, Limit) {
        this.showLoader = true;
        Axios.post(Endpoints.Subscription.List, {
          token: localStorage.getItem("token"),
          start: Start,
          limit: Limit,
        }).then((Response) => {
          this.Subscriptions = Response.data.records;
          this.showLoader = false;
          setTimeout(() => {
            $("#subscriptions_list").DataTable();
          }, 1000);
        });
      },
      SubscriptionDelete(id) {
        Swal.fire({
          title: "Are you sure to delete this subscription?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Deleting...",
              text: "Please wait...",
              imageUrl: require("../../assets/images/LoadingTwo.gif"),
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            Axios.post(Endpoints.Subscription.Remove, {
              token: localStorage.getItem("token"),
              id: id,
            }).then((Response) => {
              if (Response.data.success == true) {
                this.GetSubscriptionsList(1, 1000);
                Swal.fire(
                  "Subscriptions Deleted Successfully",
                  "",
                  "success"
                ).then(() => {});
              } else {
                Swal.fire({
                  title: "Oops!",
                  html: Response.data.message,
                  icon: "error",
                  confirmButtonText: "Close",
                });
              }
            });
          }
        });
      },
      getRecord(id) {
        Axios.post(Endpoints.Subscription.Get, {
          token: localStorage.getItem("token"),
          id: id,
        }).then((Response) => {
          this.editRec = Response.data.record;
        });
      },
      refreshList() {
        this.GetSubscriptionsList(1, 1000);
        this.forceRerender();
      },
    },
  };
</script>
<style></style>
