<template>
<div class="page">
  <Nav />
  <div class="app-wrapper">
  <div class="app-content pt-3 p-md-3 p-lg-4">
    <div class="container-fluid">
      <div class="row gy-4">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <h1 class="main-title mt-2 mb-4">Update Marketplace Promotion</h1>
          <div class="panel-box">
            <div class="row">
              <div class="form-step-col p-5">
                <form @submit.prevent="UpdateMarketplaceData">
                    <div class="row">
                      <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <label class="form-label"
                            >Title<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Title"
                            v-model="NewPromotion.title"
                            :class="{
                              'is-invalid':
                                submitted && $v.NewPromotion.title.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.NewPromotion.title.required"
                            class="invalid-feedback"
                          >
                            Title is required
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <label class="form-label"
                            >URL<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter URL"
                            v-model="NewPromotion.url"
                            :class="{
                              'is-invalid':
                                submitted && $v.NewPromotion.url.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.NewPromotion.url.required"
                            class="invalid-feedback"
                          >
                            URL is required
                          </div>
                          <div
                            v-if="submitted && !$v.NewPromotion.url.url"
                            class="invalid-feedback"
                          >
                            Enter valid URL
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <label class="form-label"
                            >Description<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Description"
                            v-model="NewPromotion.description"
                            maxlength="70"
                            :class="{
                              'is-invalid':
                                submitted && $v.NewPromotion.description.$error,
                            }"
                          />
                          <small class="form-hint"
                            >Description length 70 characters.</small
                          >
                          <div
                            v-if="
                              submitted && !$v.NewPromotion.description.required
                            "
                            class="invalid-feedback"
                          >
                            Description is required
                          </div>
                          <div
                            v-if="
                              submitted &&
                                !$v.NewPromotion.description.maxLength
                            "
                            class="invalid-feedback"
                          >
                            Description length is exceeded.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <label class="form-label"
                            >Company<span class="text-danger">*</span></label
                          >
                          <select
                            v-model="NewPromotion.company_id"
                            :class="{
                              'is-invalid':
                                submitted && $v.NewPromotion.company_id.$error,
                            }"
                            class="form-select"
                          >
                            <option value="">Select Company</option>
                            <option
                              v-for="Company in Companies"
                              v-bind:key="Company.id"
                              v-bind:value="Company.id"
                            >
                              {{ Company.name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              submitted && !$v.NewPromotion.company_id.required
                            "
                            class="invalid-feedback"
                          >
                            Company is required
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <label class="form-label"
                            >Category<span class="text-danger">*</span></label
                          >
                          <select
                            v-model="NewPromotion.category"
                            :class="{
                              'is-invalid':
                                submitted && $v.NewPromotion.category.$error,
                            }"
                            class="form-select"
                          >
                            <option value="">Select Category</option>
                            <option
                              v-for="Category in Categories"
                              v-bind:key="Category.id"
                              v-bind:value="Category.id"
                            >
                              {{ Category.category_name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              submitted && !$v.NewPromotion.category.required
                            "
                            class="invalid-feedback"
                          >
                            Category is required
                          </div>
                        </div>
                      </div>
                       <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <label class="form-label"
                            >Start Date<span class="text-danger">*</span></label
                          >
                          <input
                            type="date"
                            class="form-control"
                            v-model="NewPromotion.start_date"
                            :class="{
                              'is-invalid':
                                submitted && $v.NewPromotion.start_date.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitted && !$v.NewPromotion.start_date.required
                            "
                            class="invalid-feedback"
                          >
                            Start date is required
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-2">
                        <div class="mb-3">
                          <label class="form-label"
                            >Hot Deal
                            <img
                              src="@/assets/images/hot-deals-label.png"
                              width="25"
                              alt="Hot Deals Label"
                          /></label>
                          <label
                            class="form-check form-check-single form-switch label-radio"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="NewPromotion.hot_deal"
                            />
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Featured</label>
                          <label
                            class="form-check form-check-single form-switch label-radio"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="NewPromotion.is_featured"
                            />
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <label class="form-label"
                            >Term<span class="text-danger">*</span></label
                          >
                          <select
                            v-model="NewPromotion.term"
                            :class="{
                              'is-invalid':
                                submitted && $v.NewPromotion.term.$error,
                            }"
                            class="form-select"
                          >
                            <option value="">Select Term</option>
                            <option
                              v-for="index in 12"
                              v-bind:value="index"
                              v-bind:key="index"
                            >
                              {{ index }} Month
                            </option>
                          </select>
                          <div
                            v-if="submitted && !$v.NewPromotion.term.required"
                            class="invalid-feedback"
                          >
                            Term is required
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" style="width: 100%;">Online Promotions</label>
                          <span style="float: left">No</span>
                          <label
                            style="float: left; padding: 0px;margin: 0px 10px 0 50px;"
                            class="form-check form-check-single form-switch"
                          >
                            <input
                              name="online_promotion"
                              class="form-check-input"
                              type="checkbox"
                              v-model="NewPromotion.online_promotion"
                            />
                          </label>
                          <span style="float: left">Yes</span>
                          <div style="clear: both"></div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-4"
                        v-if="
                          NewPromotion.online_promotion == null ||
                            NewPromotion.online_promotion == ''
                        "
                      >
                        <div class="mb-3">
                          <label class="form-label"
                            >Postcode<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control text-uppercase"
                            placeholder="Enter Postcode"
                            v-model="NewPromotion.zipcode"
                            :class="{
                              'is-invalid':
                                submitted && $v.NewPromotion.zipcode.$error,
                            }"
                            maxlength="7"
                          />
                          <div
                            v-if="
                              submitted && !$v.NewPromotion.zipcode.required
                            "
                            class="invalid-feedback"
                          >
                            Postcode is required
                          </div>
                        </div>
                      </div>
                      
                     
                      <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <label class="form-label">Company Logo</label>
                          <input
                            class="input form-control"
                            type="file"
                            name="resume"
                            ref="logoFile"
                            :class="{
                              'is-invalid': $v.NewPromotion.logo_image.$error,
                            }"
                            @change="imageFileChanged($event)"
                            accept="image/*"
                          />
                          <div
                            v-if="
                              submitted &&
                                !$v.NewPromotion.logo_image.file_size_validation
                            "
                            class="invalid-feedback"
                          >
                            Please upload less than 1 MB file size image
                          </div>
                          <div
                            v-if="
                              submitted &&
                                !$v.NewPromotion.logo_image
                                  .image_type_validation
                            "
                            class="invalid-feedback"
                          >
                            Please upload jpeg, png, gif or svg file only
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-4">
                        <div class="mb-3">
                          <img
                            v-show="NewPromotion.logo_image"
                            ref="CompanyLogoPreview"
                            class=""
                            width="100"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-12 text-right">
                      <Loading v-if="Saving" class="loading-btn" />
                      <button
                        class="btn btn-primary text-right"
                         v-on:click="UpdateMarketplaceData()"
                        :disabled="Saving"
                      >
                        Update
                      </button>
                      <router-link
                      :to="{ name: 'MarketplacePromotions' }"
                      class="btn btn-primary mx-2 text-right "
                    >
                      &larr; Back
                    </router-link
                    >
                    </div>
                  </form>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 
</template>
<script>
  import {
    required,
    url,
    email,
    minLength,
    sameAs,
    maxLength,
    requiredIf,
  } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  const file_size_validation = (value, vm) => {
    if (!value || !value.size) {
      return true;
    }
    let file = value;
    return file.size < 1048576;
  };
  const image_type_validation = (value, vm) => {
    if (!value || !value.size) {
      return true;
    }
    let file = value;
    if (
      file.type == "image/svg+xml" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif" ||
      file.type == "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };
  export default {
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        Saving: false,
        submitted: false,
        PromotionId: 0,
        CompanyLogoFilename: "",
        Companies: false,
        Categories: false,
        CompanyLogo: "",
        NewPromotion: {
          title: "",
          url: "",
          description: "",
          hot_deal: "",
          category: "",
          company_id: "",
          term: "",
          start_date: "",
          logo_image: "",
          online_promotion: "",
          zipcode: "",
          is_featured: "",
        },
      };
    },
    validations: {
      NewPromotion: {
        title: { required },
        url: { required, url },
        description: { required, maxLength: maxLength(70) },
        logo_image: { file_size_validation, image_type_validation },
        company_id: { required },
        category: { required },
        term: { required },
        start_date: { required },
        zipcode: {
          required: requiredIf(function() {
            return !this.NewPromotion.online_promotion;
          }),
        },
      },
    },
    mounted() {
      document.title = " PocketBox Admin - Marketplace Edit";
      this.GetCategoriesList();
      this.GetCompanyList();
      this.PromotionId = this.$route.params.PromotionId;
      this.PromotionLoad();
    },
    methods: {
      imageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.NewPromotion.logo_image = files[0];
         var output = document.querySelector('.company__logo__preview');
        output.src = URL.createObjectURL(files[0]);
        output.onload = function() {
          URL.revokeObjectURL(output.src) // free memory
        }
      },
      GetCompanyList() {
        Axios.post(Endpoints.Company.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Companies = Response.data.records;
          this.showLoader = false;
        });
      },
      GetCategoriesList() {
        Axios.post(Endpoints.Category.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Categories = Response.data.records;
          this.showLoader = false;
        });
      },
      PromotionLoad() {
        this.Saving = true;
        Axios.post(Endpoints.Marketplace.Get, {
          token: localStorage.getItem("token"),
          id: this.PromotionId,
        }).then((Response) => {
          this.NewPromotion = Response.data.record;
          this.Saving = false;
          this.CompanyLogoFilename = this.NewPromotion.logo_image;
          this.GetCompanyLogo();
        });
      },
      GetCompanyLogo() {
        Axios.post(Endpoints.Helper.File.Get, {
          token: localStorage.getItem("token"),
          file: this.CompanyLogoFilename,
        }).then((FileGetResponse) => {
          var FileData = FileGetResponse.data;
          if (FileData.success === true) {
            this.CompanyLogo = FileData.file;
            this.$refs.CompanyLogoPreview.src = FileData.file;
          }
        });
      },
      UpdateMarketplaceData() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Marketplace.Update, {
          token: localStorage.getItem("token"),
          ...this.NewPromotion,
        }).then((Response) => {
          if (Response.data.success) {
            this.SaveCompanyLogo(Response.data.last_id);
          } else {
            this.Saving = false;
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      SaveCompanyLogo(id) {
        var LogoFormData = new FormData();
        if (this.$refs.logoFile.files[0]) {
          LogoFormData.append("storage_type", "MP");
          LogoFormData.append("token", localStorage.getItem("token"));
          LogoFormData.append("file", this.$refs.logoFile.files[0]);
          LogoFormData.append("company_id", this.NewPromotion.company_id);
          Axios.post(Endpoints.Helper.File.Upload, LogoFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Marketplace.LogoSet, {
                  token: localStorage.getItem("token"),
                  logo: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  this.Saving = false;
                  Swal.fire(
                    "Marketplace Promotion Updated Successfully",
                    "",
                    "success"
                  ).then(() => {
                    this.$router.push("/marketplace-promotions");
                  });
                });
              }
            }
          );
        } else {
          this.Saving = false;
          Swal.fire(
            "Marketplace Promotion Updated Successfully",
            "",
            "success"
          ).then(() => {
            this.$router.push("/marketplace-promotions");
          });
        }
      },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
  .loading-btn {
    display: inline-block;
    vertical-align: top;
    font-size: 30px;
    margin-right: 10px;
  }
</style>
