module.exports.Bar = {
	scales: {
		yAxes: [{
			ticks: {
				beginAtZero:true,
				min: 0
			},
		}]
	}
}
module.exports.HorizontalBar = {
	legend : {
		display : false 
	},
	scales: {
		xAxes: [{
			ticks: {
				beginAtZero:true,
			},
		}]
	}
}