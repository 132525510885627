<template>
  <div class="page">
    <Navigation />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4 mt-1">
            <VehicleTopSections
              :key="0"
              ref="VehicleTopSections"
            ></VehicleTopSections>
          </div>
          <div class="row mt-4">
            <VehicleByType :key="1" ref="VehicleByType"></VehicleByType>
            <VehicleUpcomingExpiries
              :key="2"
              ref="VehicleUpcomingExpiries"
            ></VehicleUpcomingExpiries>
          </div>
          <div class="row mt-4">
            <PopularMakes :key="3" ref="PopularMakes"></PopularMakes>
            <VehiclesByMonth :key="4" ref="PopularMakes"></VehiclesByMonth>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Navigation from "@/components/Navigation";
  import VehicleTopSections from "@/views/Vehicle/VehicleTopSections";
  import VehicleByType from "@/views/Vehicle/VehicleByType";
  import VehicleUpcomingExpiries from "@/views/Vehicle/VehicleUpcomingExpiries";
  import PopularMakes from "@/views/Vehicle/PopularMakes";
  import VehiclesByMonth from "@/views/Vehicle/VehiclesByMonth";
  export default {
    components: {
      Navigation,
      VehicleByType,
      VehicleUpcomingExpiries,
      PopularMakes,
      VehiclesByMonth,
      VehicleTopSections,
    },
    data() {
      return {};
    },
    mounted() {
      document.title = "PocketBox Admin - Vehicles";
    },
    methods: {},
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
</style>
