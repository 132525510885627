<template>
      <form ref="CreateMarketplaceForm" @submit.prevent="CreateMarketplace">
           <div class="mb-4">
              <label class="form-label">
                Company<span class="text-danger">*</span>
              </label>
              <select
                v-model="NewPromotion.company_id"
                :class="{
                  'is-invalid': submitted && $v.NewPromotion.company_id.$error,
                }"
                class="form-select"
              >
                <option value="">Select Company</option>
                <option
                  v-for="Company in Companies"
                  v-bind:key="Company.id"
                  v-bind:value="Company.id"
                >
                  {{ Company.name }}
                </option>
              </select>
              <div
                v-if="submitted && !$v.NewPromotion.company_id.required"
                class="invalid-feedback"
              >
                Company is required
              </div>
            </div>
           <div class="mb-4">
              <label class="form-label">
                Title<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Title"
                v-model="NewPromotion.title"
                :class="{
                  'is-invalid': submitted && $v.NewPromotion.title.$error,
                }"
              />
              <div
                v-if="submitted && !$v.NewPromotion.title.required"
                class="invalid-feedback"
              >
                Title is required
              </div>
            </div>
           <div class="mb-4">
              <label class="form-label">
                URL<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter URL"
                v-model="NewPromotion.url"
                :class="{
                  'is-invalid': submitted && $v.NewPromotion.url.$error,
                }"
              />
              <div
                v-if="submitted && !$v.NewPromotion.url.required"
                class="invalid-feedback"
              >
                URL is required
              </div>
              <div
                v-if="submitted && !$v.NewPromotion.url.url"
                class="invalid-feedback"
              >
                Enter valid URL
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label">
                Description<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Description"
                v-model="NewPromotion.description"
                maxlength="70"
                :class="{
                  'is-invalid': submitted && $v.NewPromotion.description.$error,
                }"
              />
              <small class="form-hint">Description length 70 characters.</small>
              <div
                v-if="submitted && !$v.NewPromotion.description.required"
                class="invalid-feedback"
              >
                Description is required
              </div>
              <div
                v-if="submitted && !$v.NewPromotion.description.maxLength"
                class="invalid-feedback"
              >
                Description length is exceeded.
              </div>
            </div>
          
            <div class="mb-4">
              <label class="form-label">Upload Promotional Image (300px x 300px Recommended)</label>
              <input
                class="input form-control"
                type="file"
                name="resume"
                ref="logoFile"
                :class="{ 'is-invalid': $v.NewPromotion.logo_image.$error }"
                @change="imageFileChanged($event)"
                accept="image/*"
              />
              <small class="form-hint">Upload a Promotional Image.</small>
              <div
                v-if="
                  submitted && !$v.NewPromotion.logo_image.file_size_validation
                "
                class="invalid-feedback"
              >
                Please upload less than 1 MB file size image
              </div>
              <div
                v-if="
                  submitted && !$v.NewPromotion.logo_image.image_type_validation
                "
                class="invalid-feedback"
              >
                Please upload jpeg, png, gif or svg file only
              </div>
              <center>
                <img
                  v-show="NewPromotion.logo_image"
                  ref="CompanyLogoPreview"
                  class="company__logo__preview"
                />
              </center>
            </div>
            <div class="mb-4 hot-deal-img">
              <label class="form-label">
                Hot Deal
                <img
                  src="@/assets/images/hot-deals-label.png"
                  width="25"
                  alt="Hot Deals"
              /></label>
              <label class="form-check form-check-single form-switch radio-label">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="NewPromotion.hot_deal"
                />
              </label>
            </div>
           <div class="mb-4">
              <label class="form-label">
                Category<span class="text-danger">*</span>
              </label>
              <select
                v-model="NewPromotion.category"
                :class="{
                  'is-invalid': submitted && $v.NewPromotion.category.$error,
                }"
                class="form-select"
              >
                <option value="">Select Category</option>
                <option
                  v-for="Category in Categories"
                  v-bind:key="Category.id"
                  v-bind:value="Category.id"
                >
                  {{ Category.category_name }}
                </option>
              </select>
              <div
                v-if="submitted && !$v.NewPromotion.category.required"
                class="invalid-feedback"
              >
                Category is required
              </div>
            </div>
           <div class="mb-4">
              <label class="form-label">Online Promotions</label>
              <span style="float: left">No</span>
              <label
                class="form-check form-check-single form-switch radio-label"
              >
                <input
                  name="online_promotion"
                  class="form-check-input"
                  type="checkbox"
                  v-model="NewPromotion.online_promotion"
                />
              </label>
              <span style="float: left">Yes</span>
              <div style="clear: both"></div>
            </div>
           <div class="mb-4" v-if="NewPromotion.online_promotion == ''">
              <label class="form-label">
                Postcode<span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control text-uppercase"
                placeholder="Enter Postcode"
                v-model="NewPromotion.zipcode"
                :class="{
                  'is-invalid': submitted && $v.NewPromotion.zipcode.$error,
                }"
                maxlength="7"
              />
              <div
                v-if="submitted && !$v.NewPromotion.zipcode.required"
                class="invalid-feedback"
              >
                Postcode is required
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label">
                Term<span class="text-danger">*</span>
              </label>
              <select
                v-model="NewPromotion.term"
                :class="{
                  'is-invalid': submitted && $v.NewPromotion.term.$error,
                }"
                class="form-select"
              >
                <option value="">Select Term</option>
                <option v-for="index in 12" :key="index" v-bind:value="index">
                  {{ index }} Month
                </option>
              </select>
              <div
                v-if="submitted && !$v.NewPromotion.term.required"
                class="invalid-feedback"
              >
                Term is required
              </div>
            </div>
           <div class="mb-4">
              <label class="form-label">
                Start Date<span class="text-danger">*</span>
              </label>
              <input
                type="date"
                class="form-control"
                v-model="NewPromotion.start_date"
                :class="{
                  'is-invalid': submitted && $v.NewPromotion.start_date.$error,
                }"
              />
              <div
                v-if="submitted && !$v.NewPromotion.start_date.required"
                class="invalid-feedback"
              >
                Start date is required
              </div>
            </div>
           <div class="mb-4">
              <label class="form-label">Featured</label>
              <label class="form-check form-check-single form-switch radio-label">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="NewPromotion.is_featured"
                />
              </label>
            </div>
        <div class="text-right">
          <button
            class="btn btn-primary"
             v-on:click="CreateMarketplace()"
            :disabled="Saving"
          >
            Save
          </button>
          <Loading v-if="Saving" />
        </div>
      </form>
</template>
<script>
  import {
    required,
    url,
    email,
    minLength,
    sameAs,
    maxLength,
    requiredIf,
  } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  const file_size_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    return file.size < 1048576;
  };
  const image_type_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    if (
      file.type == "image/svg+xml" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif" ||
      file.type == "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };
  export default {
    props: ["Categories", "Companies"],
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        Saving: false,
        submitted: false,
        NewPromotion: {
          title: "",
          url: "",
          description: "",
          hot_deal: "",
          category: "",
          company_id: "",
          online_promotion: "",
          zipcode: "",
          term: "",
          start_date: "",
          is_featured: "",
          logo_image: "",
        },
      };
    },
    validations: {
      NewPromotion: {
        title: { required },
        url: { required, url },
        description: { required, maxLength: maxLength(70) },
        logo_image: { file_size_validation, image_type_validation },
        company_id: { required },
        category: { required },
        term: { required },
        start_date: { required },
        zipcode: {
          required: requiredIf(function() {
            return !this.NewPromotion.online_promotion;
          }),
        },
      },
    },
    mounted() {
      document.title = " PocketBox Admin - Marketplace Promotion Add";
      this.randomNumber();
    },
    methods: {
      imageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.NewPromotion.logo_image = files[0];
      },
      randomNumber: function() {
        this.NewPromotion.title = "Promotion " + Math.floor(Math.random() * 99);
      },
      CreateMarketplace() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Marketplace.Create, {
          token: localStorage.getItem("token"),
          ...this.NewPromotion,
        }).then((Response) => {
          if (Response.data.success) {
            this.SaveCompanyLogo(Response.data.last_id);
          } else {
            this.Saving = false;
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      SaveCompanyLogo(id) {
        var LogoFormData = new FormData();
        if (this.$refs.logoFile.files[0]) {
          LogoFormData.append("storage_type", "MP");
          LogoFormData.append("token", localStorage.getItem("token"));
          LogoFormData.append("file", this.$refs.logoFile.files[0]);
          LogoFormData.append("company_id", this.NewPromotion.company_id);
          Axios.post(Endpoints.Helper.File.Upload, LogoFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Marketplace.LogoSet, {
                  token: localStorage.getItem("token"),
                  logo: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  this.Saving = false;
                  Swal.fire(
                    "Marketplace Promotion Added Successfully",
                    "",
                    "success"
                  ).then(() => {
                    this.$refs.CreateMarketplaceForm.reset();
                    //reset data
                    setTimeout(() => {
                      var self = this;
                      Object.keys(this.NewPromotion).forEach(function(
                        key,
                        index
                      ) {
                        self.NewPromotion[key] = "";
                      });
                      this.submitted = false;
                    }, 2000);
                    this.$emit("recordAdded");
                  });
                });
              }
            }
          );
        } else {
          Swal.fire(
            "Marketplace Promotion Added Successfully",
            "",
            "success"
          ).then(() => {
            this.Saving = false;
            this.$refs.CreateMarketplaceForm.reset();
            //reset data
            setTimeout(() => {
              var self = this;
              Object.keys(this.NewPromotion).forEach(function(key, index) {
                self.NewPromotion[key] = "";
              });
              this.submitted = false;
            }, 2000);
            this.$emit("recordAdded");
          });
        }
      },
    },
  };
</script>
<style>
  
</style>
