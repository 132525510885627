<template>
  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="table-box">
      <div class="p-4">
        <h4>New Vehicles By Month</h4>
        <p>Vehicles Added To Pocket Box By Month</p>
      </div>
      <div v-if="Loader" class="loading-class"><Loading /></div>
      <canvas
        ref="chart_NewVehiclesByMonth"
        style="width: 100%; height: 400px"
      ></canvas>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        VehicleMonthly: [],
        VehicleTotals: [],
        Loader: false,
      };
    },
    mounted() {
      this.Loader = true;
      axios
        .post(Endpoints.Data.VehiclesFragment, {
          token: localStorage.getItem("token"),
          data: "NewVehiclesByMonth",
        })
        .then((RequestResponse) => {
          if (RequestResponse.data.success) {
            this.VehicleMonthly = RequestResponse.data.data.NewVehiclesByMonth;

            // Get Totals
            var VehicleTotal = 0;
            for (let index in this.VehicleMonthly) {
              VehicleTotal += this.VehicleMonthly[index];
              this.VehicleTotals.push(VehicleTotal);
            }
            this.Loader = false;
            this.$nextTick(() => {
              this.DrawCharts();
            });
          }
        });
    },
    methods: {
      DrawCharts() {
        if (document.body.contains(this.$refs.chart_NewVehiclesByMonth)) {
          var chart_NewVehiclesByMonth2D =
              this.$refs.chart_NewVehiclesByMonth.getContext("2d"),
            gradient = chart_NewVehiclesByMonth2D.createLinearGradient(
              0,
              0,
              0,
              450
            );
          gradient.addColorStop(0, "rgba( 44, 121, 249 , 0.5)");
          gradient.addColorStop(0.5, "rgba( 44, 121, 249 , 0.25)");
          gradient.addColorStop(1, "rgba( 44, 121, 249 , 0)");
          let chart_NewVehiclesByMonth = new Chart(chart_NewVehiclesByMonth2D, {
            type: "line",
            data: {
              labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
              datasets: [
                {
                  label: "Total (for year)",
                  backgroundColor: gradient,
                  pointBackgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "#347ef9",
                  pointRadius: 3,
                  pointHoverRadius: 4,
                  data: this.VehicleMonthly,
                },
              ],
            },
            options: {
              responsive: false,
              maintainAspectRatio: false,
              layout: {
                padding: 20,
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    position: "right",
                    ticks: {
                      display: true,
                      beginAtZero: true,
                      min: 0,
                    },

                    distribution: "linear",
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                  },
                ],
              },
              tooltips: {
                backgroundColor: "#3cb054",
                borderColor: "#3cb054",
                borderWidth: 1,
                borderRadius: 10,
                titleFontColor: "white",
                titleFontStyle: "bold",
                displayColors: false,
                bodyFontColor: "white",
                yAlign: "bottom",
              },
            },
          });
        }
      },
    },
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .loading-class,
  .notify-loading-class {
    display: block;
    text-align: center;
    padding-top: 30px;
  }
</style>
