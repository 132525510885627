var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"VehicleByType",staticClass:"col-sm-6 col-md-6 col-lg-6"},[_c('div',{staticClass:"table-box table-full-height"},[_vm._m(0),_c('div',{staticClass:"three-block-gen-bre p-4 bar-pd"},[_c('div',{staticClass:"row"},[(_vm.VehicleByTypeLoader)?_c('div',{staticClass:"loading-class"},[_c('Loading')],1):_vm._e(),_vm._l((_vm.VehicleByTypes),function(VehicleByType){return _c('div',{key:VehicleByType.index,class:[
            'col typeBoxes',
            VehicleByType.type == 'Car'
              ? 'bg-blue-col'
              : VehicleByType.type == 'Motorcycle'
              ? 'bg-orange-col'
              : VehicleByType.type == 'LGV'
              ? 'bg-yellow-col'
              : VehicleByType.type == 'HGV'
              ? 'bg-voilet-col'
              : VehicleByType.type == 'LCV'
              ? 'bg-info-col'
              : VehicleByType.type.trim() == 'Bicycle'
              ? 'bg-bicycle-col'
              : VehicleByType.type.trim() == 'HCV'
              ? 'bg-hcv-col'
              : VehicleByType.type.trim() == 'Private'
              ? 'bg-private-col'
              : VehicleByType.type.trim() == 'Other'
              ? 'bg-other-col'
              : VehicleByType.type.trim() == 'PSV'
              ? 'bg-psv-col'
              : VehicleByType.type.trim() == 'Saloon'
              ? 'bg-saloon-col'
              : VehicleByType.type.trim() == 'Tricycle'
              ? 'bg-tricycle-col'
              : 'bg-no-image-col' ]},[_c('img',{attrs:{"src":'assets/images/icons/' +
              (VehicleByType.type == 'Car'
                ? 'car.svg'
                : VehicleByType.type == 'Motorcycle'
                ? 'bike.svg'
                : VehicleByType.type == 'LGV'
                ? 'lgv.svg'
                : VehicleByType.type == 'HGV'
                ? 'hgv.svg'
                : VehicleByType.type == 'LCV'
                ? 'lcv.svg'
                : VehicleByType.type.trim() == 'Bicycle'
                ? 'bicycle.svg'
                : VehicleByType.type.trim() == 'HCV'
                ? 'hcv.svg'
                : VehicleByType.type.trim() == 'Private'
                ? 'private.svg'
                : VehicleByType.type.trim() == 'Other'
                ? 'other.svg'
                : VehicleByType.type.trim() == 'PSV'
                ? 'psv.svg'
                : VehicleByType.type.trim() == 'Saloon'
                ? 'saloon.svg'
                : VehicleByType.type.trim() == 'Tricycle'
                ? 'tricycle.svg'
                : 'no-image.svg')}}),_c('p',{staticClass:"mt-4"},[_vm._v(_vm._s(VehicleByType.type))]),_c('p',[_c('b',[_vm._v(_vm._s(VehicleByType.total_percent))])]),_c('p',{staticClass:"mark-num"},[_vm._v(_vm._s(VehicleByType.total))])])})],2)]),_c('div',{staticClass:"chart-container-gender p-2",staticStyle:{"height":"30vh","width":"100%"}},[_c('canvas',{ref:"chart_VehicleByType",staticStyle:{"height":"450px"}})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3"},[_c('div',{staticClass:"col"},[_c('h4',[_vm._v("Vehicle By Type")]),_c('p',[_vm._v("Pocket Box Vehicle Data By Type.")])])])}]

export { render, staticRenderFns }