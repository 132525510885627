import Vue from 'vue'
import Vuelidate from 'vuelidate';
import App from './App.vue'
import router from './router'
import store from './store'
import feather from 'feather-icons';
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

window.feather = feather;
Vue.use(Vuelidate);
Vue.use(VueMaterial);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_KEY,
    libraries: 'places'
  },
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
