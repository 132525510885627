<template>
  <header class="app-header fixed-top">
    <div class="app-header-inner">
      <div class="container-fluid">
        <div class="app-header-content">
          <div class="row justify-content-between align-items-center">
            <div class="d-flex justify-content-end">
              <div class="app-utilities man_name col-auto">
                <div class="app-utility-item">
                  Welcome,
                  <span v-if="profile.foremane"
                    >{{ profile.forename }} {{ profile.surname }}</span
                  >
                  <span v-if="!profile.foremane">{{ profile.email }}</span>
                </div>
                <div class="app-utility-item">
                  <button
                    class="navbar-toggler pull-right mobile-navigation"
                    type="button"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    id="mobileNavigation"
                    @click="showHeaderNavigation()"
                  >
                    <i class="fa fa-bars" v-show="!showHeaderNav"></i>
                    <i class="fa fa-times" v-show="showHeaderNav"></i>
                  </button>
                  <img
                    src="/assets/images/user/avatar.svg"
                    width="50"
                    height="50"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="app-sidepanel"
      class="app-sidepanel sidepanel-hidden"
      v-bind:class="showHeaderNav == true ? 'show-menu' : 'else_class'"
    >
      <div id="sidepanel-drop" class="sidepanel-drop"></div>
      <div class="sidepanel-inner d-flex flex-column">
        <div class="app-branding">
          <a class="app-logo" href="/dashboard">
            <img src="/assets/images/logo-small.png" />
          </a>
        </div>
        <nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
          <ul class="app-menu list-unstyled">
            <li
              v-for="(MenuItem, Key) in MenuItems"
              :key="Key"
              :class="ClassList(Key)"
            >
              <a :class="MenuClassList(Key)">
                <span
                  class="nav-icon"
                  v-on:click="
                    MenuItem.dblink
                      ? redirect(MenuItem.dblink)
                      : redirect(MenuItem.link)
                  "
                >
                  <img
                    :src="['/assets/images/icons/' + MenuItem.icon + '.svg']"
                  />
                </span>
                <span
                  class="nav-link-text"
                  v-on:click="
                    MenuItem.dblink
                      ? redirect(MenuItem.dblink)
                      : redirect(MenuItem.link)
                  "
                  >{{ MenuItem.title }}
                </span>
                <span
                  class="nav-link-arrow"
                  v-if="MenuItem.subMenu"
                  v-on:click="
                    MenuItem.subMenu
                      ? (MenuItem.subMenuVisible = !MenuItem.subMenuVisible)
                      : false
                  "
                >
                  <img
                    src="/assets/images/icons/up-arrow.png"
                    v-if="MenuItem.subMenuVisible"
                  />
                  <img
                    src="/assets/images/icons/down-arrow.png"
                    v-if="!MenuItem.subMenuVisible"
                  />
                </span>
              </a>
              <ul
                v-if="MenuItem.subMenu"
                :class="
                  MenuItem.subMenuVisible
                    ? 'submenu collapse show'
                    : 'submenu collapse'
                "
              >
                <li
                  v-for="(SubMenuItem, subKey) in MenuItem.subMenu"
                  :key="subKey"
                >
                  <a
                    :class="SubmenuClassList(Key, subKey)"
                    :href="SubMenuItem.link"
                  >
                    <span class="nav-icon">
                      <img
                        :src="[
                          '/assets/images/icons/' + SubMenuItem.icon + '.svg',
                        ]"
                    /></span>
                    <span class="nav-link-text">{{ SubMenuItem.title }}</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item" v-on:click="Logout()">
              <a class="nav-link" href="">
                <span class="nav-icon">
                  <img src="/assets/images/icons/account-logout.svg" />
                </span>
                <span class="nav-link-text">Logout</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  export default {
    mounted() {
      this.getProfile();
      this.$nextTick(() => {
        feather.replace();
      });
    },
    data() {
      return {
        showHeaderNav: false,
        MenuItems: [
          {
            title: "Home",
            link: "/dashboard",
            icon: "home",
            active: ["Dashboard"],
          },
          {
            title: "Users",
            link: "javascript:void(0)",
            dblink: "/users",
            icon: "users",
            active: ["General-Users", "CreateUser"],
            subMenuVisible: false,
            subMenu: [
              {
                title: "Add/Edit Users",
                link: "/users/add-user",
                icon: "Icon-user-edit",
                active: ["CreateUser"],
              },
            ],
          },
          {
            title: "Vehicles",
            link: "/vehicles",
            icon: "car-alt",
            active: ["General-Vehicles"],
          },
          {
            title: "Documents",
            link: "/documents",
            icon: "widgets",
            active: ["General-Documents"],
          },
          {
            title: "Analytics",
            link: "/analytics",
            icon: "chart-pie",
            active: ["Analytics"],
          },

          {
            title: "Account Roles",
            link: "javascript:void(0)",
            icon: "settings",
            active: [
              "FleetAccountRoles",
              "AddFleetAccountRole",
              "UpdateFleetAccountRole",
            ],
            subMenuVisible: false,
            subMenu: [
              {
                title: "Fleet Account Roles",
                link: "/fleet-account-roles",
                icon: "category",
                active: ["AccountRoles"],
              },
              {
                title: "Add Fleet Account Role",
                link: "/add-fleet-account-role",
                icon: "add-new-sub",
                active: ["AddAccountRole"],
              },
            ],
          },

          // {
          //   title: "Charts",
          //   link: "/charts",
          //   icon: "chart-pie",
          //   active: ["Charts"],
          // },
          {
            title: "Company Subscriptions",
            link: "javascript:void(0)",
            icon: "business-center",
            active: [
              "Subscriptions",
              "AddSubscription",
              "FleetSubscriptions",
              "AddFleetSubscription",
            ],
            subMenuVisible: false,
            subMenu: [
              {
                title: "Plus Subscriptions",
                link: "/subscriptions",
                icon: "subscription-icon",
                active: ["Subscriptions"],
              },
              {
                title: "Add Plus Subscription",
                link: "/add-subscription",
                icon: "add-new-sub",
                active: ["AddSubscription"],
              },
              {
                title: "Fleet Subscriptions",
                link: "/fleet-subscriptions",
                icon: "Icon-add-company",
                active: ["FleetSubscriptions"],
              },
              {
                title: "Add Fleet Subscription",
                link: "/add-fleet-subscription",
                icon: "Icon-add-company",
                active: ["AddFleetSubscription"],
              },
            ],
          },
          {
            title: "Plus Companies",
            link: "javascript:void(0)",
            dblink: "/companies",
            icon: "business-center",
            active: [
              "Companies",
              "CompanyCreate",
              "CompanyUpdate",
              "CompanyUsers",
              "AddCompanyUsers",
              "AddCompanyFleetUser",
              "CompanyFleetUsers",
              "UpdateCompanyFleetUser",
              "UpdateFleetSubscription",
              "StaffLeaderBoard",
            ],
            subMenuVisible: false,
            subMenu: [
              {
                title: "Add Company",
                link: "/company-create",
                icon: "Icon-add-company",
                active: ["CompanyCreate"],
              },
              {
                title: "Add Company User",
                link: "/company/add-user",
                icon: "Icon-add-company",
                active: ["AddCompanyUsers"],
              },
              {
                title: "Add Company Fleet User",
                link: "/company/add-fleet-user",
                icon: "Icon-add-company",
                active: ["AddCompanyFleetUser"],
              },

              {
                title: " Staff LeaderBoard",
                link: "/staff-leaderboard",
                icon: "Icon-add-company",
                active: ["StaffLeaderBoard"],
              },
            ],
          },
          {
            title: "Plus Promotions",
            link: "javascript:void(0)",
            dblink: "/marketplace-promotions",
            icon: "Icon-promotion",
            active: [
              "MarketplacePromotions",
              "Categories",
              "AddCategory",
              "UpdateCategory",
            ],
            subMenuVisible: false,
            subMenu: [
              {
                title: "Categories",
                link: "/categories",
                icon: "category",
                active: ["Categories"],
              },
              {
                title: "Add New Category",
                link: "/add-category",
                icon: "add-cat",
                active: ["AddCategory"],
              },
            ],
          },
        ],
        delay: 700,
        clicks: 0,
        timer: null,
        profile: {
          email: null,
          forename: null,
          surname: null,
          profile_image: null,
        },
      };
    },
    methods: {
      redirect(url) {
        window.location.href = url;
      },
      getProfile() {
        axios
          .post(Endpoints.Helper.Profile, {
            token: localStorage.getItem("token"),
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              this.profile = RequestResponse.data.data;
            }
          });
      },
      Logout() {
        localStorage.removeItem("token");
        this.$router.push("/");
      },
      ClassList(Key) {
        let Classes = "nav-item ";
        if (this.MenuItems[Key].active.includes(this.$route.name)) {
          Classes += "active";
        }
        if (this.MenuItems[Key].submenu) {
          Classes += "has-submenu";
        }
        return Classes;
      },
      MenuClassList(Key) {
        const hiddenNav = ["Billing", "Notifications"];
        let Classes = "nav-link ";
        if (hiddenNav.includes(this.MenuItems[Key].title)) {
          Classes += "blurDiv";
        }
        return Classes;
      },
      SubmenuClassList(menuKey, SubMenuKey) {
        let Classes = "nav-link ";
        if (
          this.MenuItems[menuKey].subMenu[SubMenuKey].active.includes(
            this.$route.name
          )
        ) {
          Classes += "active";
          this.MenuItems[menuKey].subMenuVisible = true;
        }
        return Classes;
      },
      showHeaderNavigation() {
        if (this.showHeaderNav == false) {
          this.showHeaderNav = true;
        } else {
          this.showHeaderNav = false;
        }
      },
    },
  };
</script>

<style>
  .nav-item.active::after {
    border: 0 solid #963d8f !important;
    border-bottom-width: 2px !important;
  }
  #app-nav-main li .submenu {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
