var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('Nav'),_c('div',{staticClass:"app-wrapper permissions-page"},[_c('div',{staticClass:"app-content pt-3 p-md-3 p-lg-4"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row gy-4"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('h1',{staticClass:"main-title mt-2 mb-4"},[_vm._v("Edit Fleet Account Role")]),_c('div',{staticClass:"panel-box"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-step-col p-5"},[_c('form',{ref:"EditRoleForm",on:{"submit":function($event){$event.preventDefault();return _vm.EditAccountRole.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-lg-12"},[_c('div',{staticClass:"mb-3"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.EditRole.title),expression:"EditRole.title"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.EditRole.title.$error,
                            },attrs:{"type":"text","placeholder":"Enter Title"},domProps:{"value":(_vm.EditRole.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.EditRole, "title", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 role-name"},[_vm._v(" ")]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-check-solid"},[_c('table',[_c('tbody',[[_c('tr',[_c('td',{staticClass:"p-l-0"},[_c('div',{staticClass:"checkbox checkbox-primary select-all-box"},[_c('input',{attrs:{"id":"checkbox-primary-1","type":"checkbox"},on:{"click":function($event){return _vm.selectAllRoles()}}}),_c('label',{attrs:{"for":"checkbox-primary-1 "}},[_vm._v("Select All")])])])])]],2)])])])]),(_vm.Modules.length > 0)?_c('div',{staticClass:"form theme-form roles"},_vm._l((_vm.Modules),function(Module,ModuleIndex){return _c('div',{key:ModuleIndex,staticClass:"row"},[(Module != 'Hierarchy System')?[_c('label',{staticClass:"col-sm-2 role-name"},[_vm._v(_vm._s(Module))]),_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"form-check-solid"},[_c('table',[_c('tbody',[[_c('tr',_vm._l((_vm.EditRole.permissions[Module]),function(Action,ActionIndex){return _c('td',{key:ActionIndex,class:ActionIndex == 'enable'
                                            ? 'main-active-box'
                                            : 'child-actions'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.EditRole.permissions[Module][
                                              ActionIndex
                                            ]
                                          ),expression:"\n                                            EditRole.permissions[Module][\n                                              ActionIndex\n                                            ]\n                                          "}],staticClass:"check_now_enable",attrs:{"type":"checkbox","value":"1"},domProps:{"checked":_vm.selectAllRole,"checked":Array.isArray(
                                            _vm.EditRole.permissions[Module][
                                              ActionIndex
                                            ]
                                          )?_vm._i(
                                            _vm.EditRole.permissions[Module][
                                              ActionIndex
                                            ]
                                          ,"1")>-1:(
                                            _vm.EditRole.permissions[Module][
                                              ActionIndex
                                            ]
                                          )},on:{"change":function($event){var $$a=
                                            _vm.EditRole.permissions[Module][
                                              ActionIndex
                                            ]
                                          ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.EditRole.permissions[Module], 
                                              ActionIndex
                                            , $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.EditRole.permissions[Module], 
                                              ActionIndex
                                            , $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.EditRole.permissions[Module], 
                                              ActionIndex
                                            , $$c)}}}}),_c('label',[_vm._v(_vm._s(ActionIndex.replaceAll("_", " ")))])])}),0)]],2)])])])]:_vm._e()],2)}),0):_vm._e(),_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-default btn-xs",attrs:{"to":{ name: 'FleetAccountRoles' }}},[_vm._v(" ← Back")]),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.Saving},on:{"click":function($event){return _vm.EditAccountRole()}}},[_vm._v(" Save ")]),(_vm.Saving)?_c('Loading'):_vm._e()],1)])])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label"},[_vm._v(" Title"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])}]

export { render, staticRenderFns }