var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('Nav'),_c('div',{staticClass:"app-wrapper"},[_c('div',{staticClass:"app-content pt-3 p-md-3 p-lg-4"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row gy-4"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12"},[_c('h1',{staticClass:"main-title mt-2 mb-4"},[_vm._v("Add New Category")]),_c('div',{staticClass:"panel-box"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-step-col p-5"},[_c('form',{ref:"CreateCategoryForm",on:{"submit":function($event){$event.preventDefault();return _vm.CreateCategory.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-lg-12"},[_c('div',{staticClass:"mb-3"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.NewCategory.category_name),expression:"NewCategory.category_name"}],staticClass:"form-control",class:{
                      'is-invalid':
                        _vm.submitted && _vm.$v.NewCategory.category_name.$error,
                    },attrs:{"type":"text","placeholder":"Enter Category Name"},domProps:{"value":(_vm.NewCategory.category_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.NewCategory, "category_name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.NewCategory.category_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Category title is required ")]):_vm._e()]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Category Icon")]),_c('input',{ref:"iconFile",staticClass:"input form-control",class:{ 'is-invalid': _vm.$v.NewCategory.icon.$error },attrs:{"type":"file","name":"icon","accept":"image/*"},on:{"change":function($event){return _vm.imageFileChanged($event)}}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.NewCategory.icon),expression:"NewCategory.icon"}],ref:"IconPreview",staticClass:"icon__preview",attrs:{"width":"100","height":"100"}}),_c('small',{staticClass:"form-hint"},[_vm._v("Upload a category icon.")]),(
                      _vm.submitted && !_vm.$v.NewCategory.icon.file_size_validation
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please upload less than 1 MB file size image ")]):_vm._e(),(
                      _vm.submitted && !_vm.$v.NewCategory.icon.image_type_validation
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please upload jpeg, png, gif or svg file only ")]):_vm._e()])])]),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.Saving},on:{"click":function($event){return _vm.CreateCategory()}}},[_vm._v(" Save ")]),(_vm.Saving)?_c('Loading'):_vm._e()],1)])])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label"},[_vm._v(" Category Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])}]

export { render, staticRenderFns }