<template>
  <div class="row rowData">
    <div class="col-sm-3 col-md-3 col-lg-3 flex">
      <div class="user-block blue-block p-3">
        <div class="new-users total-users">
          <p class="user-month">Total VEHICLES</p>
          <div v-if="Loader" class="loading-class">
            <Loading />
          </div>
          <p class="arrow-text">
            <img src="assets/images/dashboard/logo-icon.svg" />
            {{ Vehicles.TotalVehicles }}
          </p>
          <p class="per-text">
            {{ Vehicles.VehiclesUpInLast24Hours }}% Up in the last 24 Hours
          </p>
        </div>
        <div class="bar-chart-col chart-col-2">
          <img src="assets/images/dashboard/bar-chart.svg" />
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 flex">
      <div class="user-block green-block p-3">
        <div class="new-users">
          <p class="user-month">VEHICLES WITH NO TAX</p>
          <div v-if="TaxLoader" class="loading-class">
            <Loading />
          </div>
          <p class="arrow-text">
            <img src="assets/images/icons/arrow_upward.svg" />
            {{ VehiclesNoTax.TotalVehiclesWithNoTax }}
          </p>
          <p class="per-text">
            {{ VehiclesNoTax.NoTaxVehiclesUpInLast30Days }}% Up in the last 30
            days
          </p>
        </div>
        <div class="bar-chart-col">
          <img src="assets/images/dashboard/bar-chart.svg" />
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 flex">
      <div class="user-block orange-block p-3">
        <div class="new-users">
          <p class="user-month">VEHICLES WITH NO MOT</p>
          <div v-if="MotLoader" class="loading-class">
            <Loading />
          </div>
          <p class="arrow-text">{{ VehiclesNoMOT.TotalVehiclesWithNoMot }}</p>
          <p class="per-text">
            {{ VehiclesNoMOT.AllVehiclesOfNoMot }}% Of all vehicles have not MOT
          </p>
        </div>
        <div class="bar-chart-col">
          <img src="assets/images/dashboard/bar-chart1.svg" />
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 flex">
      <div class="user-block red-block p-3">
        <div class="new-users">
          <p class="user-month">VEHICLES WITH NO INSURANCE</p>
          <div v-if="InsuranceLoader" class="loading-class">
            <Loading />
          </div>
          <p class="arrow-text">
            <img src="assets/images/icons/user-clock.svg" />
            {{ VehiclesNoInsurance.TotalVehiclesWithNoInsurance }}
          </p>
          <p class="per-text">
            {{ VehiclesNoInsurance.AllVehiclesOfNoInsurance }}% Of all vehicles
            have no Insurance
          </p>
        </div>
        <div class="bar-chart-col">
          <img src="assets/images/dashboard/bar-chart.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        Vehicles: {},
        VehiclesNoTax: {},
        VehiclesNoMOT: {},
        VehiclesNoInsurance: {},
        Loader: false,
        TaxLoader: false,
        MotLoader: false,
        InsuranceLoader: false,
      };
    },
    mounted() {
      this.Loader = true;
      this.TaxLoader = true;
      this.MotLoader = true;
      this.InsuranceLoader = true;
      // data fragment
      var actions = [
        "TotalVehicles",
        "VehicleWithNoTax",
        "VehicleWithNoMot",
        "VehicleWithNoInsurance",
      ];
      var setTime = 2000;
      actions.forEach((action) => {
        setTimeout(() => {
          this.GetVehiclesAnalytics(action);
        }, setTime);
        setTime = parseInt(setTime) + 500;
      });
    },
    methods: {
      GetVehiclesAnalytics(type) {
        axios
          .post(Endpoints.Data.VehiclesFragment, {
            token: localStorage.getItem("token"),
            data: type,
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              this.VehicleUpcomingExpires = RequestResponse.data.data;
              if (type == "TotalVehicles") {
                this.Vehicles = RequestResponse.data.data;
                this.Loader = false;
              } else if (type == "VehicleWithNoTax") {
                this.VehiclesNoTax = RequestResponse.data.data;
                this.TaxLoader = false;
              } else if (type == "VehicleWithNoMot") {
                this.VehiclesNoMOT = RequestResponse.data.data;
                this.MotLoader = false;
              } else if (type == "VehicleWithNoInsurance") {
                this.VehiclesNoInsurance = RequestResponse.data.data;
                this.InsuranceLoader = false;
              }
            }
          });
      },
    },
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .rowData {
    width: 100%;
    padding: 0;
    margin: 0;
  }
</style>
