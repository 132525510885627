<template>
  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="table-box table-full-height">
      <div class="p-4 border-bottom">
        <h4>Popular User Areas</h4>
        <p>Areas with most user downloads</p>
      </div>
      <div class="table-responsive">
        <table class="table" width="100%" border="0">
          <thead>
            <tr>
              <th width="50%">Area/City</th>
              <th width="25%" class="text-center">Users</th>
              <th width="25%" class="text-center">New Users</th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="table-responsive" style="height: 350px">
        <table class="table" width="100%" border="0">
          <tbody>
            <tr
              v-for="PopularUserArea in PopularUserAreas"
              :key="PopularUserArea.index"
            >
              <td width="50%">{{ PopularUserArea.area }}</td>
              <td width="25%" class="text-center">
                {{ PopularUserArea.users }}
              </td>
              <td width="25%" class="text-center">
                {{ PopularUserArea.new_users }}
              </td>
            </tr>
            <tr v-if="Loader">
              <td colspan="5">
                <div class="loading-class">
                  <Loading />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        PopularUserAreas: [],
        Loader: false,
      };
    },
    mounted() {
      this.Loader = true;
      this.GetAnalytics("PopularUserArea");
    },
    methods: {
      GetAnalytics(type) {
        axios
          .post(Endpoints.Data.UsersFragment, {
            token: localStorage.getItem("token"),
            data: type,
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              this.PopularUserAreas =
                RequestResponse.data.data.PopularUserAreas;
              this.Loader = false;
            }
          });
      },
    },
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .rowData {
    width: 100%;
    padding: 0;
    margin: 0;
  }
</style>
