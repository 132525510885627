import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios';
import Endpoints from '@/Admin/Endpoints';
import Swal from 'sweetalert2'

import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Users from '../views/Users/Index.vue'
import CreateUser from '../views/Users/Create.vue'



import Vehicles from '../views/Vehicles.vue'
import Documents from '../views/Documents.vue'
import Charts from '../views/Charts.vue'
import Analytics from '../views/Analytics.vue'

import Marketplaces from '../views/Marketplace/Index.vue'
import MarketplaceCreate from '../views/Marketplace/Create.vue'
import MarketplaceUpdate from '../views/Marketplace/Update.vue'

import Companies from '../views/Company/Index.vue'
import CompanyCreate from '../views/Company/Create.vue'
import CompanyUpdate from '../views/Company/Update.vue'
import StaffLeaderBoard from '../views/Company/StaffLeaderBoard/Index.vue'

import Subscriptions from '../views/Subscriptions/Index.vue'
import AddSubscription from "../views/Subscriptions/Create.vue";
import UpdateSubscription from "../views/Subscriptions/Update.vue";

import Categories from '../views/Categories/Index.vue'
import AddCategory from '../views/Categories/Create.vue'
import UpdateCategory from '../views/Categories/Update.vue'

import CompanyUsers from '../views/Company/User/Index.vue'
import AddCompanyUser from '../views/Company/User/Create.vue'
import UpdateCompanyUser from '../views/Company/User/Update.vue'

import CompanyFleetUsers from '../views/Company/FleetUser/Index.vue'
import AddCompanyFleetUser from '../views/Company/FleetUser/Create.vue'
import UpdateCompanyFleetUser from '../views/Company/FleetUser/Update.vue'

import FleetSubscriptions from '../views/FleetSubscriptions/Index.vue'
import AddFleetSubscription from '../views/FleetSubscriptions/Create.vue'
import UpdateFleetSubscription from '../views/FleetSubscriptions/Update.vue'

import FleetAccountRoles from '../views/FleetAccountRoles/Index.vue'
import AddFleetAccountRole from '../views/FleetAccountRoles/Create.vue'
import UpdateFleetAccountRole from '../views/FleetAccountRoles/Update.vue'

Vue.use(VueRouter)
  
const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: '/users',
        name: 'General-Users',
        component: Users,
        meta: { requiresAuth: true }
    },
    {
        path: '/users/add-user',
        name: 'CreateUser',
        component: CreateUser,
        meta: { requiresAuth: true }
    },
    {
        path: '/vehicles',
        name: 'General-Vehicles', 
        component: Vehicles,
        meta: { requiresAuth: true }
    },
    {
        path: '/documents',
        name: 'General-Documents',
        component: Documents,
        meta: { requiresAuth: true }
    },
    {
        path: '/charts',
        name: 'Charts',
        component: Charts,
        meta: { requiresAuth: true }
    },
    {
        path: '/analytics',
        name: 'Analytics',
        component: Analytics,
        meta: { requiresAuth: true }
    },
    {
        path: '/marketplace-promotions',
        name:'MarketplacePromotions',
        component:Marketplaces,
        meta: { requiresAuth: true }
    },
    {
        path: '/marketplace-promotions/create',
        name:'MarketplacePromotionsCreate',
        component:MarketplaceCreate,
        meta: { requiresAuth: true }
    },
    {
        path:'/marketplace-promotion-edit/:PromotionId',
        name:'MarketplaceUpdate',
        component:MarketplaceUpdate,
        meta: { requiresAuth: true }
    },
    {
        path: '/companies',
        name:'Companies',
        component:Companies,
        meta: { requiresAuth: true }
    },
    {
        path: '/company-create',
        name:'CompanyCreate',
        component:CompanyCreate,
        meta: { requiresAuth: true }
    },
    {
        path: '/company-update/:CompanyId',
        name:'CompanyUpdate',
        component:CompanyUpdate,
        meta: { requiresAuth: true }
    },
    {
        path:'/company/users/:CompanyId',
        name:'CompanyUsers',
        component:CompanyUsers,
        meta: { requiresAuth: true }
    },
    {
        path:'/company/add-user',
        name:'AddCompanyUsers',
        component:AddCompanyUser,
        meta: { requiresAuth: true }
        
    },
    {
        path:'/company/edit-user/:UserId',
        name:'UpdateCompanyUser',
        component:UpdateCompanyUser,
        meta: { requiresAuth: true }
    },
    {
        path:'/company/fleet/users/:CompanyId',
        name:'CompanyFleetUsers',
        component:CompanyFleetUsers,
        meta: { requiresAuth: true }
    },
    {
        path:'/company/add-fleet-user/:CompanyId?',
        name:'AddCompanyFleetUser',
        component:AddCompanyFleetUser,
        meta: { requiresAuth: true }
    },
    {
        path:'/company/fleet/edit-users/:UserId',
        name:'UpdateCompanyFleetUser',
        component:UpdateCompanyFleetUser,
        meta: { requiresAuth: true }
    },
    {
        path: '/subscriptions',
        name:'Subscriptions',
        component:Subscriptions,
        meta: { requiresAuth: true }
    },
    {
        path: '/add-subscription',
        name:'AddSubscription',
        component:AddSubscription,
        meta: { requiresAuth: true }
    },
    {
        path: '/edit-subscription/:SubscriptionId',
        name:'UpdateSubscription',
        component:UpdateSubscription,
        meta: { requiresAuth: true }
    },
    {
        path: '/categories',
        name:'Categories',
        component:Categories,
        meta: { requiresAuth: true }
    },
    {
        path: '/add-category',
        name:'AddCategory',
        component:AddCategory,
        meta: { requiresAuth: true }
    },
    {
        path: '/edit-category/:CategoryId',
        name:'UpdateCategory',
        component:UpdateCategory,
        meta: { requiresAuth: true }
    }, 
    {
        path: '/fleet-subscriptions',
        name:'FleetSubscriptions',
        component:FleetSubscriptions
    },
    {
        path: '/add-fleet-subscription',
        name:'AddFleetSubscription',
        component:AddFleetSubscription
    },
    {
        path: '/edit-fleet-subscription/:SubscriptionId',
        name:'UpdateFleetSubscription',
        component:UpdateFleetSubscription,
        meta: { requiresAuth: true }
    },
    {
        path: '/staff-leaderboard',
        name:'StaffLeaderBoard',
        component:StaffLeaderBoard
    },
    {
        path: '/fleet-account-roles',
        name:'FleetAccountRoles',
        component:FleetAccountRoles,
        meta: { requiresAuth: true }
    },{
        path: '/add-fleet-account-role',
        name:'AddFleetAccountRole',
        component:AddFleetAccountRole,
        meta: { requiresAuth: true }
    },{
        path: '/edit-fleet-account-roles/:RoleId',
        name:'UpdateFleetAccountRole',
        component:UpdateFleetAccountRole,
        meta: { requiresAuth: true }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!localStorage.getItem('token')) {
        next({
          name: "Login",
          query: { redirect: to.path }
        });
      } else {
        axios.post(Endpoints.User.Verify, {token: localStorage.getItem('token')}).then(RequestResponse => {
			next();
		}).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text:'Your session has expired. You need to log in again.',
            })
            next({
                name: "Login",
                query: { redirect: to.path }
           });
           return
          })
      }
    } else {
      next();
    }
  });

export default router
