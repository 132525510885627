<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="table-box">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-6 p-4">
                    <h4>Pocket Box Plus Companies Fleet Users</h4>
                    <p>{{ CompanyName }} - Fleet Users</p>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 p-4">
                    <router-link
                      :to="{ name: 'Companies' }"
                      class="btn btn-primary float-end"
                    >
                      &larr; Back</router-link
                    >
                    <router-link
                      :to="'/company/add-fleet-user/' + CompanyId"
                      class="btn btn-primary float-end"
                    >
                      <img src="/assets/images/icons/Icon-add-company.svg" />
                      Add New User</router-link
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 px-4 mt-3 mb-3">
                    <div class="table-responsive table-color">
                      <table
                        class="table table-vcenter card-table"
                        id="company_users_list"
                      >
                        <thead>
                          <tr>
                            <th>Profile Image</th>
                            <th>Name</th>
                            <th>Email Address</th>
                            <th>User Type</th>
                            <th>Contact Number</th>
                            <th class="text-center">Edit</th>
                          </tr>
                        </thead>
                        <tbody v-if="showLoader">
                          <tr>
                            <td>
                              <Loading />
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr
                            v-for="(FleetUser, index) in FleetUsers"
                            :key="index"
                          >
                            <td>
                              <div class="profile-image">
                                <img
                                  :src="
                                    GetProfileImage(
                                      FleetUser.id,
                                      FleetUser.profile_image
                                    )
                                  "
                                  class="listingIMG"
                                  :title="FleetUser.forename"
                                  :alt="FleetUser.forename"
                                  @error="
                                    $event.target.src = GetProfileImage(
                                      FleetUser.id,
                                      FleetUser.profile_image
                                    )
                                  "
                                />
                              </div>
                            </td>
                            <td>
                              {{ FleetUser.forename }} {{ FleetUser.surname }}
                            </td>
                            <td>{{ FleetUser.email }}</td>
                            <td>
                              {{ FleetUser.user_role }}
                            </td>
                            <td>{{ FleetUser.phone }}</td>
                            <td>
                              <router-link
                                :to="{
                                  name: 'UpdateCompanyFleetUser',
                                  params: { UserId: FleetUser.id },
                                }"
                                class="btn btn-primary btn-xs"
                                title="Edit User"
                                >Edit</router-link
                              >
                              <button
                                class="btn btn-primary btn-xs"
                                v-on:click="UserDelete(FleetUser.id)"
                                style="margin-left: 10px"
                                title="Delete Record"
                              >
                                Delete
                              </button>
                              <button
                                type="button"
                                class="btn btn-primary btn-xs"
                                v-on:click="UpdatePassword(FleetUser.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#updateCompanyFleetUserPassword"
                                title="Update Password"
                                style="margin-left: 10px"
                              >
                                Update Password
                              </button>
                              <button
                              v-if="FleetUser.is_2fa_enabled && FleetUser.google_2fa_secret_key != '' && FleetUser.google_2fa_secret_key != null"
                              class="btn btn-primary btn-xs"
                              @click="Reset2F(FleetUser.id)"
                              style="margin-left: 10px"
                              >Reset 2FA</button
                            >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="modal">
      <updateCompanyFleetUserPassword
        :updatePasswordID="updatePasswordID"
        :key="ComponentKey + 20"
        ref="updateCompanyFleeUserPassword"
      ></updateCompanyFleetUserPassword>
    </div>
  </div>
</template>
<script>
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "jquery/dist/jquery.min.js";
  import $ from "jquery";

  import updateCompanyFleetUserPassword from "@/views/Company/FleetUser/UpdatePassword";
  import Nav from "@/components/Navigation";
  import Axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  export default {
    components: {
      Nav,
      Loading,
      updateCompanyFleetUserPassword,
    },
    data() {
      return {
        showLoader: false,
        CompanyId: 0,
        FleetUsers: {},
        editRec: {},
        updatePasswordID: "",
        CompanyName: "",
        ComponentKey: 0,
      };
    },
    mounted() {
      document.title = "PocketBox Admin - Fleet Users";
      this.CompanyId = this.$route.params.CompanyId;
      this.GetCompanyUsersList();
    },
    methods: {
      forceRerender() {
        this.ComponentKey += 1;
      },
      GetCompanyUsersList() {
        this.showLoader = true;
        Axios.post(Endpoints.Company.FleetUser.List, {
          token: localStorage.getItem("token"),
          company_id: this.CompanyId,
        }).then((Response) => {
          this.FleetUsers = Response.data.records;
          this.CompanyName = Response.data.company_name;
          this.showLoader = false;
          setTimeout(() => {
            $("#company_users_list").DataTable({
              aaSorting: [],
              bDestroy: true,
              language: {
                search: "",
                searchPlaceholder: "Search...",
                paginate: {
                  next: "&#8594;", // or '→'
                  previous: "&#8592;", // or '←'
                },
              },
              oLanguage: {
                sLengthMenu: "Show _MENU_",
              },
              columnDefs: [
                {
                  targets: [6],
                  orderable: false,
                },
              ],
            });
          }, 1000);
        });
      },
      refreshList() {
        this.$router.go();
      },
      GetProfileImage(Id, ProfileImage) {
        if (ProfileImage == "" || ProfileImage == 1 || ProfileImage == 0) {
          return "/assets/images/avatar.jpg";
        } else {
          return (
            Endpoints.Helper.MarketplaceImage +
            Id +
            "/" +
            localStorage.getItem("token") +
            "/fleet-user-profile-image"
          );
        }
      },
      getRecord(id) {
        Axios.post(Endpoints.Company.FleetUser.Get, {
          token: localStorage.getItem("token"),
          id: id,
        }).then((Response) => {
          this.editRec = Response.data.record;
          this.$refs.editCompanyFleetUser.GetProfileImage(
            this.editRec.profile_image
          );
        });
      },
      UpdatePassword(id) {
        this.updatePasswordID = id;
      },
      UserDelete(id) {
        Swal.fire({
          title: "Are you sure to delete this Fleet User?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Deleting...",
              text: "Please wait...",
              imageUrl: require("../../../assets/images/LoadingTwo.gif"),
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            Axios.post(Endpoints.Company.FleetUser.Remove, {
              token: localStorage.getItem("token"),
              id: id,
            }).then((Response) => {
              if (Response.data.success == true) {
                Swal.fire(
                  "Fleet User Deleted Successfully",
                  "",
                  "success"
                ).then(() => {
                  this.refreshList();
                });
              } else {
                Swal.fire({
                  title: "Oops!",
                  html: Response.data.message,
                  icon: "error",
                  confirmButtonText: "Close",
                });
              }
            });
          }
        });
      },
      Reset2F(id) {
        Swal.fire({
          title: "Are you sure You want to reset 2FA?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, reset it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Deleting...",
              text: "Please wait...",
              imageUrl: require("../../../assets/images/LoadingTwo.gif"),
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            Axios.post(Endpoints.User.Reset2FA, {
              token: localStorage.getItem("token"),
              id: id,
            }).then((Response) => {
              if (Response.data.success === true) {
                Swal.fire(
                  "User 2FA Reset Successfully",
                  "",
                  "success"
                ).then(() => {
                  this.refreshList();
                });
              } else {
                  Swal.fire({
                  title: "Oops!",
                  html: Response.data.message,
                  icon: "error",
                  confirmButtonText: "Close",
                });
              }
            });
          }
        });
      },
    },
  };
</script>
<style>
  .card-title p {
    font-size: 14px;
    color: #6d6d6d;
    margin-bottom: 0;
  }
  .dataTables_filter input {
    margin-bottom: 10px;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 3px 10px !important;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current,
  .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
  .paginate_button:hover,
  .paginate_button:focus {
    border: 1px solid #dadada !important;
  }
  h2.page-title {
    padding: 15px 0;
  }
  .float-end {
    margin-left: 10px;
  }
  .profile-image img {
    max-width: 50px;
    max-height: 50px;
    vertical-align: middle;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .profile-image {
    position: relative;
    text-align: center;
    width: 60px;
    padding: 5px;
    height: 60px;
    background: #ccc;
  }
</style>
