<template>
  <div class="page">
    <Navigation />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4" style="margin-top: -4px">
            <UserTopSections :key="0" ref="UserTopSections"></UserTopSections>
          </div>
          <div class="row mt-4">
            <UserTopBottomSections
              :key="1"
              ref="UserTopBottomSections"
            ></UserTopBottomSections>
          </div>
          <div class="row mt-4">
            <UKMapSection :key="2" ref="UKMapSection"></UKMapSection>
            <PopularAreasSection
              :key="3"
              ref="PopularAreasSection"
            ></PopularAreasSection>
          </div>
          <div class="row mt-4">
            <UserAgeBreakdownSection
              :key="3"
              ref="PopularAreasSection"
            ></UserAgeBreakdownSection>
            <UserGenderBreakdownSection
              :key="12"
              ref="UserGenderBreakdownSection"
            ></UserGenderBreakdownSection>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Navigation from "@/components/Navigation";
  import UserTopSections from "@/views/Users/Sections/UserTopSections";
  import UserTopBottomSections from "@/views/Users/Sections/UserTopBottomSections";
  import UKMapSection from "@/views/Users/Sections/UKMapSection";
  import PopularAreasSection from "@/views/Users/Sections/PopularAreasSection";
  import UserAgeBreakdownSection from "@/views/Users/Sections/UserAgeBreakdownSection";
  import UserGenderBreakdownSection from "@/views/Users/Sections/UserGenderBreakdownSection";

  export default {
    components: {
      Navigation,
      UserTopSections,
      UserTopBottomSections,
      UKMapSection,
      PopularAreasSection,
      UserAgeBreakdownSection,
      UserGenderBreakdownSection,
    },
    mounted() {
      document.title = "PocketBox Admin - Users";
      /*Import External JS*/
      let PieceLabel = document.createElement("script");
      PieceLabel.setAttribute(
        "src",
        "https://rawgit.com/beaver71/Chart.PieceLabel.js/master/build/Chart.PieceLabel.min.js"
      );
      document.head.appendChild(PieceLabel);
    },
    methods: {},
  };
</script>
