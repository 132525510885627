<template>
  <div
    class="modal fade"
    id="qrcodeModal"
    ref="companyQRCode"
    tabindex="-1"
    role="dialog"
    aria-labelledby="companyQRCodeTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="companyQRCodeTitle">{{ PageTitle }}</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            ref="closeModal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <img :src="QRCodeEndpoint" style="max-height: 200px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["QRCodeEndpoint", "PageTitle"],
  };
</script>
