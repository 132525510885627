<template>
<div class="page">
	<Navigation />
   <div class="content">
      <div class="container-xl">
         <div class="page-header d-print-none">
            <div class="row align-items-center">
               <div class="col">
                  <h2 class="page-title">Data Charts</h2>
               </div>
            </div>
         </div>
		 <div class="row row-deck row-cards">
			<!-- Brand Loyalty & Overview -->
			<div class="col-sm-12 col-lg-12">
				<div class="card">
					<div class="card-header">
						<div class="card-title">Overview</div>
					</div>
					<div class="card-body">
						Your linked users favour <b>{{OverviewData.Popular}}</b> over other brands with <b>{{OverviewData.PopularCount}}</b> your users vehicles being from this brand.
					</div>
				</div>
			</div>
			<!-- Makes -->
			<div class="col-sm-12 col-lg-12">
				<div class="card">
					<div class="card-header">
						<div class="card-title">Vehicle Makes</div>
					</div>
					<div class="card-body">
						<canvas ref="chart_VehicleMakes" style="width:100%; height:200px;"></canvas>
					</div>
				</div>
			</div>
			<!-- Popular Models -->
			<div class="col-sm-12 col-lg-12" v-if="PopularModels.length <= 3">
				<div class="card">
					<div class="card-header">
						<div class="card-title">Popular Vehicle Models</div>
					</div>
					<div class="card-body">
						<div class="row row-deck row-cards">
							<!-- Pop Model Images -->
							<div class="col-sm-6 col-lg-4" v-for="(Model, Index) in PopularModels" :key="Index">
								<div class="card">
									<div class="card-body">
										<div style="min-height: 100px; display: flex; justify-content: center; align-items: center;">
											<img :src="GetVehicleImage(Model.vrm)" style="width: 100%; height: 90px; object-fit: contain !important;">
										</div>
										<div style="width:100%; text-align:center;">
											{{Model.make}} {{Model.model}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Gender -->
			<div class="col-sm-6 col-lg-4">
				<div class="card">
					<div class="card-header">
						<div class="card-title">Gender Breakdown</div>
					</div>
					<div class="card-body">
						<canvas ref="chart_Gender" style="width:100%; height:200px;"></canvas>
					</div>
				</div>
			</div>
			<!-- Age Groups -->
			<div class="col-sm-6 col-lg-4">
				<div class="card">
					<div class="card-header">
						<div class="card-title">User Age Groups</div>
					</div>
					<div class="card-body">
						<canvas ref="chart_UserAgeGroups" style="width:100%; height:200px;"></canvas>
					</div>
				</div>
			</div>
			<!-- Vehicle Age Groups -->
			<div class="col-sm-6 col-lg-4">
				<div class="card">
					<div class="card-header">
						<div class="card-title">Vehicle Age Groups</div>
					</div>
					<div class="card-body">
						<canvas ref="chart_VehicleAgeGroups" style="width:100%; height:200px;"></canvas>
					</div>
				</div>
			</div>
			<!-- Vehicle Body Types -->
			<div class="col-sm-6 col-lg-4">
				<div class="card">
					<div class="card-header">
						<div class="card-title">Vehicle Body Types</div>
					</div>
					<div class="card-body">
						<canvas ref="chart_VehicleBodyTypes" style="width:100%; height:200px;"></canvas>
					</div>
				</div>
			</div>
			<!-- Upcoming MOTs -->
			<div class="col-sm-6 col-lg-4">
				<div class="card">
					<div class="card-header">
						<div class="card-title">Upcoming MOTs</div>
					</div>
					<div class="card-body">
						<canvas ref="chart_UpcomingMOT" style="width:100%; height:200px;"></canvas>
					</div>
				</div>
			</div>
			<!-- Vehicle Ownership -->
			<!-- <div class="col-sm-6 col-lg-4">
				<div class="card">
					<div class="card-header">
						<div class="card-title">Vehicle Ownership</div>
					</div>
					<div class="card-body">
						<canvas ref="chart_vehicleOwnership" style="width:100%; height:200px;"></canvas>
					</div>
				</div>
			</div> -->
			<!-- Vehicles By Area -->
			<div class="col-sm-6 col-lg-12">
				<div class="card">
					<div class="card-header">
						<div class="card-title">Vehicles By Area <span style="font-size:9px;">(Only valid for users who have completed their profile)</span></div>
					</div>
					<div class="card-body">
						<div class="row row-deck row-cards">
							<div class="col-lg-4 col-sm-4" v-for="(Items, Index) in VehiclesByArea" :key="Index" @v-if="Items.length">
								<div class="card" style="max-height:200px; height:200px; overflow-y:auto;">
									<div class="card-header">
										<div class="card-title">{{Index}}</div>
									</div>
									<table class="table card-table table-vcenter">
										<thead>
											<tr>
												<th>Make</th>
												<th colspan="2">Total</th>
											</tr>
										</thead>
										<tbody>
										<tr v-for="(ItemsCount, ItemsIndex) in Items" :key="ItemsIndex">
											<td>{{ItemsCount.label}}</td>
											<td>{{ItemsCount.count}}</td>
											<!-- <td class="w-50">
												<progress id="file" :value="ItemsCount.count" :max="GetMaxVehicleCountInSet(Items)" style="width:100%;"></progress>
											</td> -->
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		 </div>
	  </div>
   </div>
</div>
</template>

<script>
import Chart from 'chart.js';
import axios from 'axios';
import Navigation from '@/components/Navigation';
import Endpoints from '@/Admin/Endpoints';
const {ucwords} = require('@/Utils/MooseTheCoder/PHPJSLangFunctions/LanguageFunctions.js');
import ChartOptions from '@/Admin/ChartOptions';
export default {
	components:{
		Navigation
	},
	data(){
		return {
			Colors : [
				'#f72585','#B5179E','#7209B7','#560BAD','#480CA8',
				'#3A0CA3','#3F37C9','#4361EE','#4895EF','#6DAAF2',
				'#f72585','#B5179E','#7209B7','#560BAD','#480CA8',
				'#3A0CA3','#3F37C9','#4361EE','#4895EF','#6DAAF2',
				'#f72585','#B5179E','#7209B7','#560BAD','#480CA8',
				'#3A0CA3','#3F37C9','#4361EE','#4895EF','#6DAAF2',
				'#f72585','#B5179E','#7209B7','#560BAD','#480CA8',
				'#3A0CA3','#3F37C9','#4361EE','#4895EF','#6DAAF2',
				'#f72585','#B5179E','#7209B7','#560BAD','#480CA8',
				'#3A0CA3','#3F37C9','#4361EE','#4895EF','#6DAAF2',
				'#f72585','#B5179E','#7209B7','#560BAD','#480CA8',
				'#3A0CA3','#3F37C9','#4361EE','#4895EF','#6DAAF2',
				'#f72585','#B5179E','#7209B7','#560BAD','#480CA8',
				'#3A0CA3','#3F37C9','#4361EE','#4895EF','#6DAAF2',
				'#f72585','#B5179E','#7209B7','#560BAD','#480CA8',
				'#3A0CA3','#3F37C9','#4361EE','#4895EF','#6DAAF2',
				'#f72585','#B5179E','#7209B7','#560BAD','#480CA8',
				'#3A0CA3','#3F37C9','#4361EE','#4895EF','#6DAAF2',
				
			],
			VehicleMakes : {
				Labels : [],
				Data : [],
				Colors : []
			},
			OverviewData:{
				Popular:'Nobody',
				PopularCount:0
			},
			PopularModels : [],
			Gender : [],
			UserAgeGroups : {
				Labels : [],
				Data : []
			},
			VehicleAgeGroups : {
				Labels : [],
				Data : []
			},
			VehicleBodyTypes : {
				Labels : [],
				Data : []
			},
			UpcomingMOT:{
				Labels : [],
				Data :[]
			},
			VehicleCounts:{
				Labels:[],
				Data:[],
			},
			VehiclesByArea : []
		}
	},
	mounted(){
		document.title = "PocketBox Admin - Charts"
		axios.post(Endpoints.Data.Charts, {token: localStorage.getItem('token')}).then(RequestResponse => {
			if(RequestResponse.data.success){
				RequestResponse.data.data.Makes.forEach(Make => {
					this.VehicleMakes.Labels.push(Make.label)
					this.VehicleMakes.Data.push(Make.count)
					this.VehicleMakes.Colors.push(Make.color)
				});

				this.OverviewData.Popular = this.VehicleMakes.Labels[this.VehicleMakes.Data.indexOf(Math.max(...this.VehicleMakes.Data))]
				this.OverviewData.PopularCount = Math.max(...this.VehicleMakes.Data);
				this.OverviewData.Popular = this.OverviewData.Popular[0].toUpperCase() + this.OverviewData.Popular.slice(1).toLowerCase();

				this.PopularModels = RequestResponse.data.data.PopularModels;
				this.Gender = RequestResponse.data.data.Genders;

				for(let AgeGroup in RequestResponse.data.data.UserAgeGroups){
					if(AgeGroup != 'Unspecified'){
						this.UserAgeGroups.Labels.push(AgeGroup+' y/o');
						this.UserAgeGroups.Data.push(RequestResponse.data.data.UserAgeGroups[AgeGroup]);
					}
				}

				for(let VAgeGroup in RequestResponse.data.data.VehicleAgeGroups){
					this.VehicleAgeGroups.Labels.push(VAgeGroup+' Years');
					this.VehicleAgeGroups.Data.push(RequestResponse.data.data.VehicleAgeGroups[VAgeGroup]);
				}

				for(let VBType in RequestResponse.data.data.VehicleBodyTypes){
					if(VBType !== ''){
						this.VehicleBodyTypes.Labels.push(VBType);
						this.VehicleBodyTypes.Data.push(RequestResponse.data.data.VehicleBodyTypes[VBType]);
					}
				}

				for(let UCMOT in RequestResponse.data.data.UpcomingMOT){
					this.UpcomingMOT.Labels.push(UCMOT+(UCMOT !== 'Expired' ? ' Days' : ''));
					this.UpcomingMOT.Data.push(RequestResponse.data.data.UpcomingMOT[UCMOT]);
				}

				// for(let VCount in RequestResponse.data.data.VehicleCounts){
				// 	this.VehicleCounts.Labels.push(VCount);
				// 	this.VehicleCounts.Data.push(RequestResponse.data.data.VehicleCounts[VCount]);
				// }

				this.VehiclesByArea = RequestResponse.data.data.VehiclesByArea;

				console.log(RequestResponse.data.data);
				this.DrawCharts();
			}
		});
	},
	methods : {
		GetMaxVehicleCountInSet(Dataset){
			return Dataset.sort( 
				function(a, b) {
					return parseFloat(b['count']) - parseFloat(a['count']);
			})[0]['count']
		},
		GetVehicleImage(Vrm){
			return Endpoints.Helper.VehicleImage+Vrm;
		},
		DrawCharts(){
			// Vehicle Makes
			let chart_VehicleMakes = new Chart(this.$refs.chart_VehicleMakes.getContext('2d'), {
				type: 'bar',
				data: {
					labels : this.VehicleMakes.Labels,
					datasets: [{
						label :'Make',
						data : this.VehicleMakes.Data,
						backgroundColor :this.VehicleMakes.Colors
					}]
				},
				options: {
					legend : {
						display : false
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero:true,
								precision: 0
							},
						}]
					}
				}
			})
			// Genders
			let chart_Gender = new Chart(this.$refs.chart_Gender.getContext('2d'), {
				type: 'bar',
				data: {
					labels : ['Male','Female','Other'],
					datasets: [{
						label:'Gender',
						backgroundColor:['#3498db','#fd79a8','#ffeaa7'],
						data: [this.Gender.Male,this.Gender.Female,this.Gender.Other]
					}],
				},
				options:{
					legend : {
						display : false
					},
					scales: {
						yAxes: [{
							ticks: {
								precision: 0
							}
						}]
					}
				}
			})
			// User Age Groups
			let chart_UserAgeGroups = new Chart(this.$refs.chart_UserAgeGroups.getContext('2d'), {
				type: 'doughnut',
				data: {
					labels : [...this.UserAgeGroups.Labels],
					datasets: [{
						label:'Gender',
						backgroundColor: this.Colors,
						data: [...this.UserAgeGroups.Data]
					}],
				},
				options:{
					legend : {
						display : true,
						position:'bottom',
						align:'start'
					},
				}
			})
			// Vehicle Age Groups
			let chart_VehicleAgeGroups = new Chart(this.$refs.chart_VehicleAgeGroups.getContext('2d'), {
				type: 'bar',
				data: {
					labels : [...this.VehicleAgeGroups.Labels],
					datasets: [{
						label:'Age Group',
						backgroundColor: this.Colors,
						data: [...this.VehicleAgeGroups.Data]
					}],
				},
				options:{
					legend : {
						display : false
					},
					scales: {
						yAxes: [{
							ticks: {
								precision: 0,
								beginAtZero: true
							}
						}]
					}
				}
			})
			// Vehicle Body Types
			let chart_VehicleBodyTypes = new Chart(this.$refs.chart_VehicleBodyTypes.getContext('2d'), {
				type: 'bar',
				data: {
					labels : [...this.VehicleBodyTypes.Labels],
					datasets: [{
							label:'Body Type',
							backgroundColor: this.Colors,
							data: [...this.VehicleBodyTypes.Data]
						}
					],
				},
				options:{
					legend : {
						display : false,
						position:'bottom',
						align:'start'
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero:true,
								precision: 0
							},
						}]
					}
				}
			})
			// Upcoming MOT
			let chart_UpcomingMOT = new Chart(this.$refs.chart_UpcomingMOT.getContext('2d'), {
				type: 'bar',
				data: {
					labels : [...this.UpcomingMOT.Labels],
					datasets: [{
						backgroundColor: this.Colors,
						data: [...this.UpcomingMOT.Data]
					}],
				},
				options:{
					legend : {
						display : false,
						position:'bottom',
						align:'start'
					},
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero:true,
								precision: 0
							},
						}]
					}
				}
			})
			// let chart_vehicleOwnership = new Chart(this.$refs.chart_vehicleOwnership.getContext('2d'), {
			// 	type: 'polarArea',
			// 	data: {
			// 		labels : [...this.VehicleCounts.Labels],
			// 		datasets: [{
			// 			backgroundColor: this.Colors,
			// 			data: [...this.VehicleCounts.Data]
			// 		}],
			// 	},
			// 	options:{
			// 		legend : {
			// 			display : true,
			// 			position:'bottom',
			// 			align:'start'
			// 		},
			// 	}
			// })
		}
	}
}
</script>

<style>

</style>