<template>
  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="table-box userGender">
      <div class="p-4 border-bottom">
        <div class="col">
          <h4>User Gender Breakdown</h4>
          <p>Areas with most user downloads</p>
        </div>
      </div>
      <div v-if="Loader" class="loading-class">
        <Loading />
      </div>
      <div
        class="three-block-gen-bre row p-4 bar-pd"
        v-if="this.UserGendersBreakdown"
      >
        <div class="col bg-blue-col">
          <img src="assets/images/dashboard/male.svg" />
          <p class="mt-4">Male</p>
          <p>
            <b>{{ this.UserGendersBreakdown["Male"][1] }}</b>
          </p>
          <p class="mark-num">
            {{ this.UserGendersBreakdown["Male"][0] }}
          </p>
        </div>
        <div class="col text-center bg-orange-col">
          <img src="assets/images/dashboard/female.svg" />
          <p class="mt-4">Female</p>
          <p>
            <b>{{ this.UserGendersBreakdown["Female"][1] }}</b>
          </p>
          <p class="mark-num">
            {{ this.UserGendersBreakdown["Female"][0] }}
          </p>
        </div>
        <div class="col text-end bg-yellow-col">
          <img src="assets/images/dashboard/na.svg" />
          <p class="mt-4">N/A</p>
          <p>
            <b>{{ this.UserGendersBreakdown["N/A"][1] }}</b>
          </p>
          <p class="mark-num">
            {{ this.UserGendersBreakdown["N/A"][0] }}
          </p>
        </div>
      </div>
      <div class="chart-container-gender p-2" style="height: 30vh; width: 100%">
        <canvas ref="chart_Gender" style="height: 450px"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Chart from "chart.js";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        UserGendersBreakdown: {
          Male: [],
          Female: [],
          "N/A": [],
        },
        Loader: false,
      };
    },
    mounted() {
      this.Loader = true;
      this.GetAnalytics("UserGenderBreakdown");
    },
    methods: {
      GetAnalytics(type) {
        axios
          .post(Endpoints.Data.UsersFragment, {
            token: localStorage.getItem("token"),
            data: type,
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              /*Get Gender BreakDown By Age*/
              this.UserGendersBreakdown =
                RequestResponse.data.data.UserGendersBreakdown;
              this.Loader = false;
              this.DrawCharts();
            }
          });
      },
      DrawCharts() {
        // Gender Chart
        if (document.body.contains(this.$refs.chart_Gender)) {
          var stackedBar = new Chart(this.$refs.chart_Gender.getContext("2d"), {
            type: "horizontalBar",
            data: {
              datasets: [
                {
                  data: [this.UserGendersBreakdown.Male[0]],
                  backgroundColor: ["#4c8bfd"],
                  borderColor: ["#4c8bfd"],
                  borderWidth: 1,
                  barThickness: 40,
                  maxBarThickness: 40,
                },
                {
                  data: [this.UserGendersBreakdown.Female[0]],
                  backgroundColor: ["#f37137"],
                  borderColor: ["#f37137"],
                  borderWidth: 1,
                  barThickness: 40,
                  maxBarThickness: 40,
                },
                {
                  data: [this.UserGendersBreakdown["N/A"][0]],
                  backgroundColor: ["#f49d37"],
                  borderColor: ["#f49d37"],
                  borderWidth: 1,
                  barThickness: 40,
                  maxBarThickness: 40,
                },
                // {
                // 	data: [
                // 		this.Gender.Unspecified
                // 	],
                // 	backgroundColor: ['#f49d37'],
                // 	borderColor: ['#f49d37'],
                // 	borderWidth: 1,
                // 	barThickness: 40,
                // 	maxBarThickness: 40
                // }
              ],
            },
            options: {
              responsive: false,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: false,
                    },
                    ticks: {
                      display: false, //this will remove only the label
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: false,
                    },
                    ticks: {
                      display: false, //this will remove only the label
                    },
                  },
                ],
              },
            },
          });
        }
      },
    },
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .rowData {
    width: 100%;
    padding: 0;
    margin: 0;
  }
</style>
