<template>
  <div class="page">
  <Nav />
  <div class="app-wrapper">
  <div class="app-content pt-3 p-md-3 p-lg-4">
    <div class="container-fluid">
      <div class="row gy-4">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <h1 class="main-title mt-2 mb-4">Add New Category</h1>
          <div class="panel-box">
            <div class="row">
              <div class="form-step-col p-5">
                <form ref="CreateCategoryForm" @submit.prevent="CreateCategory">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="mb-3">
                  <label class="form-label">
                    Category Name<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Category Name"
                    v-model="NewCategory.category_name"
                    :class="{
                      'is-invalid':
                        submitted && $v.NewCategory.category_name.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.NewCategory.category_name.required"
                    class="invalid-feedback"
                  >
                    Category title is required
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Category Icon</label>
                  <input
                    class="input form-control"
                    type="file"
                    name="icon"
                    ref="iconFile"
                    :class="{ 'is-invalid': $v.NewCategory.icon.$error }"
                    @change="imageFileChanged($event)"
                    accept="image/*"
                  />
                   <img
                      v-show="NewCategory.icon"
                      ref="IconPreview"
                      class="icon__preview"
                      width="100"
                      height="100"
                    />
                  <small class="form-hint">Upload a category icon.</small>
                  <div
                    v-if="
                      submitted && !$v.NewCategory.icon.file_size_validation
                    "
                    class="invalid-feedback"
                  >
                    Please upload less than 1 MB file size image
                  </div>
                  <div
                    v-if="
                      submitted && !$v.NewCategory.icon.image_type_validation
                    "
                    class="invalid-feedback"
                  >
                    Please upload jpeg, png, gif or svg file only
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right">
              <button
                class="btn btn-primary"
                 v-on:click="CreateCategory()"
                :disabled="Saving"
              >
                Save
              </button>
              <Loading v-if="Saving" />
            </div>
          </form>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
  import { required } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  const file_size_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    return file.size < 1048576;
  };
  const image_type_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    if (
      file.type == "image/svg+xml" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif" ||
      file.type == "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };
  export default {
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        Saving: false,
        submitted: false,
        NewCategory: {
          category_name: "",
          icon: "",
        },
      };
    },
    validations: {
      NewCategory: {
        category_name: { required },
        icon: { file_size_validation, image_type_validation },
      },
    },
    methods: {
      imageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.NewCategory.icon = files[0];
        var output = document.querySelector('.icon__preview');
        output.src = URL.createObjectURL(files[0]);
        output.onload = function() {
          URL.revokeObjectURL(output.src) // free memory
        }
      },
      CreateCategory() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Category.Create, {
          token: localStorage.getItem("token"),
          ...this.NewCategory,
        }).then((Response) => {
          if (Response.data.success) {
            this.Saving = false;
            this.SaveIcon(Response.data.last_id);
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      SaveIcon(id) {
        var LogoFormData = new FormData();
        if (this.$refs.iconFile.files[0]) {
          LogoFormData.append("storage_type", "MC");
          LogoFormData.append("token", localStorage.getItem("token"));
          LogoFormData.append("file", this.$refs.iconFile.files[0]);
          Axios.post(Endpoints.Helper.File.Upload, LogoFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Category.setIcon, {
                  token: localStorage.getItem("token"),
                  logo: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  Swal.fire(
                    "Category Added Successfully",
                    "",
                    "success"
                  ).then(() => {
                    this.$router.push("/categories");
                  });
                });
              }
            }
          );
        } else {
          Swal.fire("Category Added Successfully", "", "success").then(() => {
            this.Saving = false;
            this.$router.push("/categories");
          });
        }
      },
      clearModal() {
        this.$refs.CreateCategoryForm.reset();
        var self = this;
        Object.keys(this.NewCategory).forEach(function (categoryKey, index) {
          self.NewCategory[categoryKey] = "";
        });
        this.submitted = false;
      },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
</style>
