<template>
  <div class="page">
  <Nav />
  <div class="app-wrapper">
  <div class="app-content pt-3 p-md-3 p-lg-4">
    <div class="container-fluid">
      <div class="row gy-4">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <h1 class="main-title mt-2 mb-4">Update Category</h1>
          <div class="panel-box">
            <div class="row">
              <div class="form-step-col p-5">
               <form ref="EditCategoryForm" @submit.prevent="EditCategory">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="mb-3">
                  <label class="form-label">
                    Category Name<span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Category Name"
                    v-model="Update_Category.category_name"
                    :class="{
                      'is-invalid':
                        submitted && $v.Update_Category.category_name.$error,
                    }"
                  />
                  <div
                    v-if="
                      submitted && !$v.Update_Category.category_name.required
                    "
                    class="invalid-feedback"
                  >
                    Category Name is required
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Category Icon</label>
                  <input
                    class="input form-control"
                    type="file"
                    name="icon"
                    ref="iconFile"
                    :class="{ 'is-invalid': $v.Update_Category.icon.$error }"
                    @change="imageFileChanged($event)"
                    accept="image/*"
                  />
                  <small class="form-hint">Upload a category icon.</small>
                  <div
                    v-if="
                      submitted && !$v.Update_Category.icon.file_size_validation
                    "
                    class="invalid-feedback"
                  >
                    Please upload less than 1 MB file size image
                  </div>
                  <div
                    v-if="
                      submitted &&
                      !$v.Update_Category.icon.image_type_validation
                    "
                    class="invalid-feedback"
                  >
                    Please upload jpeg, png, gif or svg file only
                  </div>
                  <div class="categoryIcon" v-show="Update_Category.icon">
                    <img
                        :src="GetCategoryIcon(Update_Category.id)"
                        class="icon__preview"
                          ref="IconPreview"
                        :title="Update_Category.category_name"
                        :alt="Update_Category.category_name"
                        @error="
                          $event.target.src = GetCategoryIcon(
                            Update_Category.id
                          )
                        "
                        width="100"
                      />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right">
              <button
                class="btn btn-primary"
                 v-on:click="EditCategory()"
                :disabled="Saving"
              >
                Save
              </button>
              <Loading v-if="Saving" />
            </div>
          </form>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
  import { required } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  const file_size_validation = (value, vm) => {
    if (!value || !value.size) {
      return true;
    }
    let file = value;
    return file.size < 1048576;
  };
  const image_type_validation = (value, vm) => {
    if (!value || !value.size) {
      return true;
    }
    let file = value;
    if (
      file.type == "image/svg+xml" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif" ||
      file.type == "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };
  export default {
   // props: ["Update_Category"],
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        CategoryId:0,
        Saving: false,
        submitted: false,
        Update_Category: {
        category_name: "",
        icon: "",
      }
      };
    },
    validations: {
      Update_Category: {
        category_name: { required },
        icon: { file_size_validation, image_type_validation },
      },
    },
    mounted(){
      this.CategoryId = this.$route.params.CategoryId;
      this.getCategoryIdData()
    },
    methods: {
      getCategoryIdData() {
        Axios.post(Endpoints.Category.Get, {
          token: localStorage.getItem("token"),
          id: this.CategoryId,
        }).then((Response) => {
          if (typeof Response.data.record != "undefined") {
            this.Update_Category = Response.data.record;

          }
        });
      },
      imageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.Update_Category.icon = files[0];
        var output = document.querySelector('.icon__preview');
        output.src = URL.createObjectURL(files[0]);
        output.onload = function() {
          URL.revokeObjectURL(output.src) // free memory
        }
      },
      EditCategory() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Category.Update, {
          token: localStorage.getItem("token"),
          ...this.Update_Category,
        }).then((Response) => {
          if (Response.data.success) {
            this.SaveIcon(Response.data.last_id);
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      GetCategoryLogo(icon) {
        Axios.post(Endpoints.Helper.File.Get, {
          token: localStorage.getItem("token"),
          file: icon,
        }).then((FileGetResponse) => {
          var FileData = FileGetResponse.data;
          if (FileData.success === true) {
            this.CategoryIcon = FileData.file;
            this.$refs.IconPreview.src = FileData.file;
          }
        });
      },
      SaveIcon(id) {
        var LogoFormData = new FormData();
        if (this.$refs.iconFile.files[0]) {
          LogoFormData.append("token", localStorage.getItem("token"));
          LogoFormData.append("file", this.$refs.iconFile.files[0]);
          LogoFormData.append("storage_type", "MC");
          Axios.post(Endpoints.Helper.File.Upload, LogoFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Category.setIcon, {
                  token: localStorage.getItem("token"),
                  logo: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  Swal.fire(
                    "Category Updated Successfully",
                    "",
                    "success"
                  ).then(() => {
                     this.$router.push("/categories");
                    this.$emit("recordUpdated");
                    this.Saving = false;
                  });
                });
              }
            }
          );
        } else {
          Swal.fire("Category Updated Successfully", "", "success").then(() => {
            this.$router.push("/categories");
            this.$emit("recordUpdated");
            this.Saving = false;
          });
        }
      },
      clearModal() {
        this.$refs.EditCategoryForm.reset();
        var self = this;
        Object.keys(this.Update_Category).forEach(function (key, index) {
          self.Update_Category[key] = "";
        });
        this.submitted = false;
      },
      GetCategoryIcon(Id) {
        return (
          Endpoints.Helper.MarketplaceImage +
          Id +
          "/" +
          localStorage.getItem("token") +
          "/category"
        );
      }
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
</style>
