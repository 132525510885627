<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title mt-2 mb-4">Add New Plus Subscription</h1>
              <div class="panel-box">
                <div class="row">
                  <div class="form-step-col p-5">
                    <form
                      ref="CreateSubscriptionForm"
                      @submit.prevent="CreateSubscription"
                    >
                      <div class="row">
                        <!-- Company Name -->
                        <div class="col-sm-12 col-lg-12">
                          <div class="mb-3">
                            <label class="form-label"
                              >Title<span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Title"
                              v-model="NewSubscription.title"
                              :class="{
                                'is-invalid':
                                  submitted && $v.NewSubscription.title.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted && !$v.NewSubscription.title.required
                              "
                              class="invalid-feedback"
                            >
                              Title is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label"
                              >Price<span class="text-danger">*</span></label
                            >
                            <input
                              type="number"
                              maxlength="5"
                              class="form-control"
                              placeholder="Enter Price"
                              v-model="NewSubscription.price"
                              :class="{
                                'is-invalid':
                                  submitted && $v.NewSubscription.price.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted && !$v.NewSubscription.price.required
                              "
                              class="invalid-feedback"
                            >
                              Price is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label"
                              >Allowed Ads<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="number"
                              maxlength="5"
                              class="form-control"
                              placeholder="Enter Allowed Ads"
                              v-model="NewSubscription.allowed_ads"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.NewSubscription.allowed_ads.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.NewSubscription.allowed_ads.required
                              "
                              class="invalid-feedback"
                            >
                              Allowed Ads is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label"
                              >Max User Import Allow<span class="text-danger"
                                >*</span
                              ></label
                            >
                            <input
                              type="number"
                              maxlength="5"
                              class="form-control"
                              placeholder="Enter Max User Import Allow"
                              v-model="NewSubscription.allow_max_user_import"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.NewSubscription.allow_max_user_import.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.NewSubscription.allow_max_user_import.required
                              "
                              class="invalid-feedback"
                            >
                            Max user import allow is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">
                              Max Hot Deal Allow
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="number"
                              maxlength="5"
                              class="form-control"
                              placeholder="Enter Max Hot Deal Allow"
                              v-model="NewSubscription.max_hot_deal_allow"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.NewSubscription.max_hot_deal_allow.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.NewSubscription.max_hot_deal_allow.required
                              "
                              class="invalid-feedback"
                            >
                              Max Hot Deal Allow Ads is required
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">Feature Ad</label>
                            <label
                              class="form-check form-check-single form-switch radio-label"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="NewSubscription.is_feature_ad"
                              />
                            </label>
                          </div>
                          <div class="mb-3">
                            <label class="form-label"
                              >Show only ads from company default
                              category</label
                            >
                            <label
                              class="form-check form-check-single form-switch radio-label"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="NewSubscription.is_default_ad"
                              />
                            </label>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Modules</label>
                          <hr />
                        </div>
                        <div class="row module-section">
                          <div
                            class="col-sm-12 col-lg-4"
                            v-for="(PlusModule, index) in PlusModules"
                            :key="'Social' + index"
                          >
                            <div class="mb-3">
                              <label class="form-label switch-label">{{
                                PlusModule.title
                              }}</label>
                              <label
                                class="form-check form-check-single form-switch"
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  v-model="
                                    NewSubscription.plus_modules[
                                      PlusModule.title
                                    ]
                                  "
                                  @change="
                                    $emit('input', $event.target.checked)
                                  "
                                />
                              </label>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-lg-12"
                            @click="isModuleShow = !isModuleShow"
                            v-if="isModuleShow == false"
                          >
                            <button class="btn smallBtn btn-info">
                              New Module?
                            </button>
                          </div>
                          <div
                            class="col-sm-12 col-lg-12"
                            v-if="isModuleShow == true"
                          >
                            <div class="form-inline add-new-modules">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Enter Module Title"
                                  v-model="NewModule.title"
                                />
                              </div>
                              <button
                                type="button"
                                class="btn btn-primary btn-xs mb-2"
                                @click="CreateModule()"
                              >
                                Save
                              </button>
                              <Loading
                                v-if="ModuleSaving"
                                class="module-saving"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-right">
                        <router-link
                          :to="{ name: 'Subscriptions' }"
                          class="btn btn-default btn-xs"
                        >
                          &larr; Back</router-link
                        >
                        <button
                          class="btn btn-primary"
                          v-on:click="CreateSubscription()"
                          :disabled="Saving"
                        >
                          Save
                        </button>
                        <Loading v-if="Saving" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  export default {
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        isModuleShow: false,
        Saving: false,
        ModuleSaving: false,
        submitted: false,
        PlusModules: {},
        NewSubscription: {
          title: "",
          price: "",
          allowed_ads: "",
          max_hot_deal_allow: "",
          allow_max_user_import:"",
          is_feature_ad: "",
          is_default_ad: "",
          plus_modules: {},
        },
        NewModule: {
          title: "",
        },
      };
    },
    validations: {
      NewSubscription: {
        title: { required },
        price: { required },
        allowed_ads: { required },
        allow_max_user_import: { required },
        max_hot_deal_allow: { required },
      },
    },
    mounted() {
      this.GetPlusModulesList();
    },
    methods: {
      GetPlusModulesList() {
        var self = this;
        Axios.post(Endpoints.PlusModules.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          Response.data.records.forEach((moduleName) => {
            self.NewSubscription.plus_modules[moduleName.title] = "";
          });
          this.PlusModules = Response.data.records;
        });
      },
      CreateSubscription() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Subscription.Create, {
          token: localStorage.getItem("token"),
          ...this.NewSubscription,
        }).then((Response) => {
          this.Saving = false;
          if (Response.data.success) {
            Swal.fire("Subscription Added Successfully", "", "success").then(
              () => {
                this.$router.push("/subscriptions");
                this.$emit("recordAdded");
              }
            );
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      clearSubscriptionModal() {
        this.$refs.CreateSubscriptionForm.reset();
        var self = this;
        Object.keys(this.NewSubscription).forEach(function (subsKey, index) {
          self.NewSubscription[subsKey] = "";
        });
        this.submitted = false;
      },
      CreateModule() {
        var self = this;
        this.ModuleSaving = true;
        Axios.post(Endpoints.PlusModules.Create, {
          token: localStorage.getItem("token"),
          ...this.NewModule,
        }).then((Response) => {
          this.ModuleSaving = false;
          if (Response.data.success) {
            if (Response.data.record != "") {
              self.NewSubscription.plus_modules[this.NewModule.title] = "";
              self.PlusModules.push(Response.data.record);
            }
            this.isModuleShow = false;
            this.NewModule.title = "";
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
  .module-section label:first-child {
    width: 130px;
  }
  .module-section .mb-3 {
    margin-bottom: 8px !important;
  }
  .module-section {
    margin-bottom: 15px;
  }
  hr {
    margin-bottom: 15px;
  }
  .module-section .form-switch {
    display: inline-block;
    padding: 0px;
    margin: -5px 40px !important;
  }
  .module-section .switch-label {
    display: inline-block;
    vertical-align: middle;
  }
  .smallBtn {
    padding: 5px 10px !important;
    color: #fff;
  }
  .add-new-modules .form-group {
    width: 40%;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
  .add-new-modules button {
    margin: 0;
    vertical-align: middle;
    margin-top: 6px;
  }
  .module-saving {
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    margin-left: 10px;
  }
</style>
