<template>
  <div
    class="modal fade"
    id="updateCompanyUserPassword"
    ref="updateCompanyUserPassword"
    tabindex="-1"
    role="dialog"
    aria-labelledby="updateCompanyUserPasswordPassword"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-lg">
        <div class="modal-header">
          <h5 class="modal-title" id="updateCompanyUserPasswordPassword">
            Update User Password
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            ref="closeChangePasswordModal"
            v-on:click="clearCompanyUserPasswordModal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form
            ref="updateCompanyUserPasswordForm"
            @submit.prevent="updateCompanyUserPassword"
          >
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <div class="mb-3">
                  <label class="form-label"
                    >New Password<span class="text-danger">*</span></label
                  >
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Enter New Password"
                    v-model="updatePassword.new_password"
                    :class="{
                      'is-invalid':
                        submitted && $v.updatePassword.new_password.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.updatePassword.new_password.required"
                    class="invalid-feedback"
                  >
                    New Password is required
                  </div>
                  <div v-if="submitted && !$v.updatePassword.new_password.minLength" class="d-block invalid-feedback">Password must be at least 8 characters long.</div>
                  <div v-if="submitted && !$v.updatePassword.new_password.strong" class="d-block invalid-feedback">Password must be strong (eg. include at least 1 capital letter, 1 number and 1 special character)</div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-12">
                <div class="mb-3">
                  <label class="form-label"
                    >Confirm New Password<span class="text-danger"
                      >*</span
                    ></label
                  >
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Enter Confirm New Password"
                    v-model="updatePassword.confirm_new_password"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.updatePassword.confirm_new_password.$error,
                    }"
                  />
                  <div
                    v-if="
                      submitted &&
                      !$v.updatePassword.confirm_new_password.required
                    "
                    class="invalid-feedback"
                  >
                    Confirm New Password is required
                  </div>
                  <div
                    v-if="
                      submitted &&
                      !$v.updatePassword.confirm_new_password.sameAsPassword
                    "
                    class="invalid-feedback"
                  >
                    Passwords must be identical
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right">
              <button
                class="btn btn-primary"
                v-on:click="updateCompanyUserPasswordPasword()"
                :disabled="Saving"
              >
                Save
              </button>
              <Loading v-if="Saving" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required, minLength, sameAs } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";

  export default {
    props: ["updatePasswordID"],
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        Saving: false,
        submitted: false,
        updatePassword: {
          new_password: "",
          confirm_new_password: "",
        },
      };
    },
    validations: {
      updatePassword: {
        new_password: { required, minLength: minLength(8),
          strong: (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(value)
         },
        confirm_new_password: {
          required,
          sameAsPassword: sameAs("new_password"),
        },
      },
    },
    methods: {
      updateCompanyUserPassword() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        this.updatePassword.id = this.updatePasswordID;
        Axios.post(Endpoints.Company.User.UpdatePassword, {
          token: localStorage.getItem("token"),
          ...this.updatePassword,
        }).then((Response) => {
          if (Response.data.success) {
            Swal.fire(
              "Plus Company User Password Updated Successfully",
              "",
              "success"
            ).then(() => {
              this.$refs.updateCompanyUserPasswordForm.reset();
              this.$refs.closeChangePasswordModal.click();
              this.Saving = false;
            });
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      clearCompanyUserPasswordModal() {
        this.$refs.updateCompanyUserPasswordForm.reset();
        var self = this;
        Object.keys(this.updatePassword).forEach(function (subsKey, index) {
          self.updatePassword[subsKey] = "";
        });
        this.submitted = false;
      },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
  .modal-backdrop {
    background-color: #232e3c !important;
  }
  .mt-10 {
    margin-top: 10px;
  }
</style>
