<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title">Add Company</h1>

              <div class="panel-box">
                <div class="row row-deck row-cards">
                  <div class="col-sm-12 col-lg-12">
                    <div class="steps">
                      <div class="step-row step1 activeStep" ref="step1">
                        <span>1</span>
                        <div class="contentPart">General Info</div>
                      </div>
                      <div class="step-row step2" ref="step2">
                        <span>2</span>
                        <div class="contentPart">People & Contacts</div>
                      </div>
                      <div class="step-row step3" ref="step3">
                        <span>3</span>
                        <div class="contentPart">Social Links</div>
                      </div>
                      <div class="step-row step4" ref="step4">
                        <span>4</span>
                        <div class="contentPart">Other Info</div>
                      </div>
                      <div class="lastRow">
                        <span>
                          <img
                            src="@/assets/images/check-icon.png"
                            width="15"
                            alt="Check Icon"
                          />
                        </span>
                      </div>
                    </div>
                    <form @submit.prevent="CreateCompanyData">
                      <!-- Step 1 start -->
                      <div class="step_1 activeForm" ref="step_1">
                        <div class="row">
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Company Name<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Company Name"
                                v-model="NewCompany.name"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.name.$error,
                                }"
                              />
                              <div
                                v-if="submitted && !$v.NewCompany.name.required"
                                class="invalid-feedback"
                              >
                                Company Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-2">
                            <div class="mb-3">
                              <label class="form-label">
                                Mileage Unit<span class="text-danger">*</span>
                              </label>
                              <select
                                v-model="NewCompany.mileage_unit"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.NewCompany.mileage_unit.$error,
                                }"
                                class="form-select"
                              >
                                <option value="">Select Mileage Unit</option>
                                <option value="1">Miles</option>
                                <option value="2">KM</option>
                              </select>

                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.mileage_unit.required
                                "
                                class="invalid-feedback"
                              >
                                Mileage Unit is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-3">
                            <div class="mb-3">
                              <label class="form-label">
                                Company Type<span class="text-danger">*</span>
                              </label>
                              <select
                                v-model="NewCompany.default_category"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.NewCompany.default_category.$error,
                                }"
                                class="form-select"
                              >
                                <option value="">Select Company Type</option>
                                <option
                                  v-for="Category in Categories"
                                  v-bind:value="Category.id"
                                  v-bind:key="Category.id"
                                >
                                  {{ Category.category_name }}
                                </option>
                              </select>

                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.default_category.required
                                "
                                class="invalid-feedback"
                              >
                                Company Type is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-3">
                            <div class="mb-3">
                              <label class="form-label"
                                >Company Logo
                                <span class="text-danger">*</span></label
                              >
                              <input
                                class="input form-control company__logo__input"
                                type="file"
                                name="resume"
                                ref="logoFile"
                                accept="image/*"
                                :class="{
                                  'is-invalid': $v.NewCompany.logo_image.$error,
                                }"
                                @change="imageFileChanged($event)"
                              />
                              <img
                                v-show="NewCompany.logo_image"
                                ref="CompanyLogoPreview"
                                class="company__logo__preview company__logo__create"
                                height="200"
                                width="200"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.logo_image.required
                                "
                                class="invalid-feedback"
                              >
                                Company Logo is required
                              </div>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.logo_image.file_size_validation
                                "
                                class="invalid-feedback"
                              >
                                Please upload less than 1 MB file size image
                              </div>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.logo_image
                                    .image_type_validation
                                "
                                class="invalid-feedback"
                              >
                                Please upload jpeg, png, gif or svg file only
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Website<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Website URL"
                                v-model="NewCompany.website"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.website.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted && !$v.NewCompany.website.required
                                "
                                class="invalid-feedback"
                              >
                                Website is required
                              </div>
                              <div
                                v-if="submitted && !$v.NewCompany.website.url"
                                class="invalid-feedback"
                              >
                                Enter valid Website URL
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Email Address<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Company Email Address"
                                v-model="NewCompany.email"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.email.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted && !$v.NewCompany.email.required
                                "
                                class="invalid-feedback"
                              >
                                Email Address is required
                              </div>
                              <div
                                v-if="submitted && !$v.NewCompany.email.email"
                                class="invalid-feedback"
                              >
                                Valid Email Address is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4 colorSection">
                            <div class="mb-3">
                              <label class="form-label"
                                >Corporate Colours</label
                              >
                              <div class="row">
                                <div class="col-sm-6 col-lg-6">
                                  <span>Colour 1:</span>
                                  <input
                                    type="color"
                                    class="form-control"
                                    v-model="NewCompany.colour_1"
                                  />
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                  <span>Colour 2:</span>
                                  <input
                                    type="color"
                                    class="form-control"
                                    v-model="NewCompany.colour_2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Phone Number<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Company Phone Number"
                                v-model="NewCompany.contact_number"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.NewCompany.contact_number.$error,
                                }"
                                maxlength="16"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.contact_number.required
                                "
                                class="invalid-feedback"
                              >
                                Phone Number is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Address Line 1<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Building Number and Street Name"
                                v-model="NewCompany.address"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.address.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted && !$v.NewCompany.address.required
                                "
                                class="invalid-feedback"
                              >
                                Address is required
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-sm-12 col-lg-4">
                            <div
                              class="mb-3"
                              :class="{
                                'is-invalid':
                                  submitted && $v.NewCompany.town.$error,
                              }"
                            >
                              <label class="form-label">
                                Town / City<span class="text-danger">*</span>
                              </label>
                              <Select2
                                class="custom_search"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.town.$error,
                                }"
                                v-model="NewCompany.town"
                                :options="Cities"
                                placeholder="Select City"
                              />
                              <div
                                v-if="submitted && !$v.NewCompany.town.required"
                                class="invalid-feedback"
                              >
                                Town / City is required
                              </div>
                            </div>
                          </div> -->
                          <!-- <div class="col-sm-12 col-lg-4">
                            <div
                              class="mb-3"
                              :class="{
                                'is-invalid':
                                  submitted && $v.NewCompany.county.$error,
                              }"
                            >
                              <label class="form-label"
                                >County<span class="text-danger">*</span></label
                              >
                              <Select2
                                class="custom_search"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.county.$error,
                                }"
                                v-model="NewCompany.county"
                                :options="Counties"
                                placeholder="Select County"
                              />
                              <div
                                v-if="
                                  submitted && !$v.NewCompany.county.required
                                "
                                class="invalid-feedback"
                              >
                                County is required
                              </div>
                            </div>
                          </div> -->

                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Postcode<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Postcode"
                                v-model="NewCompany.postcode"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.postcode.$error,
                                }"
                                maxlength="8"
                              />
                              <div
                                v-if="
                                  submitted && !$v.NewCompany.postcode.required
                                "
                                class="invalid-feedback"
                              >
                                Postcode is required
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-sm-12 col-lg-12 text-right button-section"
                        >
                          <router-link
                            :to="{ name: 'Companies' }"
                            class="btn btn-default"
                          >
                            &larr; Back</router-link
                          >
                          <button
                            type="button"
                            class="btn btn-primary"
                            v-on:click="showNext('step_2', '2')"
                          >
                            Next and Continue
                          </button>
                        </div>
                      </div>
                      <!-- Step 2 start -->
                      <div class="step_2" ref="step_2">
                        <div class="row">
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                CEO First Name<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="CEO First Name"
                                v-model="NewCompany.forename"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.forename.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted && !$v.NewCompany.forename.required
                                "
                                class="invalid-feedback"
                              >
                                CEO First Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                CEO Last Name<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="CEO Last Name"
                                v-model="NewCompany.surname"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.surname.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted && !$v.NewCompany.surname.required
                                "
                                class="invalid-feedback"
                              >
                                CEO Last Name is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                CEO Email<span class="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="CEO Email Address"
                                v-model="NewCompany.ceo_email"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.NewCompany.ceo_email.$error,
                                }"
                              />
                              <div
                                v-if="
                                  submitted && !$v.NewCompany.ceo_email.required
                                "
                                class="invalid-feedback"
                              >
                                CEO Email Address is required
                              </div>
                              <div
                                v-if="
                                  submitted && !$v.NewCompany.ceo_email.email
                                "
                                class="invalid-feedback"
                              >
                                Valid Email Address is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                CEO Phone Number<span class="text-danger"
                                  >*</span
                                >
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="CEO Phone Number"
                                v-model="NewCompany.ceo_contact_number"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.NewCompany.ceo_contact_number.$error,
                                }"
                                maxlength="16"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.ceo_contact_number.required
                                "
                                class="invalid-feedback"
                              >
                                CEO Phone Number is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"
                                >PocketBox Champion<span class="text-danger"
                                  >*</span
                                ></label
                              >
                              <select
                                class="form-select"
                                v-model="NewCompany.contact_type"
                              >
                                <option value="1">CEO</option>
                                <option value="2">Other</option>
                              </select>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="NewCompany.contact_type == '2'"
                          >
                            <div class="col-sm-12 col-lg-4">
                              <div class="mb-3">
                                <label class="form-label">
                                  Champion First Name<span class="text-danger"
                                    >*</span
                                  >
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Pocket Box Champion"
                                  v-model="NewCompany.champion_firstname"
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.NewCompany.champion_firstname.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.NewCompany.champion_firstname.required
                                  "
                                  class="invalid-feedback"
                                >
                                  Champion First Name is required
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                              <div class="mb-3">
                                <label class="form-label">
                                  Champion Last Name<span class="text-danger"
                                    >*</span
                                  >
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Pocket Box Champion"
                                  v-model="NewCompany.champion_lastname"
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.NewCompany.champion_lastname.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.NewCompany.champion_lastname.required
                                  "
                                  class="invalid-feedback"
                                >
                                  Champion Last Name is required
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                              <div class="mb-3">
                                <label class="form-label">
                                  Champion Email<span class="text-danger"
                                    >*</span
                                  >
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Champion Email Address"
                                  v-model="NewCompany.champion_email"
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.NewCompany.champion_email.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.NewCompany.champion_email.required
                                  "
                                  class="invalid-feedback"
                                >
                                  Champion Email Address is required
                                </div>
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.NewCompany.champion_email.email
                                  "
                                  class="invalid-feedback"
                                >
                                  Valid Email Address is required
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                              <div class="mb-3">
                                <label class="form-label">
                                  Champion Phone Number<span class="text-danger"
                                    >*</span
                                  >
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Champion Phone Number"
                                  v-model="NewCompany.champion_contact_number"
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.NewCompany.champion_contact_number
                                        .$error,
                                  }"
                                  maxlength="16"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.NewCompany.champion_contact_number
                                      .required
                                  "
                                  class="invalid-feedback"
                                >
                                  Champion Phone Number is required
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-lg-12 text-right button-section"
                          >
                            <button
                              type="button"
                              class="btn btn-default"
                              v-on:click="showBack('step_1', '1')"
                            >
                              &larr; Back
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              v-on:click="showNext('step_3', '3')"
                            >
                              Next and Continue
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- Step 3 start -->
                      <div class="step_3" ref="step_3">
                        <div class="row">
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                MOT Partner Company
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="MOT Partner Company"
                                v-model="NewCompany.MOTPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Tax Partner Company
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Tax Partner Company"
                                v-model="NewCompany.TaxPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                License Check Partner
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="License Check Partner"
                                v-model="NewCompany.LicenseCheckPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Insurance Partner Company
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Insurance Partner Company"
                                v-model="NewCompany.InsurancePartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Car Sell Partner
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Car Sell Partner"
                                v-model="NewCompany.CarSellPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Accident Report Partner
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Accident Report Partner"
                                v-model="NewCompany.AccidentReportPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Garage Booking Partner
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Garage Booking Partner"
                                v-model="NewCompany.GarageBookingPartner"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> Facebook </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Facebook URL"
                                v-model="NewCompany.Facebook"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> Twitter </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Twitter URL"
                                v-model="NewCompany.Twitter"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> LinkedIn </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="LinkedIn URL"
                                v-model="NewCompany.LinkedIn"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> Instagram </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Instagram URL"
                                v-model="NewCompany.Instagram"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"> YouTube </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="YouTube URL"
                                v-model="NewCompany.YouTube"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label">
                                Send Message Duration
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Send Message Duration"
                                v-model="NewCompany.MessageDuration"
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="mb-3">
                              <label class="form-label"
                                >Email Bottom Banner</label
                              >
                              <input
                                class="input form-control"
                                type="file"
                                name="banner"
                                ref="bannerFile"
                                accept="image/*"
                                :class="{
                                  'is-invalid':
                                    $v.NewCompany.banner_image.$error,
                                }"
                                @change="bannerImageFileChanged($event)"
                              />
                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.banner_image
                                    .file_size_validation
                                "
                                class="invalid-feedback"
                              >
                                Please upload less than 1 MB file size image
                              </div>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.banner_image
                                    .image_type_validation
                                "
                                class="invalid-feedback"
                              >
                                Please upload jpeg, png, gif or svg file only
                              </div>
                              <center>
                                <img
                                  v-show="NewCompany.banner_image"
                                  ref="CompanyBannerPreview"
                                  class="company__banner__preview"
                                />
                              </center>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-lg-12 text-right button-section"
                          >
                            <button
                              type="button"
                              class="btn btn-default"
                              v-on:click="showBack('step_2', '2')"
                            >
                              &larr; Back
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              v-on:click="showNext('step_4', '4')"
                            >
                              Next and Continue
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- Step 4 start -->
                      <div class="step_4" ref="step_4">
                        <div class="row">
                          <div class="col-sm-12 col-lg-8">
                            <div class="mb-3">
                              <label class="form-label">Company Notes</label>
                              <textarea
                                type="text"
                                class="form-control"
                                placeholder="Enter Note"
                                style="height: 122px"
                                v-model="NewCompany.company_note"
                                :class="{
                                  'is-invalid':
                                    submitted &&
                                    $v.NewCompany.company_note.$error,
                                }"
                              ></textarea>
                              <div
                                v-if="
                                  submitted &&
                                  !$v.NewCompany.company_note.required
                                "
                                class="invalid-feedback"
                              >
                                Company Notes is required
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                            <div class="row">
                              <div class="col-sm-12 col-lg-6">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Fleet Contract Type</label
                                  >
                                  <select
                                    v-model="NewCompany.fleet_subscription"
                                    :class="{
                                      'is-invalid':
                                        submitted &&
                                        $v.NewCompany.fleet_subscription.$error,
                                    }"
                                    class="form-select"
                                  >
                                    <option value="">
                                      Select Fleet Contract Type
                                    </option>
                                    <option
                                      v-for="FleetSubscription in FleetSubscriptions"
                                      v-bind:value="FleetSubscription.id"
                                      v-bind:key="FleetSubscription.id"
                                    >
                                      {{ FleetSubscription.title }}
                                    </option>
                                  </select>
                                  <div
                                    v-if="
                                      submitted &&
                                      !$v.NewCompany.fleet_subscription.required
                                    "
                                    class="invalid-feedback"
                                  >
                                    Fleet Contract Type is required
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-6">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Plus Contract Type<span class="text-danger"
                                      >*</span
                                    ></label
                                  >
                                  <select
                                    v-model="NewCompany.marketplace_plan"
                                    :class="{
                                      'is-invalid':
                                        submitted &&
                                        $v.NewCompany.marketplace_plan.$error,
                                    }"
                                    class="form-select"
                                  >
                                    <option value="">
                                      Select Plus Contract Type
                                    </option>
                                    <option
                                      v-for="Subscription in Subscriptions"
                                      v-bind:value="Subscription.id"
                                      v-bind:key="Subscription.id"
                                    >
                                      {{ Subscription.title }}
                                    </option>
                                  </select>
                                  <div
                                    v-if="
                                      submitted &&
                                      !$v.NewCompany.marketplace_plan.required
                                    "
                                    class="invalid-feedback"
                                  >
                                    Plus Contract Type is required
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-6">
                                <div class="mb-3">
                                  <label class="form-label">
                                    Service Start Date<span class="text-danger"
                                      >*</span
                                    >
                                  </label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    placeholder="Service Start Date"
                                    v-model="NewCompany.service_start_date"
                                    :class="{
                                      'is-invalid':
                                        submitted &&
                                        $v.NewCompany.service_start_date.$error,
                                    }"
                                  />
                                  <div
                                    v-if="
                                      submitted &&
                                      !$v.NewCompany.service_start_date.required
                                    "
                                    class="invalid-feedback"
                                  >
                                    Service Date is required
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-6">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Contract Length<span class="text-danger"
                                      >*</span
                                    ></label
                                  >
                                  <select
                                    v-model="NewCompany.contract_length"
                                    :class="{
                                      'is-invalid':
                                        submitted &&
                                        $v.NewCompany.contract_length.$error,
                                    }"
                                    class="form-select"
                                  >
                                    <option value="">
                                      Select Contract Length
                                    </option>
                                    <option
                                      v-for="index in 12"
                                      :key="index"
                                      v-bind:value="index"
                                    >
                                      {{ index }} Month
                                    </option>
                                  </select>
                                  <div
                                    v-if="
                                      submitted &&
                                      !$v.NewCompany.contract_length.required
                                    "
                                    class="invalid-feedback"
                                  >
                                    Contract Length is required
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-8">
                            <div class="row">
                              <div class="col-sm-12 col-lg-12">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Vehicle Check Templates</label
                                  >
                                  <div class="row">
                                    <template v-for="(classData, index) in Classes">
                                      <div
                                        class="col-sm-12 col-lg-2 p-t-10"
                                      >
                                        <input
                                          type="checkbox"
                                          v-model="NewCompany.check_templates"
                                          :value="classData.id"
                                          :true-value="[]"
                                          :id="'row+' + index"
                                        />
                                        <label
                                          style="padding-left: 5px; cursor: pointer"
                                          :for="'row+' + index"
                                          >{{ classData.title }}</label
                                        >
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                                <div class="col-sm-12 col-lg-12">
                                <div class="mb-3">
                                  <label class="form-label"
                                    >Inspection Templates</label
                                  >
                                  <div class="row">
                                    <template v-for="(classData, index) in InspectionClasses">
                                      <div
                                        class="col-sm-12 col-lg-3 p-t-10"
                                      >
                                        <input
                                          type="checkbox"
                                          v-model="NewCompany.inspection_templates"
                                          :value="classData.id"
                                          :true-value="[]"
                                          :id="'row+' + index"
                                        />
                                        <label
                                          style="padding-left: 5px; cursor: pointer"
                                          :for="'row+' + index"
                                          >{{ classData.title }}</label
                                        >
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-sm-12 col-lg-12 text-right button-section"
                          >
                            <button
                              type="button"
                              class="btn btn-default"
                              v-on:click="showBack('step_3', '3')"
                            >
                              &larr; Back
                            </button>
                            <button
                              class="btn btn-primary"
                              v-on:click="CreateCompanyData()"
                              :disabled="Saving"
                            >
                              Complete Company
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- Step 1 start -->
                      <Loading v-if="Saving" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    required,
    url,
    email,
    minLength,
    sameAs,
    requiredIf,
  } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  import Select2 from "v-select2-component";
  import citiesData from "@/assets/JsonData/cities.json";
  import countiesData from "@/assets/JsonData/counties.json";

  // set cities data
  var citiesList = [];
  var keys = Object.keys(citiesData);
  keys.forEach(function (key) {
    citiesList.push(citiesData[key]);
  });

  // set counties data
  var countiesList = [];
  var keys = Object.keys(countiesData);
  keys.forEach(function (key) {
    countiesList.push(countiesData[key]);
  });

  const file_size_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    return file.size < 1048576;
  };
  const image_type_validation = (value, vm) => {
    if (!value) {
      return true;
    }
    let file = value;
    if (
      file.type == "image/svg+xml" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif" ||
      file.type == "image/png"
    ) {
      return true;
    } else {
      return false;
    }
  };
  export default {
    components: {
      Nav,
      Loading,
      Select2,
    },
    data() {
      return {
        Saving: false,
        submitted: false,
        Companies: false,
        steps: 1,
        Categories: {},
        Counties: countiesList,
        Cities: citiesList,
        Subscriptions: {},
        FleetSubscriptions: {},
        NewCompany: {
          name: "",
          default_category: "",
          mileage_unit: "",
          fleet_subscription: "",
          logo_image: "",
          website: "",
          email: "",
          colour_1: "#bc1c1c",
          colour_2: "#e06f6f",
          Banner: "",
          contact_number: "",
          address: "",
          /* town: "",
          county: "", */
          postcode: "",
          forename: "",
          surname: "",
          ceo_email: "",
          ceo_contact_number: "",
          contact_type: "1",
          champion_firstname: "",
          champion_lastname: "",
          champion_email: "",
          champion_contact_number: "",
          Facebook: "",
          Twitter: "",
          LinkedIn: "",
          YouTube: "",
          Instagram: "",
          banner_image: "",
          company_note: "",
          marketplace_plan: "",
          service_start_date: "",
          contract_length: "",
          MOTPartner: "",
          TaxPartner: "",
          MessageDuration: "",
          LicenseCheckPartner: "",
          InsurancePartner: "",
          CarSellPartner: "",
          AccidentReportPartner: "",
          GarageBookingPartner: "",
          check_templates: [],
          inspection_templates: [],

        },
        Classes: [],
        InspectionClasses: [],
      };
    },
    validations: {
      NewCompany: {
        name: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
        },
        default_category: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
        },
        mileage_unit: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
        },
        logo_image: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
          file_size_validation,
          image_type_validation,
        },
        website: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
          url,
        },
        email: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
          email,
        },
        contact_number: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
        },
        address: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
        },
        /* town: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
        },
        county: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
        }, */
        postcode: {
          required: requiredIf(function () {
            return this.steps == 1 ? true : false;
          }),
        },
        forename: {
          required: requiredIf(function () {
            return this.steps == 2 ? true : false;
          }),
        },
        surname: {
          required: requiredIf(function () {
            return this.steps == 2 ? true : false;
          }),
        },
        ceo_email: {
          required: requiredIf(function () {
            return this.steps == 2 ? true : false;
          }),
          email,
        },
        ceo_contact_number: {
          required: requiredIf(function () {
            return this.steps == 2 ? true : false;
          }),
        },
        champion_firstname: {
          required: requiredIf(function () {
            return this.steps == 2
              ? this.NewCompany.contact_type == 2
                ? true
                : false
              : false;
          }),
        },
        champion_lastname: {
          required: requiredIf(function () {
            return this.steps == 2
              ? this.NewCompany.contact_type == 2
                ? true
                : false
              : false;
          }),
        },
        champion_email: {
          required: requiredIf(function () {
            return this.steps == 2
              ? this.NewCompany.contact_type == 2
                ? true
                : false
              : false;
          }),
          email,
        },
        champion_contact_number: {
          required: requiredIf(function () {
            return this.steps == 2
              ? this.NewCompany.contact_type == 2
                ? true
                : false
              : false;
          }),
        },
        banner_image: {
          file_size_validation,
          image_type_validation,
        },
        company_note: {},
        marketplace_plan: {
          required: requiredIf(function () {
            return this.steps == 4 ? true : false;
          }),
        },
        service_start_date: {
          required: requiredIf(function () {
            return this.steps == 4 ? true : false;
          }),
        },
        contract_length: {
          required: requiredIf(function () {
            return this.steps == 4 ? true : false;
          }),
        },
        fleet_subscription: {},
      },
    },
    mounted() {
      document.title = " PocketBox Admin - Add New Company";
      this.GetCategoriesList();
      this.GetSubscriptionsList();
      this.GetFleetSubscriptionsList();
      this.getClasses();
      this.getInspectionClasses();
    },
    methods: {
      GetCategoriesList() {
        Axios.post(Endpoints.Category.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Categories = Response.data.records;
        });
      },
      GetSubscriptionsList() {
        Axios.post(Endpoints.Subscription.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Subscriptions = Response.data.records;
        });
      },
      GetFleetSubscriptionsList() {
        Axios.post(Endpoints.FleetSubscription.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.FleetSubscriptions = Response.data.records;
        });
      },
      imageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.NewCompany.logo_image = files[0];
        var output = document.querySelector(".company__logo__preview");
        output.src = URL.createObjectURL(files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
      },
      bannerImageFileChanged(event) {
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length) {
          return;
        }
        this.NewCompany.banner_image = files[0];
      },
      CreateCompanyData() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.Company.Create, {
          token: localStorage.getItem("token"),
          ...this.NewCompany,
        }).then((Response) => {
          if (Response.data.success) {
            this.SaveCompanyLogo(Response.data.last_id);
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      SaveCompanyLogo(id) {
        var LogoFormData = new FormData();
        if (this.$refs.logoFile.files[0]) {
          LogoFormData.append("storage_type", "CC");
          LogoFormData.append("token", localStorage.getItem("token"));
          LogoFormData.append("file", this.$refs.logoFile.files[0]);
          LogoFormData.append("company_id", id);
          Axios.post(Endpoints.Helper.File.Upload, LogoFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Company.LogoSet, {
                  token: localStorage.getItem("token"),
                  logo: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  this.SaveBannerImage(id);
                });
              }
            }
          );
        } else {
          this.SaveBannerImage(id);
        }
      },
      SaveBannerImage(id) {
        var BannerFormData = new FormData();
        if (this.$refs.bannerFile.files[0]) {
          BannerFormData.append("storage_type", "CC");
          BannerFormData.append("token", localStorage.getItem("token"));
          BannerFormData.append("file", this.$refs.bannerFile.files[0]);
          BannerFormData.append("company_id", id);
          Axios.post(Endpoints.Helper.File.Upload, BannerFormData).then(
            (UploadResponse) => {
              var UploadData = UploadResponse.data;
              if (UploadData.success === true) {
                Axios.post(Endpoints.Company.BannerSet, {
                  token: localStorage.getItem("token"),
                  banner: UploadData.file,
                  id: id,
                }).then((SetResponse) => {
                  this.Saving = false;
                  Swal.fire("Company Created Successfully", "", "success").then(
                    () => {
                      this.$router.push("/companies");
                    }
                  );
                });
              }
            }
          );
        } else {
          this.Saving = false;
          Swal.fire("Company Created Successfully", "", "success").then(() => {
            this.$router.push("/companies");
          });
        }
      },
      getClasses() {
        Axios.post(Endpoints.Vehicle.Classes, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.Classes = Response.data.records;
        });
      },
      getInspectionClasses() {
        Axios.post(Endpoints.Inspection.Classes, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          this.InspectionClasses = Response.data.records;
        });
      },
      showNext(step, stepNo) {
        this.submitted = true;
        this.$v.NewCompany.$touch();
        if (this.$v.NewCompany.$invalid) {
          return;
        }
        this.submitted = false;
        this.$el.querySelector(".activeForm").classList.remove("activeForm");
        this.$refs[step].classList.add("activeForm");
        this.$el.querySelector(".activeStep").classList.remove("activeStep");
        this.$refs["step" + stepNo].classList.add("activeStep");
        this.steps = stepNo;
      },
      showBack(step, stepNo) {
        this.submitted = false;
        this.$el.querySelector(".activeForm").classList.remove("activeForm");
        this.$refs[step].classList.add("activeForm");
        this.$el.querySelector(".activeStep ").classList.remove("activeStep");
        this.$refs["step" + stepNo].classList.add("activeStep");
        this.steps = stepNo;
      },
    },
  };
</script>
<style>
  .colorSection span {
    display: inline-block;
    width: 80px;
  }
  .colorSection input {
    display: inline-block;
    width: 40px;
    padding: 0;
    height: 40px;
    vertical-align: middle;
  }
  .button-section a,
  .button-section .btn-default {
    padding: 8px 20px;
    margin-right: 20px;
  }
  .button-section .btn-pocketbox {
    padding: 8px 20px;
    background-color: #357ffa;
    border-color: #357ffa;
    border-radius: 4px;
  }
  .step_1,
  .step_2,
  .step_3,
  .step_4 {
    display: none;
  }
  textarea {
    height: 120px;
  }
  .activeForm {
    display: block;
  }
  .steps {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
  .step-row {
    display: inline-block;
    background: #f0f0f7;
    color: #727272;
    padding: 10px;
    width: 220px;
    text-align: left;
    font-weight: bold;
    position: relative;
  }
  .lastRow {
    display: inline-block;
    position: relative;
    height: 25px;
  }
  .step-row span,
  .lastRow span {
    display: inline-block;
    width: 40px;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    padding: 10px 9px;
    position: absolute;
    left: -20px;
    top: 0px;
    color: #357ffa;
    font-weight: 600;
  }
  .step-row .contentPart {
    display: inline-block;
    padding-left: 30px;
  }
  .activeStep span {
    color: #fff;
    background-color: #2972eb;
  }
  .activeStep .contentPart,
  .activeStep {
    color: #fff;
    background-color: #357ffa;
  }
  .lastRow span {
    top: 0;
  }
  .select2-selection--single {
    display: block;
    width: 100%;
    padding: 0.4375rem 2.25rem 0.4375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714;
    color: #232e3c;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #dadcde !important;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 36px !important;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    display: block;
    width: 100%;
    padding: 7px 12px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4285714;
    color: #232e3c;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dadcde' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 4px center;
    background-size: 16px 12px;
    border: none;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    right: 0%;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    right: 22px;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 20px;
    padding-left: 0px;
  }
  .is-invalid .select2-selection--single {
    border-color: #d63939 !important;
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dadcde' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23d63939' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
    background-position: right 10px center, center right 35px;
    background-size: 16px 12px,
      calc(0.7142857em + 0.4375rem) calc(0.7142857em + 0.4375rem);
  }
</style>
