<template>
  <div class="page">
    <Nav />
    <div class="app-wrapper permissions-page">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title mt-2 mb-4">Add New Fleet Account Role</h1>
              <div class="panel-box">
                <div class="row">
                  <div class="form-step-col p-5">
                    <form ref="CreateRoleForm" @submit.prevent="CreateRole">
                      <div class="row">
                        <!-- Company Name -->
                        <div class="col-sm-12 col-lg-12">
                          <div class="mb-3">
                            <label class="form-label"
                              >Title<span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Title"
                              v-model="NewRole.title"
                              :class="{
                                'is-invalid':
                                  submitted && $v.NewRole.title.$error,
                              }"
                            />
                            <div
                              v-if="submitted && !$v.NewRole.title.required"
                              class="invalid-feedback"
                            >
                              Title is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div
                        class="form theme-form roles"
                        v-if="Modules.length > 0"
                      >
                        <div class="row">
                          <label class="col-sm-2 role-name">&nbsp;</label>
                          <div class="col-sm-10">
                            <div class="form-check-solid">
                              <table>
                                <tbody>
                                  <template>
                                    <tr>
                                      <td class="p-l-0">
                                        <div class="checkbox checkbox-primary">
                                          <input
                                            id="checkbox-primary-1"
                                            type="checkbox"
                                            @click="selectAllRoles()"
                                          /><label for="checkbox-primary-1"
                                            >Select All</label
                                          >
                                        </div>
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form theme-form roles"
                        v-if="Modules.length > 0"
                      >
                        <div
                          class="row"
                          v-for="(Module, ModuleIndex) in Modules"
                          :key="ModuleIndex"
                        >
                          <template v-if="Module != 'Hierarchy System'">
                            <label class="col-sm-2 role-name">{{
                              Module
                            }}</label>
                            <div class="col-sm-10">
                              <div class="form-check-solid">
                                <table>
                                  <tbody>
                                    <template>
                                      <tr>
                                        <td
                                          v-for="(
                                            Action, ActionIndex
                                          ) in NewRole.permissions[Module]"
                                          :key="ActionIndex"
                                          :class="
                                            ActionIndex == 'enable'
                                              ? 'main-active-box'
                                              : 'child-actions'
                                          "
                                        >
                                          <input
                                            class="check_now_enable"
                                            type="checkbox"
                                            value="1"
                                            :checked="selectAllRole"
                                            v-model="
                                              NewRole.permissions[Module][
                                                ActionIndex
                                              ]
                                            "
                                          /><label>{{
                                            ActionIndex.replaceAll("_", " ")
                                          }}</label>
                                        </td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="text-right">
                        <router-link
                          :to="{ name: 'FleetAccountRoles' }"
                          class="btn btn-default btn-xs"
                        >
                          &larr; Back</router-link
                        >
                        <button
                          class="btn btn-primary"
                          v-on:click="CreateRole()"
                          :disabled="Saving"
                        >
                          Save
                        </button>
                        <Loading v-if="Saving" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required } from "vuelidate/lib/validators";
  import Nav from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  export default {
    components: {
      Nav,
      Loading,
    },
    data() {
      return {
        isModuleShow: false,
        Saving: false,
        ModuleSaving: false,
        submitted: false,
        PlusModules: {},
        Modules: [],
        selectAllRole: false,
        NewRole: {
          title: "",
          permissions: {},
        },
        Permissions: ["enable", "add", "edit", "delete"],
        selectAllRole: false,
        currentModules: [],
      };
    },
    validations: {
      NewRole: {
        title: { required },
      },
    },
    mounted() {
      this.GetModules();
    },
    methods: {
      GetModules() {
        var obj = { token: localStorage.getItem("token") };
        Axios.post(Endpoints.Modules.List, obj).then((Response) => {
          if (Response.data.success) {
            Axios.post(Endpoints.AccountRole.PermissionsList, obj).then(
              (RoleResponse) => {
                var AccountRoesData = JSON.parse(RoleResponse.data.records);

                if (RoleResponse.data.success) {
                  Response.data.records.forEach((element) => {
                    this.NewRole.permissions[element] = {};
                    this.Permissions.forEach((Permission) => {
                      this.NewRole.permissions[element][Permission] = false;
                    });
                    if (typeof AccountRoesData[element] != "undefined") {
                      this.NewRole.permissions[element] =
                        AccountRoesData[element];
                    }
                  });
                  setTimeout(() => {
                    this.Modules = Response.data.records;
                  }, 400);
                }
              }
            );
          }
        });
      },
      CreateRole() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.AccountRole.Create, {
          token: localStorage.getItem("token"),
          ...this.NewRole,
        }).then((Response) => {
          this.Saving = false;
          if (Response.data.success) {
            Swal.fire("Role Added Successfully", "", "success").then(() => {
              this.$router.push("/fleet-account-roles");
            });
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      selectAllRoles() {
        if (this.selectAllRole == true) {
          this.selectAllRole = false;
        } else {
          this.selectAllRole = true;
        }
        this.Modules.forEach((element) => {
          if (typeof this.NewRole.permissions[element] != "undefined") {
            Object.keys(this.NewRole.permissions[element]).forEach(
              (Permission) => {
                this.NewRole.permissions[element][Permission] =
                  this.selectAllRole;
              }
            );
          }
        });
      },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
  .module-section label:first-child {
    width: 130px;
  }
  .module-section .mb-3 {
    margin-bottom: 8px !important;
  }
  .module-section {
    margin-bottom: 15px;
  }
  hr {
    margin-bottom: 15px;
  }
  .module-section .form-switch {
    display: inline-block;
    padding: 0px;
    margin: -5px 40px !important;
  }
  .module-section .switch-label {
    display: inline-block;
    vertical-align: middle;
  }
  .smallBtn {
    padding: 5px 10px !important;
    color: #fff;
  }
  .add-new-modules .form-group {
    width: 40%;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
  .add-new-modules button {
    margin: 0;
    vertical-align: middle;
    margin-top: 6px;
  }
  .module-saving {
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    margin-left: 10px;
  }

  .permissions-page .row-new {
    display: inline-block;
    width: 350px;
    margin-left: 50px;
  }
  .permissions-page .row-new .btn-primary {
    margin-left: 10px;
    padding: 8px 15px;
    width: 161px;
  }
  .permissions-page .add-vehicle-heading {
    vertical-align: top;
    margin-top: 15px;
  }
  .permissions-page .headerCustom h5 span {
    display: inline-block;
    vertical-align: super;
  }
  .permissions-page .vehicle-details-heading {
    vertical-align: top;
    margin-top: 13px;
  }
  .permissions-page .roles .main-active-box {
    padding: 10px;
    background-color: #8f4f88 !important;
    color: #fff !important;
    text-transform: capitalize;
    width: 100px;
  }
  .permissions-page .roles .main-active-box label {
    padding-left: 10px;
    vertical-align: top;
    font-size: 13px;
    color: #fff;
  }
  .permissions-page .roles tr {
    border-bottom: 1px solid #fff;
  }
  .permissions-page .roles tr label {
    padding-left: 10px;
    vertical-align: top;
    font-size: 13px !important;
  }
  .permissions-page .roles tr td {
    padding: 10px 20px;
  }
  .permissions-page .roles tr input {
    vertical-align: middle;
  }
  .permissions-page .roles .role-name {
    vertical-align: middle;
    line-height: normal;
    padding-top: 15px;
    font-size: 13px;
  }
  .permissions-page .roles .child-actions {
    background-color: #e3e6eb;
    text-transform: capitalize;
  }
  .select-all-box label {
    margin-left: 10px;
    font-size: 13px;
  }
</style>
