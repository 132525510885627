<template>
  <div class="page">
    <Navigation />
    <div class="app-wrapper">
      <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
          <div class="row gy-4">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h1 class="main-title mt-2 mb-4">Edit Fleet Subscription</h1>
              <div class="panel-box p-5">
                <div class="row">
                  <div class="form-step-col p-5">
                    <form
                      ref="EditSubscriptionForm"
                      @submit.prevent="EditSubscription"
                    >
                      <div class="row">
                        <!-- Company Name -->
                        <div class="col-sm-12 col-lg-12">
                          <div class="mb-3">
                            <label class="form-label"
                              >Title<span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter Title"
                              v-model="Update_Subscription.title"
                              :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.Update_Subscription.title.$error,
                              }"
                            />
                            <div
                              v-if="
                                submitted &&
                                !$v.Update_Subscription.title.required
                              "
                              class="invalid-feedback"
                            >
                              Title is required
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-12 col-lg-4">
                              <div class="mb-3">
                                <label class="form-label"
                                  >Max Drivers<span class="text-danger"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="number"
                                  maxlength="5"
                                  class="form-control"
                                  placeholder="Enter Max Drivers"
                                  v-model="Update_Subscription.max_drivers"
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.Update_Subscription.max_drivers.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.Update_Subscription.max_drivers.required
                                  "
                                  class="invalid-feedback"
                                >
                                  Max Drivers is required
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                              <div class="mb-3">
                                <label class="form-label"
                                  >Max Vehicle<span class="text-danger"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="number"
                                  maxlength="5"
                                  class="form-control"
                                  placeholder="Enter Max Vehicle"
                                  v-model="Update_Subscription.max_vehicle"
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.Update_Subscription.max_vehicle.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.Update_Subscription.max_vehicle.required
                                  "
                                  class="invalid-feedback"
                                >
                                  Max Vehicle is required
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                              <div class="mb-3">
                                <label class="form-label"
                                  >Max Trailer<span class="text-danger"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="number"
                                  maxlength="5"
                                  class="form-control"
                                  placeholder="Enter Max Trailer"
                                  v-model="Update_Subscription.max_trailer"
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.Update_Subscription.max_trailer.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.Update_Subscription.max_trailer.required
                                  "
                                  class="invalid-feedback"
                                >
                                  Max Trailer is required
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                              <div class="mb-3">
                                <label class="form-label"
                                  >Max Asset<span class="text-danger"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="number"
                                  maxlength="5"
                                  class="form-control"
                                  placeholder="Enter Max Asset"
                                  v-model="Update_Subscription.max_asset"
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.Update_Subscription.max_asset.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.Update_Subscription.max_asset.required
                                  "
                                  class="invalid-feedback"
                                >
                                  Max Asset is required
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                              <div class="mb-3">
                                <label class="form-label"
                                  >Max Fleet Users<span class="text-danger"
                                    >*</span
                                  ></label
                                >
                                <input
                                  type="number"
                                  maxlength="5"
                                  class="form-control"
                                  placeholder="Enter Max Fleet Users"
                                  v-model="Update_Subscription.max_fleet_users"
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.Update_Subscription.max_fleet_users
                                        .$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.Update_Subscription.max_fleet_users
                                      .required
                                  "
                                  class="invalid-feedback"
                                >
                                  Max Fleet Users is required
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-sm-12 col-lg-4"
                              v-if="
                                Update_Subscription.fleet_modules[
                                  'Vehicle Checks'
                                ] != ''
                              "
                            >
                              <div class="mb-3">
                                <label class="form-label">
                                  Allowed Custom Vehicle Checks<span
                                    class="text-danger"
                                    >*</span
                                  >
                                </label>
                                <input
                                  type="number"
                                  maxlength="5"
                                  class="form-control"
                                  placeholder="Enter Allowed Custom Vehicle Checks"
                                  v-model="
                                    Update_Subscription.allowed_custom_vehicle_checks
                                  "
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.Update_Subscription
                                        .allowed_custom_vehicle_checks.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.Update_Subscription
                                      .allowed_custom_vehicle_checks.required
                                  "
                                  class="invalid-feedback"
                                >
                                  Allowed Custom Vehicle Checks is required
                                </div>
                              </div>
                              </div>
                              <div
                              class="col-sm-12 col-lg-4"
                            >

                              <div class="mb-3">
                                <label class="form-label">
                                  PMI Check Count Per Category<span
                                    class="text-danger"
                                    >*</span
                                  >
                                </label>
                                <input
                                  type="number"
                                  maxlength="5"
                                  class="form-control"
                                  placeholder="Enter Allowed Custom Vehicle Checks"
                                  v-model="
                                    Update_Subscription.pmi_check_count_per_category
                                  "
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.Update_Subscription
                                        .pmi_check_count_per_category.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.Update_Subscription
                                      .pmi_check_count_per_category.required
                                  "
                                  class="invalid-feedback"
                                >
                                PMI check count per category is required
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-sm-12 col-lg-4"
                            >

                              <div class="mb-3">
                                <label class="form-label">
                                  Allowed Total Asset Checks<span
                                    class="text-danger"
                                    >*</span
                                  >
                                </label>
                                <input
                                  type="number"
                                  maxlength="5"
                                  class="form-control"
                                  placeholder="Enter Allowed Custom Vehicle Checks"
                                  v-model="
                                    Update_Subscription.allowed_total_asset_checks
                                  "
                                  :class="{
                                    'is-invalid':
                                      submitted &&
                                      $v.Update_Subscription
                                        .allowed_total_asset_checks.$error,
                                  }"
                                />
                                <div
                                  v-if="
                                    submitted &&
                                    !$v.Update_Subscription
                                      .allowed_total_asset_checks.required
                                  "
                                  class="invalid-feedback"
                                >
                                Allowed total asset checks is required
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">Modules</label>
                            <hr />
                          </div>
                          <div class="row module-section">
                            <div
                              class="col-sm-12 col-lg-4"
                              v-for="(FleetModule, index) in FleetModules"
                              :key="'Social' + index"
                            >
                              <div class="mb-3">
                                <label class="form-label switch-label">{{
                                  FleetModule.title
                                }}</label>
                                <label
                                  class="form-check form-check-single form-switch"
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="
                                      Update_Subscription.fleet_modules[
                                        FleetModule.title
                                      ]
                                    "
                                    @change="
                                      $emit('input', $event.target.checked)
                                    "
                                  />
                                </label>
                              </div>
                            </div>
                            <div
                              class="col-sm-12 col-lg-12"
                              @click="isModuleShow = !isModuleShow"
                              v-if="isModuleShow == false"
                            >
                              <button class="btn smallBtn btn-info">
                                New Module?
                              </button>
                            </div>
                            <div
                              class="col-sm-12 col-lg-12"
                              v-if="isModuleShow == true"
                            >
                              <div class="form-inline add-new-modules">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter Module Title"
                                    v-model="New_Module.title"
                                  />
                                </div>
                                <button
                                  type="button"
                                  class="btn btn-primary mb-2"
                                  @click="CreateModule()"
                                >
                                  Save
                                </button>
                                <Loading
                                  v-if="ModuleSaving"
                                  class="module-saving"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul
                        class="list-unstyled list-inline float-end buttons-col mt-4"
                      >
                        <li style="padding: 5px 20px">
                          <Loading v-if="Saving" />
                        </li>
                        <li>
                          <router-link
                            :to="{ name: 'FleetSubscriptions' }"
                            class="btn btn-default btn-xs"
                          >
                            &larr; Back</router-link
                          >
                        </li>
                        <li>
                          <button
                            type="button"
                            class="btn btn-info next-step"
                            v-on:click="EditSubscription()"
                            :disabled="Saving"
                          >
                            Update Fleet Subscription
                          </button>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { required, requiredIf } from "vuelidate/lib/validators";
  import Navigation from "@/components/Navigation";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Swal from "sweetalert2";
  import Axios from "axios";
  export default {
    components: {
      Navigation,
      Loading,
    },
    data() {
      return {
        isModuleShow: false,
        Saving: false,
        ModuleSaving: false,
        submitted: false,
        FleetModules: {},
        New_Module: {
          title: "",
        },
        Update_Subscription: {
          title: "",
          max_drivers: "",
          max_vehicle: "",
          max_trailer:"",
          max_asset:"",
          max_fleet_users: "",
          allowed_custom_vehicle_checks: "",
          pmi_check_count_per_category: "",
          allowed_total_asset_checks: "",
          fleet_modules: {},
        },
      };
    },
    validations: {
      Update_Subscription: {
        title: { required },
        max_drivers: { required },
        max_vehicle: { required },
        max_trailer: { required },
        max_asset: { required },
        max_fleet_users: { required },
        pmi_check_count_per_category: { required },
        allowed_total_asset_checks: { required },
        allowed_custom_vehicle_checks: {
          required: requiredIf(function () {
            return this.Update_Subscription.fleet_modules["Vehicle Checks"];
          }),
        },
      },
    },
    mounted() {
      this.GetFleetModulesList();
      this.SubscriptionId = this.$route.params.SubscriptionId;

      setTimeout(() => {
        this.getRecord(this.SubscriptionId);
      }, 500);
    },
    methods: {
      getRecord(id) {
        Axios.post(Endpoints.FleetSubscription.Get, {
          token: localStorage.getItem("token"),
          id: id,
        }).then((Response) => {
          this.Update_Subscription = Response.data.record;
          this.Update_Subscription.fleet_modules = JSON.parse(
            Response.data.record.fleet_modules
          );
        });
      },
      GetFleetModulesList() {
        var self = this;
        Axios.post(Endpoints.FleetModules.List, {
          token: localStorage.getItem("token"),
        }).then((Response) => {
          Response.data.records.forEach((moduleName) => {
            self.Update_Subscription.fleet_modules[moduleName.title] = "";
          });
          this.FleetModules = Response.data.records;
        });
      },
      EditSubscription() {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.Saving = true;
        Axios.post(Endpoints.FleetSubscription.Update, {
          token: localStorage.getItem("token"),
          ...this.Update_Subscription,
        }).then((Response) => {
          this.Saving = false;
          if (Response.data.success) {
            Swal.fire(
              "Fleet Subscription Updated Successfully",
              "",
              "success"
            ).then(() => {
              this.$router.push("/fleet-subscriptions");
            });
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      CreateModule() {
        var self = this;
        this.ModuleSaving = true;
        Axios.post(Endpoints.FleetModules.Create, {
          token: localStorage.getItem("token"),
          ...this.New_Module,
        }).then((Response) => {
          this.ModuleSaving = false;
          if (Response.data.success) {
            if (Response.data.record != "") {
              self.Update_Subscription.fleet_modules[this.New_Module.title] =
                "";
              self.FleetModules.push(Response.data.record);
            }
            this.isModuleShow = false;
            this.New_Module.title = "";
          } else {
            Swal.fire({
              title: "Oops!",
              text: Response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        });
      },
      clearSubscriptionModal() {
        this.$refs.EditSubscriptionForm.reset();
        var self = this;
        Object.keys(this.Update_Subscription).forEach(function (
          subsKey,
          index
        ) {
          self.Update_Subscription[subsKey] = "";
        });
        this.submitted = false;
      },
    },
  };
</script>
<style>
  .form-switch {
    padding-left: 0;
  }
  .module-section label:first-child {
    width: 130px;
  }
  .module-section .mb-3 {
    margin-bottom: 8px !important;
  }
  .module-section {
    margin-bottom: 15px;
  }
  hr {
    margin-bottom: 15px;
  }
  .module-section .form-switch {
    display: inline-block;
    padding: 0px;
    margin: 0px 40px !important;
  }
  .module-section .switch-label {
    display: inline-block;
    vertical-align: middle;
  }
  .smallBtn {
    padding: 5px 10px !important;
    color: #fff;
  }
  .add-new-modules .form-group {
    width: 40%;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
  .add-new-modules button {
    margin: 0;
    vertical-align: middle;
    margin-top: 6px;
  }
  .module-saving {
    display: inline-block;
    vertical-align: middle;
    font-size: 30px;
    margin-left: 10px;
  }
</style>
