<template>
  <div class="row rowData">
    <div class="col-sm-3 col-md-3 col-lg-3 flex">
      <div class="user-block blue-block p-3">
        <div class="new-users total-users">
          <p class="user-month">Total Users</p>
          <div v-if="Loader" class="loading-class">
            <Loading />
          </div>
          <p class="arrow-text" v-if="Users.Users">
            <img src="assets/images/dashboard/logo-icon.svg" />
            {{ Users.Users.Total }}
          </p>
          <p class="per-text">
            {{ Users.UsersUpInLast24Hours }} Up in the last 24 Hours
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 flex">
      <div class="user-block green-block p-3">
        <div class="new-users">
          <p class="user-month">NEW USERS THIS MONTH</p>
          <div v-if="NewUsersThisMonthLoader" class="loading-class">
            <Loading />
          </div>
          <p class="arrow-text">
            <img src="/assets/images/icons/arrow_upward.svg" />
            {{ NewUsersThisMonth.NewUsersThisMonth }}
          </p>
          <p class="per-text">
            {{ NewUsersThisMonth.NewUsersUpInLast30Days }} Up in the last 30
            days
          </p>
        </div>
        <div class="bar-chart-col">
          <img src="/assets/images/dashboard/bar-chart.svg" />
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 flex">
      <div class="user-block orange-block p-3">
        <div class="new-users">
          <p class="user-month">ACTIVE USERS THIS WEEK</p>
          <div v-if="ActiveThisWeekLoader" class="loading-class">
            <Loading />
          </div>
          <p class="arrow-text">{{ ActiveThisWeek.ActiveThisWeek }}</p>
          <p class="per-text">
            {{ ActiveThisWeek.ActiveUsersUpInLastWeek }} Up on last week
          </p>
        </div>
        <div class="bar-chart-col">
          <img src="/assets/images/dashboard/bar-chart1.svg" />
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3 flex">
      <div class="user-block purple-block p-3">
        <div class="new-users">
          <p class="user-month">USERS ACTIVE TODAY</p>
          <div v-if="ActiveTodayLoader" class="loading-class">
            <Loading />
          </div>
          <p class="arrow-text">
            <img src="/assets/images/icons/user-clock.svg" />
            {{ ActiveToday.ActiveToday }}
          </p>
          <p class="per-text">
            {{ ActiveToday.ActiveUsersUpInYesterday }} Up since yesterday
          </p>
        </div>
        <div class="bar-chart-col">
          <img src="/assets/images/dashboard/bar-chart.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        Users: {},
        NewUsersThisMonth: {},
        ActiveThisWeek: {},
        ActiveToday: {},
        Loader: false,
        NewUsersThisMonthLoader: false,
        ActiveThisWeekLoader: false,
        ActiveTodayLoader: false,
      };
    },
    mounted() {
      this.Loader = true;
      this.ActiveThisWeekLoader = true;
      this.NewUsersThisMonthLoader = true;
      this.ActiveTodayLoader = true;
      // data fragment
      var actions = [
        "TotalUsers",
        "NewUsersThisMonth",
        "ActiveUserThisWeek",
        "ActiveUserToday",
      ];
      var setTime = 2000;
      actions.forEach((action) => {
        setTimeout(() => {
          this.GetVehiclesAnalytics(action);
        }, setTime);
        setTime = parseInt(setTime) + 500;
      });
    },
    methods: {
      GetVehiclesAnalytics(type) {
        axios
          .post(Endpoints.Data.UsersFragment, {
            token: localStorage.getItem("token"),
            data: type,
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              if (type == "TotalUsers") {
                this.Users = RequestResponse.data.data;
                this.Loader = false;
              } else if (type == "NewUsersThisMonth") {
                this.NewUsersThisMonth = RequestResponse.data.data;
                this.NewUsersThisMonthLoader = false;
              } else if (type == "ActiveUserThisWeek") {
                this.ActiveThisWeek = RequestResponse.data.data;
                this.ActiveThisWeekLoader = false;
              } else if (type == "ActiveUserToday") {
                this.ActiveToday = RequestResponse.data.data;
                this.ActiveTodayLoader = false;
              }
            }
          });
      },
    },
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .rowData {
    width: 100%;
    padding: 0;
    margin: 0;
  }
</style>
