<template>
  <div class="col-sm-6 col-md-6 col-lg-6">
    <div class="table-box">
      <div class="p-4 border-bottom">
        <div class="col">
          <h4>User Age Breakdown</h4>
          <p>Pocket Box Users By Age</p>
        </div>
      </div>
      <div class="p-2">
        <div v-if="Loader" class="loading-class">
          <Loading />
        </div>
        <canvas ref="chart_Gender_Age" style="height: 350px"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Endpoints from "@/Admin/Endpoints";
  import Loading from "@/components/Loading";
  import Chart from "chart.js";
  export default {
    components: {
      Loading,
    },
    data() {
      return {
        UserAgeBreakdown: {
          keys: [],
          values: [],
          backgroundColor: [],
        },
        Loader: false,
      };
    },
    mounted() {
      this.Loader = true;
      this.GetAnalytics("UserAgeBreakdown");
    },
    methods: {
      GetAnalytics(type) {
        axios
          .post(Endpoints.Data.UsersFragment, {
            token: localStorage.getItem("token"),
            data: type,
          })
          .then((RequestResponse) => {
            if (RequestResponse.data.success) {
              /*Get Gender BreakDown By Age*/
              var UserAgeBreakdown = RequestResponse.data.data.UserAgeBreakdown;
              for (let uIndex in UserAgeBreakdown) {
                this.UserAgeBreakdown.keys.push(uIndex);
                this.UserAgeBreakdown.values.push(UserAgeBreakdown[uIndex]);
              }
              this.Loader = false;
              this.DrawCharts();
            }
          });
      },
      DrawCharts() {
        // Gender Chart
        let chart_Gender = new Chart(
          this.$refs.chart_Gender_Age.getContext("2d"),
          {
            type: "doughnut",
            data: {
              labels: this.UserAgeBreakdown.keys,
              datasets: [
                {
                  label: "User Age Breakdown",
                  data: this.UserAgeBreakdown.values,
                  backgroundColor: [
                    "#f7931a",
                    "#1cb0a0",
                    "#39d3ec",
                    "#4c8bfd",
                    "#8563e1",
                  ],
                  hoverOffset: 4,
                },
              ],
            },
            options: {
              responsive: false,
              legend: {
                position: "left",
                labels: {
                  fontColor: "black",
                  boxWidth: 20,
                  padding: 40,
                },
              },
              pieceLabel: {
                render: "label",
                fontColor: "#000",
                position: "outside",
                segment: true,
                fontSize: 14,
              },
            },
          }
        );
      },
    },
  };
</script>
<style>
  .typeBoxes {
    display: inline-block;
    width: 14.2%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .rowData {
    width: 100%;
    padding: 0;
    margin: 0;
  }
</style>
